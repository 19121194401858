<template>
  <el-form ref="form"  class="el-form-create"
           :model="form"
           v-bind="currentOptions"
           :rules="rules"
           v-loading="isDone"
           @submit.native.prevent
           :style="isDone ? {height:'50px'} : {}"
  >
    <slot name="before"></slot>
    <slot v-if="isStart">
      <diy-form-item ref="diyFormItem"
                     v-bind="currentOptions"
                     :childrens="components"
                     v-model="form"
                     @done="handleEmit('done' , ...arguments)"
                     :item-type="3"
                     @update="handleUpdate"
                     :condition-fun="conditionFun"
      >
        <template slot-scope="Obj" :slot="slotName" v-for="slotName in Object.keys($scopedSlots).filter(s=>['before','after'].indexOf(s) === -1)">
          <slot :name="slotName" v-bind="Obj"></slot>
        </template>
      </diy-form-item>
    </slot>
    <slot name="after"></slot>
  </el-form>
</template>

<script>
import tool from "@/Utils/tool";
import DiyFormItem from "./el/diyFormItem";
export default {
  name: "FormCreate",
  components: {DiyFormItem},
  model: {
    prop: 'formData',
    event: 'change'
  },
  props:{
    options:{
      type:Object,
      default(){return {}; },
    },
    components:{
      type:Array,
      default(){return []; },
    },
    formData:{
      type:Object,
      default(){return null; },
    },
    rules:{
      type:Object,
      default(){return {}; },
    },
  },
  data(){
    return {
      form:{...(this.formData||{})},
      loading:false,
      isDone:false,
      conditionData:{},
      conditionFields:[],
      isStart:false,
      isChange:false,
    }
  },
  computed:{
    currentOptions(){
      const {components,formData,rules,options, ...others} = this.$attrs;
      return this.$tool.ObjectAssign({labelWidth:'150px',inline:false,labelPosition:'right',size:'small'}, others, this.options);
    }
  },
  watch:{
    "formData":{
      deep:true,
      immediate:true,
      handler(v){
        this.$nextTick(()=>{
          let r = this.$tool.obj_equal({...this.form} , {...this.formData} , true);
          if(r.equal === false){
            var $isLoading = this.loading === true;
            this.loading = true;
            this.form = {...this.formData};
            if($isLoading === true){
              this.$nextTick(()=>{
                this.loading = false;
              })
            }
          }
        })
      }
    },
    components:{
      deep:true,
      immediate:true,
      handler(v , o){
        let {diffValue , is_equal} = this.$tool.obj_equal(v , o , true);
        if(is_equal === false){
          this.setStart();
        }
      }
    },
  },
  created() {
    this.setStart();
  },
  methods:{
    setStart(){
      this.isDone = true;
      this.isStart = false;
      setTimeout(()=>{
        this.isStart = true;
        if(this.$scopedSlots.default){
          this.isDone = false;
          this.$emit('done');
        }
      } , 250)
    },
    variableData($str){
      if(!($str && typeof $str === 'string')){
        return $str;
      }
      let fields = [];
      var regStr = ($str)=>{
        let matched = /\{\{.*\}\}/g.exec($str);
        if(matched){
          let re = matched[0];
          let name = re.substr(2, re.indexOf('}}') - 2);
          fields.push(name);
          $str = $str.replace('{{'+name+'}}' , '$obj.'+name)
          if(name){
            $str = regStr($str)
          }
        }
        return $str;
      }
      return {conditionStr:regStr($str),fields:fields};
    },
    conditionFun(condition , val , vNode){
      var conditionHandler = condition && condition instanceof Function ? condition : null;
      if(!conditionHandler && condition){
        if(this.conditionData[condition] === undefined){
          this.conditionData[condition] = null;
          let {conditionStr,fields} = this.variableData(condition , true)
          for (let i = 0; i < (fields || []).length; i++) {
            if(this.conditionFields.indexOf(fields[i]) === -1){
              this.conditionFields.push(fields[i]);
            }
          }
          try{ this.conditionData[condition] = eval("($obj,vNode)=>{$obj = $obj || {};return ("+conditionStr+") !== false;}"); }catch (e) {}
        }
        conditionHandler = this.conditionData[condition];
      }
      let ret = true;
      if(conditionHandler){
        try{ ret = conditionHandler({...this.form,$value:val === undefined ? null : val},vNode); }catch (e) { ret = false }
        // console.log(" ----- conditionFun --- " , vNode ? vNode.curProp : null , condition , ret , {...this.form,$value:val === undefined ? null : val});
      }
      return ret;
    },
    //数据验证
    async validate(callback, _fields = ['*']){
      var _this = this , valid = true , errors = [] , $fields = [] , fields = _fields === true || _fields === '*' ? ['*'] : (_fields === false ? [] : _fields);
      var editloading = false;
      return new Promise((resolve)=>{
        _this.$refs.form.fields.map(async function(d){
          let prop = ((d.$attrs || {}).prefixProp || '')+d.prop
          if($fields.indexOf(prop) === -1){
            let isIn = fields.indexOf('*') > -1 || fields.indexOf(prop) > -1;
            if((d.$parent || {}).inputType === 'daterange'){
              var startField = d.$parent.startField || 'start_time'
                  ,endField = d.$parent.endField || 'end_time'
              if(fields.indexOf('*') > -1 || fields.indexOf(((d.$attrs || {}).prefixProp || '')+startField) > -1){
                $fields.push(((d.$attrs || {}).prefixProp || '')+startField);
              }
              if(fields.indexOf('*') > -1 || fields.indexOf(((d.$attrs || {}).prefixProp || '')+endField) > -1){
                $fields.push(((d.$attrs || {}).prefixProp || '')+endField);
              }
            }
            if((isIn && fields.length > 0) || _fields === false){
              if((d.fieldValue === undefined || d.fieldValue === null) && (d.$parent.fieldValue !== undefined && d.$parent.fieldValue !== null)){
                d.fieldValue = d.$parent.acValue;
                _this.$set(_this.form ,prop , d.$parent.acValue)
              }
              if(d.editloading){
                valid = false;
                editloading = true;
                errors.push({prop:((d.$attrs || {}).prefixProp || '')+d.prop , message:(d.label||'')+"正在上传中" , vNode:d});
              }
              $fields.push(prop);
              await d.validate('', function (message){
                if (message) {
                  valid = false;
                  errors.push({prop:((d.$attrs || {}).prefixProp || '')+d.prop , message:message , vNode:d});
                }
              })
            }
          }
          return false
        });
        let {...form} = _fields === true ? tool.obj_intersect_key(Object.assign({} , _this.form) , $fields) : Object.assign({} , _this.form);
        callback && callback(form , valid , errors)
        resolve && resolve(form , valid , errors);
      })
    },
    reset(){
      var form = {};
      this.$refs.form.fields.map(async (d)=>{
        let prop = ((d.$attrs || {}).prefixProp || '')+d.prop
        this.$set(form ,prop , d.$parent.defaultValue);
      });
      this.$nextTick(()=>{
        this.clearValidate();
      })
      this.$emit('change' , form);
    },
    resetField(...vars){
      this.$refs.form.resetField(...vars);
    },
    handleEmitChange(){
      this.$emit('change' , this.form);
    },
    handleEmitDone(){
      let diyFormItems = this.$refs.diyFormItem instanceof Array ? this.$refs.diyFormItem : (this.$refs.diyFormItem instanceof Object ? [this.$refs.diyFormItem]:[])
      if(diyFormItems.length === diyFormItems.filter(d=>d.isDone === true).length){
        this.isDone = false;
        this.$emit('done');
      }
    },
    clearValidate(){
      this.$refs.form.clearValidate();
    },
    handleUpdate({value,oldValue,actValue,prop,type , props , vNode, prefix}){
      this.loading = true;
      this.$emit('change' , this.form);
      this.$emit('update' , {value,oldValue,actValue,prop,type , props , vNode:vNode.itemType === 1 && vNode.$refs.diyFormItemChildren?vNode.$refs.diyFormItemChildren:vNode, prefix})
      this.$nextTick(()=>{
        this.loading = false;
      })
    },
  }
}
</script>

<style lang="less">
.el-form-create::v-deep{
  .el-form-item ~ .el-divider-box{
    margin-bottom: 22px;
  }
  .el-divider-box ~ .el-row .el-col .el-form-item {
    margin-top: 0;
  }
}
.el-form--inline{
   > div{
    display: inline-block;
  }
}
</style>
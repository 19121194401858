<template>
  <div>
    <div v-loading="loading" v-if="listType === 'table-list'">
      <slot name="empty" v-if="parseData.length === 0">
        <el-empty :description="emptyText" v-if="loading === false"></el-empty>
        <div :style="{
              width:'100%',
              height:(loading ? '300px' : (height && !isNaN(height) ? height+'px' : (height || 'auto')))
              ,maxHeight:(maxHeight && !isNaN(maxHeight) ? maxHeight+'px' : (maxHeight || '200px'))
            }" v-else></div>
      </slot>
      <el-row class="table-list" :justify="'center'" :type="'type'">
        <template v-for="(row , rowIndex) in parseData">
          <el-col  :key="rowIndex" :md="parseInt(Math.ceil(24 / currentListNumber))" :sm="parseInt(Math.ceil(24 / (currentListNumber / 2)))" :xs="parseInt(Math.ceil(24 / (currentListNumber / 4)))">
            <slot style="padding: 0 5px;margin-bottom: 15px;" v-bind:row="row" v-bind:rowIndex="rowIndex">
              <el-card :body-style="{ padding: '0px' }" style="margin-bottom: 15px;">
                <div :style="{
                    width:(listOptions.width || 300)+'px'
                    ,height:(listOptions.width || 300)+'px'
                    ,position:'relative'
                  }">
                  <el-image
                      fit="contain"
                      :src="row[listOptions.coverName || 'cover'] || ''"
                      style="width:100%;height:100%"
                      :preview-src-list="row[listOptions.coverName || 'cover'] ? [row[listOptions.coverName || 'cover']] : []"
                  >
                  </el-image>
                </div>
                <div style="padding: 14px;">
                  <span>{{ row[listOptions.titleName || 'title'] || '' }}</span>
                </div>
              </el-card>
            </slot>
          </el-col>
        </template>
      </el-row>
    </div>
    <template v-else-if="listType === 'list'">
      <slot name="empty" v-if="loading === false && parseData.length === 0">
        <el-empty :description="emptyText"></el-empty>
      </slot>
      <div class="infinite-list-wrapper" :style="{'text-align': 'left','overflow': 'auto',height:height||'450px'}" v-if="parseData.length > 0">
        <ul :class="{list:$attrs.timeLine !== 'times','el-timeline':$attrs.timeLine === 'times'}"
            v-infinite-scroll="handlePageChange"
            :infinite-scroll-disabled="loading===true || (currentPage > 1 && totalData === parseData.length)"
        >
          <template v-for="(data , dataIndex) in parseData">
            <el-timeline-item
                :key="dataIndex"
                v-bind="data"
                :timestamp="data[$attrs.timestampField || 'timestamp']"
                v-if="$attrs.timeLine === 'times'">
              <slot v-bind:row="data" v-bind:rowIndex="dataIndex">
                {{data[$attrs.contentField || 'content']}}
              </slot>
            </el-timeline-item>
            <li class="list-item"  :key="dataIndex"
                :style="border !== false ? {borderBottom: '1px solid #EBEEF5',borderTop: dataIndex === 0 ? '1px solid #EBEEF5' : 'none'} : {}"
                v-else
            >
              <slot v-bind:row="data" v-bind:rowIndex="dataIndex">
                {{data[$attrs.contentField || 'content']}}
              </slot>
            </li>
          </template>
        </ul>
        <slot name="loading" v-if="loading === true">
          <p style="text-align:center;">加载中...</p>
        </slot>
        <slot name="noMore" v-if="loading === false && parseData.length > currentPageSize">
          <p style="text-align:center;">没有更多了</p>
        </slot>
      </div>
    </template>
    <el-table
        :data="parseData"
        :height="height"
        :max-height="maxHeight"
        :stripe="stripe"
        :border="border"
        :size="size"
        :fit="fit"
        :show-header="showHeader"
        :highlight-current-row="highlightCurrentRow"
        :current-row-key="currentRowKey"
        :row-class-name="rowClassName"
        :row-style="rowStyle"
        :cell-class-name="cellClassName"
        :cell-style="cellStyle"
        :header-row-class-name="headerRowClassName"
        :header-row-style="headerRowStyle"
        :header-cell-class-name="headerCellClassName"
        :header-cell-style="handleHeaderCellStyle"
        :row-key="rowKey"
        :default-expand-all="defaultExpandAll"
        :expand-row-keys="expandRowKeys"
        :default-sort="defaultSort"
        :tooltip-effect="tooltipEffect"
        :show-summary="showSummary"
        :sum-text="sumText"
        :summary-method="summaryMethod"
        :span-method="spanMethod"
        :select-on-indeterminate="selectOnIndeterminate"
        :indent="indent"
        :lazy="lazy"
        :load="load"
        :tree-props="treeProps"
        ref="table"
        @select="handleEmit('select' , ...arguments)"
        @select-all="handleEmit('select-all' , ...arguments)"
        @selection-change="handleEmit('selection-change' , ...arguments)"
        @cell-mouse-enter="handleEmit('cell-mouse-enter' , ...arguments)"
        @cell-mouse-leave="handleEmit('cell-mouse-leave' , ...arguments)"
        @cell-click="handleEmit('cell-click' , ...arguments)"
        @cell-dblclick="handleEmit('cell-dblclick' , ...arguments)"
        @row-click="handleEmit('row-click' , ...arguments)"
        @row-contextmenu="handleEmit('row-contextmenu' , ...arguments)"
        @row-dblclick="handleEmit('row-dblclick' , ...arguments);"
        @header-click="handleEmit('header-click' , ...arguments)"
        @header-contextmenu="handleEmit('header-contextmenu' , ...arguments)"
        @sort-change="handleEmit('sort-change' , ...arguments)"
        @filter-change="handleEmit('filter-change' , ...arguments)"
        @current-change="handleEmit('current-change' , ...arguments)"
        @header-dragend="handleEmit('header-dragend' , ...arguments)"
        @expand-change="handleEmit('expand-change' , ...arguments)"
        v-loading="loading"
        style="width: 100%;"
        v-else
    >
      <template slot="empty">
        <slot name="empty"  v-if="parseData.length === 0">
          <el-empty :description="emptyText"></el-empty>
        </slot>
      </template>
      <slot name="before"></slot>
      <template v-for="($column , columnIndex) in columns" >
        <el-table-column :key="columnIndex" v-bind="{
          selectable:(row)=>{return !(limit > 1 &&selectionData.length >=limit && selectionData.filter(d=>d[columnKey]===row[columnKey]).length===0)},
          width:45,
          ...$column,
          type:'selection',align:'center',
          reserveSelection,
          columnKey:initColumnKey($column.columnKey),
        }" v-if="['radio' , 'selection','checkbox'].indexOf($column.type) > -1"></el-table-column>
        <el-table-column :key="columnIndex" v-bind="{width:45,...$column,columnKey:initColumnKey($column.columnKey)}" v-else-if="$column.type === 'expand'"></el-table-column>
        <el-table-column :key="columnIndex" v-bind="{...$column,columnKey:initColumnKey($column.columnKey)}" v-else-if="$column.type === 'index'"></el-table-column>
        <com-component
            name="el-table-column"
            ref="tableItem"
            :children="'children'"
            v-bind="{props:{showOverflowTooltip , ...$column , columnIndex:columnIndex}}"
            :key="columnIndex"
            :components="$column.children || []"
            :componentMethod="(component,_this)=>{
              return component.hidden === true ? null : {name:'el-table-column',props:{showOverflowTooltip , ...component, columnIndex:columnIndex,slots:_this.$scopedSlots}}
            }"
            v-else-if="$column.hidden !== true"
        >
          <template slot="header" slot-scope="Obj">
            <slot name="header" v-bind="Obj">
              <span v-html="(Obj.$props || {}).label"></span>
            </slot>
          </template>
          <template slot-scope="Obj" >
            <slot :name="Obj.$props.slot || 'content'" v-bind="Obj">
              {{typeof Obj.$props.callback === 'function' && Obj.$props.callback(Obj.$props , {...Obj,$props:undefined}) ?'':''}}
              <template v-if="Obj.$props.slot === 'image'">
                <el-image
                    :style="{
                      width:'100%',
                      height:'55px'
                    }"
                    fit="contain"
                    :src="getTemplateDataValue(Obj , null , '')"
                    :preview-src-list="[getTemplateDataValue(Obj , null , '')]"
                    v-if="getTemplateDataValue(Obj , null , '')"
                >
                  <div slot="error">
                    -
                  </div>
                </el-image>
              </template>
              <template v-else-if="Obj.$props.slot === 'avatar'">
                <el-avatar :src="getTemplateDataValue(Obj , null , '')">
                  -
                </el-avatar>
              </template>
              <template v-else-if="Obj.$props.slot === 'tag'">
                <template v-for="(_d ,_dIndex) in Obj.$props.data">
                  <el-button size="mini" :type="_d.type || 'primary'" v-if="_d.value+'' === getTemplateDataValue(Obj , null , '')+''" :key="_dIndex">
                    {{_d.label === undefined ?_d.name : _d.label }}
                  </el-button>
                </template>
              </template>
              <template v-else-if="Obj.$props.slot === 'tags'">
                <template v-for="(_d ,_dIndex) in Obj.$props.data">
                  <el-button size="mini" :type="_d.type || 'primary'" v-if="(getTemplateDataValue(Obj , Obj.$props.prop , []) || []).includes(_d.value)" :key="_dIndex">
                    {{_d.label === undefined ?_d.name : _d.label }}
                  </el-button>
                </template>
              </template>
              <template v-else-if="Obj.$props.slot === 'status'">
                <template v-for="_d in Obj.$props.data">
                  <template v-if="_d.value+'' === getTemplateDataValue(Obj , null , '')+''">
                    <span class="ele-dot" :style="{width: '6px', height: '6px',marginRight:'4px','background-color':_d.color||'#1890ff'}"></span>{{_d.label === undefined ?_d.name : _d.label }}
                  </template>
                </template>
              </template>
              <template v-else-if="Obj.$props.slot === 'color'">
                <template v-if="Obj.$props.prop && getTemplateDataValue(Obj , Obj.$props.prop , '')">
                  <div class="button" :style="{ padding:'0 10px', '--color-default': getTemplateDataValue(Obj , Obj.$props.prop_color , '#000') }">
                    {{ getTemplateDataValue(Obj , Obj.$props.prop) }}
                  </div>
                </template>
                <template v-else> - </template>
              </template>
              <template v-else-if="['switch'].indexOf(Obj.$props.slot) > -1">
                <diy-form-item :item-type="0"
                               type="switch"
                               v-bind="Object.assign({},Obj.$props,Obj.$props.options || {} )"
                               v-model="Obj.row[Obj.$props.prop]"
                               @update="handleChangeEditData($event.value , $event.oldValue , Obj)"
                               @focus="editColRowIndex = null"
                ></diy-form-item>
              </template>
              <template v-else-if="editColRowIndex && editColRowIndex === Obj.$index+'-'+Obj.$props.columnIndex">
                <diy-form-item :item-type="0"
                               v-bind="Object.assign({},Obj.$props,Obj.$props.options || {} , {autofocus:true})"
                               v-model="Obj.row[Obj.$props.prop]"
                               @update="handleChangeEditData($event.value , $event.oldValue , Obj)"
                               @blur="$nextTick(()=>{editColRowIndex = null})"
                ></diy-form-item>
              </template>
              <template class="row-cell-item" v-else-if="Obj.$props.prop">
                <i class="el-icon-edit" :data-index="Obj.$index+'-'+Obj.$props.columnIndex"
                   @click="editColRowIndex = Obj.$index+'-'+Obj.$props.columnIndex"
                   v-if="Obj.$props.prop && (Obj.$props.slot === 'input' || Obj.$props.isEdit === true)"
                   style="margin-right: 5px;"></i>
                <div class="cell-template"
                     :class="Obj.$props.rowClass" :style="Obj.$props.rowStyle"
                     v-if="Obj.$props.template && typeof Obj.$props.template === 'function'"
                     v-html="getTemplateDataValue(Obj , Obj.$props.template)"
                ></div>
                <span v-html="getTemplateDataValue(Obj)" v-else></span>
              </template>
              <template v-else>-</template>
            </slot>
          </template>
        </com-component>
      </template>
      <slot name="after"></slot>
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </el-table>
    <slot name="page" v-bind:total="total" v-bind:pageSize="pageSize" v-if="pageOptions !== false && listType !== 'list' && pageSize > 0 && loading === false">
      <div style="text-align: center;margin-top: 15px;">
        <el-pagination
            :background="currentPageOptions.background !== false"
            :layout="currentPageOptions.layout || 'sizes, prev, pager, next, jumper, ->, total, slot'"
            :pager-count="currentPageOptions.pagerCount || 7"
            :popper-class="currentPageOptions.popperClass || null"
            :prev-text="currentPageOptions.prevText || null"
            :next-text="currentPageOptions.nextText || null"
            :disabled="currentPageOptions.disabled === true"
            :hide-on-single-page="currentPageOptions.hideOnSinglePage === true"
            :total="totalData"
            :page-sizes="(currentPageOptions.pageSizes || []).length === 0 ? [1, 2, 3, 4, 5, 10 , 20 ].map(d=>d*pageSize) :currentPageOptions.pageSizes"
            :page-size.sync="currentPageSize"
            :current-page.sync="currentPage"
            @size-change="handlePageSizeChange"
            @current-change="handlePageChange"
            @prev-click="handlePageChange"
            @next-click="handlePageChange"
        >
        </el-pagination>
      </div>
    </slot>
  </div>
</template>

<script>
import tool from "@/Utils/tool";
import DiyFormItem from "@/components/com/el/diyFormItem";
export default {
  name: "comTable",
  components: {DiyFormItem},
  props:{
    data:{
      type:[Array , Function , Object],
      required:true,
    },
    limit:{type:Number,default(){return null; },},
    // 列表选中数据(多选)，支持 .sync 修饰符
    selection:{
      type:Array,
      default(){return []; },
    },
    columns:{
      type:Array,
      required:true,
    },
    height:{
      type:[String,Number],
      default(){return null; },
    },
    maxHeight:{
      type:[String,Number],
      default(){return null; },
    },
    stripe:{
      type:Boolean,
      default(){return false; },
    },
    border:{
      type:Boolean,
      default(){return false; },
    },
    size:{
      type:String,
      default(){return null; },
      validator(value) {
        return ['medium', 'small', 'mini'].includes(value)
      }
    },
    fit:{
      type:Boolean,
      default(){return true; },
    },
    showHeader:{
      type:Boolean,
      default(){return true; },
    },
    highlightCurrentRow:{
      type:Boolean,
      default(){return false; },
    },
    currentRowKey:{
      type:[String , Number],
      default(){return null; },
    },
    rowClassName:{
      type:[String , Function],
    },
    rowStyle:{
      type:[String , Function],
    },
    cellClassName:{
      type:[String , Function],
    },
    cellStyle:{
      type:[String , Function],
    },
    headerRowClassName:{
      type:[String , Function],
    },
    headerRowStyle:{
      type:[String , Function],
    },
    headerCellClassName:{
      type:[String , Function],
    },
    headerCellStyle:{
      type:[String , Function],
    },
    rowKey:{
      type:[String , Function],
    },
    emptyText:{
      type:String,
      default(){return '暂无数据'; },
    },
    defaultExpandAll:{
      type:Boolean,
      default(){return false; },
    },
    expandRowKeys:{
      type:Array,
      default(){return null; },
    },
    defaultSort:{
      type:Object,
      default(){return {}; },
    },
    tooltipEffect:{
      type:String,
      default(){return 'dark'; },
      validator(value) {
        return ['dark', 'light'].includes(value)
      }
    },
    showSummary:{
      type:Boolean,
      default(){return false; },
    },
    sumText:{
      type:String,
      default(){return '合计'; },
    },
    summaryMethod:{
      type:Function,
    },
    spanMethod:{
      type:Function,
    },
    selectOnIndeterminate:{
      type:Boolean,
      default(){return true; },
    },
    indent:{
      type:Number,
      default(){return 16; },
    },
    lazy:{
      type:Boolean,
      default(){return null; },
    },
    showOverflowTooltip:{
      type:Boolean,
      default(){return true; },
    },
    load:{
      type:Function,
      default(){return null; },
    },
    treeProps:{
      type:Object,
      default(){return { hasChildren: 'hasChildren', children: 'children' }; },
    },
    parseMethod:{
      type:Function,
    },
    editMethod:{
      type:Function,
    },
    where:{
      type:Object,
      default(){return {} },
    },
    pageSize:{
      type:Number,
      default(){return 10 },
    },
    pageOptions:{
      type:[Object , Boolean],
      default(){return true; },
    },
    listType:{
      type:String,
      default(){return 'table'; },
    },
    listOptions:{
      type:Object,
      default(){return {} },
    },
    total:{
      type:Number,
      default(){return 0 },
    },
    autoLoad:{
      type:Boolean,
      default(){return true; },
    },
    rowPage:{
      type:Number,
      default(){return null },
    },
    reserveSelection:{//为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
      type:Boolean,
      default(){return false; },
    },
  },
  data(){
    return {
      loading:false,
      parseData:[],
      totalData:0,
      whereData:{...this.where},
      editColRowIndex:null,
      currentPage:1,
      currentPageSize:this.pageSize,
      currentListNumber:1,
      selectionData:[],
      isEditStatus:false,
      columnKey:null,
      isSelection:true,
    };
  },
  watch:{
    totalData(v){
      this.$emit('update:total' , v);
    },
    data:{
      deep:true,
      immediate:true,
      handler(data , _data){
        if(this.autoLoad && !tool.obj_equal(data , _data)){
          this.reset();
        }
      }
    },
    columns:{
      deep:true,
      immediate:true,
      handler(columns , _columns){
        if(this.autoLoad && !tool.obj_equal(columns , _columns)){
          this.reset();
        }
      }
    },
    "listOptions.width"(){
      this.initialStyle();
    },
    height(){
      this.initialStyle();
    },
    maxHeight(){
      this.initialStyle();
    },
    loading(){
      this.initialStyle();
    },
  },
  computed:{
    currentPageOptions(){
      return this.pageOptions instanceof Object ? this.pageOptions : {};
    },
  },
  created() {
    if(this.autoLoad){
      this.handleChangeTableData();
    }
    // console.log("comTable created")
  },
  mounted() {
    this.initialStyle();
    // console.log("comTable mounted")
  },
  methods:{
    initColumnKey(columnKey){
      if(this.columnKey === null){
        this.columnKey = (typeof columnKey === 'string' && columnKey ? columnKey : null) || this.rowKey || 'id';
      }
      return this.columnKey;
    },
    handleHeaderCellStyle({row, column, rowIndex, columnIndex}){
      if(!column.width && column.label){
        let label = this.$jquery('<div>'+column.label+'</div>').text();
        let len = label.length;
        let byteLen = len;// 表头label长度
        for (let i = 0; i < len; i++) {if (label.charCodeAt(i) > 255) { byteLen++;}}
        let f = 14  // 根据需要定义标尺，直接使用字体大小确定就行，也可以根据需要定义
        var minWidth = parseInt(f * byteLen * 0.65) //加上一个文字长度
        // console.log("handleHeaderCellStyle" , label , len , minWidth , byteLen , {...Object.assign({},column)});
        if(column.realWidth < minWidth){
          column.minWidth = minWidth;
        }
      }
      return typeof this.headerCellStyle === 'function' ? this.headerCellStyle({row, column, rowIndex, columnIndex}) : this.headerCellStyle;
    },
    initialStyle(){
      if(this.listType === 'table-list'){
        if(!this.rowPage > 0){
          let lw = this.listOptions.width || 300
          this.currentListNumber = parseInt((this.$el.clientWidth) > lw ? ((this.$el.clientWidth) / lw) : (lw / (this.$el.clientWidth))) || 1;
        }else{
          this.currentListNumber = this.rowPage;
        }
      }
      if(this.listType === 'table'){
        this.$nextTick(()=>{
          var $refs = this.$refs.table;
          if(this.limit === 1){
            this.$jquery($refs.$el).find('.el-table__header .el-table-column--selection .cell').css('display','none')
          }else{
            this.$jquery($refs.$el).find('.el-table__header .el-table-column--selection .cell').css('display','inline-block')
          }
          // $refs.initStyle();
        })
      }
    },
    handleSelect(selection , row){
      var removes ;
      if(this.limit > 1 && selection.length > this.limit){
        removes = selection.splice(this.limit);
        for (let i = 0; i < removes.length; i++) {
          this.$refs.table.toggleRowSelection(removes[i], false)
        }
      }else if(this.limit === 1 && selection.length > this.limit){
        removes = selection.splice(0 , selection.length - 1);
        for (let i = 0; i < removes.length; i++) {
          this.$refs.table.toggleRowSelection(removes[i], false)
        }
      }
    },
    handleSetSelect(selection , selected , row){
      var columnKey = this.initColumnKey();
      selected = selected !== false;
      if(selected){
        if(this.limit === 1){
          if(row){
            selection = [row];
          }else if(this.selectionData.length){
            selection = [this.selectionData[0]]
          }else if(selection.length){
            selection = [selection[0]];
          }else{
            selection = [];
          }
        }else if(this.limit > 1 && selection.length > this.limit){
          selection = selection.slice(0, this.limit);
        }
      }
      if(this.$refs.table){
        var children = this.treeProps.children || 'children';
        var isCheck =  this.treeProps.isCheck !== false;
        var selectionKey = columnKey ? [...this.$refs.table.selection.map(d=>d[columnKey])] : [];
        var selections = columnKey ? [...selection.map(d=>d[columnKey])] : [];
        var selectFunc = (data)=>{
          var selectData = [];
          tool.treeListEach(data , ({item , subList})=>{
            let index = selectionKey.indexOf(item[columnKey]);
            let index1 = selections.indexOf(item[columnKey]);
            if((index > -1 && index1 === -1) || (index1 > -1 && index === -1)){
              this.$refs.table.toggleRowSelection(item,  index1 > -1);
              if(isCheck){
                for (let i = 0; i < subList.length; i++) {
                  this.$refs.table.toggleRowSelection(subList[i],  index1 > -1)
                }
              }
            }
            if(index1 > -1){
              selectData.push(item);
              if(isCheck)selectData.push(...subList);
            }
          } , children);
          return selectData;
        }
        selection = selectFunc(this.$refs.table.tableData);
      }
      return selection;
    },
    'handleEmitSelection-change'(selection) {
      if(this.isSelection === false){
        this.selectionData = this.handleSetSelect(selection);
        this.$nextTick(()=>{
          this.$emit('update:selection', this.selectionData);
        })
      }
      this.$nextTick(()=>{
        this.isSelection = false;
        this.$emit('selection-change', this.selectionData );
      })
    },
    handleEmitSelect(selection, row) {
      this.isSelection = true;
      var selected = false ,columnKey = this.initColumnKey() , selectionData = [];
      if(columnKey && selection.filter(d=>d[columnKey]===row[columnKey]).length){selected = true;}
      this.selectionData = this.handleSetSelect(selection, selected , row);
      this.$nextTick(()=>{
        this.$emit('update:selection', this.selectionData);
        this.$emit('select', this.selectionData , row);
        this.isSelection = true;
      })
    },
    'handleEmitSelect-all'(selection) {
      this.isSelection = true;
      var selected = this.selectionData.length === 0 || (this.limit >= 1 && this.selectionData.length < this.limit);
      this.selectionData = this.handleSetSelect(selected ? selection : [], selected);
      this.$nextTick(()=>{
        this.$emit('update:selection', this.selectionData);
        this.$emit('select-all', this.selectionData );
        this.isSelection = true;
      })
    },
    handleChangeEditData(v , ov, {row , column , $props}){
      if(this.isEditStatus === true){
        this.isEditStatus = false;
        column.prop = column.prop || $props.prop;
        this.editMethod && this.editMethod(v , ov , {row , column , $props});
        this.$nextTick(()=>{
          this.isEditStatus = true;
        })
      }
    },
    handlePageChange(page , isPage = true){
      if(isPage === true && page && page !== true && !isNaN(page)){
        this.currentPage = page;
      }else{
        this.currentPage ++;
      }
      var _page = parseInt(this.currentPage);
      this.handleChangeTableData((data)=>{
        if(this.listType === 'list'){
          setTimeout(()=>{
            if(_page > 1 && data.length === 0){
              this.currentPage = _page - 1;
            }
          } , 200)
        }
      });
    },
    handlePageSizeChange(pageSize){
      // console.log(pageSize,"pageSizepageSize");
      localStorage.setItem('pageSize',pageSize)
      // console.log(localStorage.getItem('pageSize'),"localStorage.getItem('pageSize')");
      this.currentPageSize = localStorage.getItem('pageSize')?Number(localStorage.getItem('pageSize')) :pageSize;
      this.handlePageChange(1);
    },
    handleEmit(type , ...vars){
      let commandKey = type === undefined || type === null ? '' : type+"";
      let method = 'handleEmit'+commandKey.slice(0,1).toUpperCase() +commandKey.slice(1)
      if(typeof this[method] === 'function'){
        this[method](...vars);
      }else{
        this.$emit(type, ...vars);
      }
    },
    reload(where){
      if(where && where instanceof Object){
        this.whereData = {...where}
      }
      return this.handleChangeTableData();
    },
    reset(where){
      // console.log(where,"whwww");
      // 当在全部下搜索关键字  需清空cid
      if(where&&where.keyword&&where.cid=='全部'){
        where.cid=""
      }
      // 将cid数组逗号分隔
      if( where&&where.cid&& Array.isArray(where.cid)&&where.cid.length>0){
        where.cid=where.cid.join(",")
        // 点击全部 清空搜索 展示所有
        if(where.cid=='全部'){
          where={}
        }
      }else if(where&&where.cid&&where.cid.length==0){
        where={}
      }
      // console.log(where,"wherer");
      this.currentPage = 1;
      this.parseData = [];
      this.totalData = 0;
      return this.reload(where);
    },
    clearSelection(){
      this.$refs.table.clearSelection();
    },
    toggleRowSelection(row, selected){
      this.$refs.table.toggleRowSelection(row, selected);
    },
    toggleAllSelection(){
      this.$refs.table.toggleAllSelection();
    },
    toggleRowExpansion(row, expanded){
            // console.log(row,"rowrowrow")
            // console.log(expanded,"expandedexpanded")

      this.$refs.table.toggleRowExpansion(row, expanded);
    },
    toggleAllExpansion(expanded){
      // console.log(expanded,"expandedexpandedexpanded")
      this.$refs.table.tableData.forEach(row=>{
        this.toggleRowExpansion(row , expanded);
      })
    },
    setCurrentRow(row){
      this.$refs.table.setCurrentRow(row);
    },
    clearSort(){
      this.$refs.table.clearSort();
    },
    clearFilter(columnKey){
      this.$refs.table.clearFilter();
    },
    doLayout(){
      this.$refs.table.doLayout();
    },
    sort(prop, order){
      this.$refs.table.sort(prop, order);
    },
    handleChangeTableData(callback = null){
      // console.log(this.pageSize,"this.pageSizethis.pageSize");
      this.currentPageSize=localStorage.getItem('pageSize')? Number(localStorage.getItem('pageSize')):this.pageSize
      // console.log(this.currentPageSize,"this.currentPageSize.currentPageSize");
      this.isEditStatus = false;
      var $callback = (data , count)=>{
        callback && callback(data , count);
        this.$nextTick(()=>{
          var columnKey = this.columnKey || this.rowKey;
          if(this.reserveSelection === false){
            this.selectionData = [];
          }
          if(this.selection.length > 0 && columnKey){
            this.selectionData = this.handleSetSelect(this.selection , true);
            this.$emit('update:selection', this.selectionData);
          }
        })
      }
      return new Promise((resolve, reject) => {
        var $dataCallback = (data , count)=>{
          this.loading = false;
          if(this.listType === 'list' && this.currentPage > 1){
            if(data.length > 0)this.parseData = this.parseData.concat(data);
            this.totalData = count
          }else{
            this.parseData = [...data];
            this.totalData = count
          }
          resolve(data , count);
          $callback(data , count);
          setTimeout(()=>{
            this.$emit('done' , data , count);
            this.isEditStatus = true;
          } , 200)
        }
        if(this.loading) {
          this.$setInterval(()=>{
            if(this.loading === false){
              resolve(this.parseData , this.totalData);
              $callback();
              return false;
            }
          } , 100)
          return ;
        }
        this.loading = true;
        if(this.data instanceof Function){
          this.data((data , count)=>{
            $dataCallback( [...data] , count);
          });
        }else if(this.data instanceof Array){
          this.$nextTick(()=>{
            $dataCallback( [...this.data] , this.data.length);
          });
        }else{
          let data = this.data instanceof Object ? {...this.data} : {};
          if(!data.url){ data.url = this.$route.path; }
          let params = {...this.$route.params,...data.params || {}}
          params.page = this.currentPage;
          // params.pageSize=this.currentPageSize;
          params.pageSize = localStorage.getItem('pageSize')?localStorage.getItem('pageSize'): this.pageSize;
          this.$http.request({
            url:data.url,
            method:data.method || 'post',
            params:params,
            data: {...this.whereData},
          }).then((res)=>{
            res = res || {};
            if(this.parseMethod && this.parseMethod instanceof Function){
              this.parseMethod(res , (data , count)=>{
                $dataCallback( [...data||[]] , Math.max(count || 0 , 0));
              });
            }else{
              $dataCallback([...((res.data || {}).data || [])] , (res.data || {}).total || (res.data || {}).count || 0);
            }
          }).catch((error)=>{
            this.loading = false;
            reject(error);
          })
        }
      })
    },
    getTemplateDataValue(Obj , callback, _default = '-'){
      var val ;
      try{
        if(callback && typeof callback === 'function'){
          val = callback(Obj)
        }else{
          val = this.$tool.getDataValue(Obj.row , callback&& typeof callback === 'string' ? callback : Obj.$props.prop , null);
        }
      }catch (e) {val = null;}
      if(val || val === 0 || val === false){
        return val;
      }
      return _default;
    },
    getDataValue(prop , obj , _default = '-' ){
      return this.$tool.getDataValue(obj , prop , _default);
    },
  }
}
</script>

<style scoped lang="less">
::v-deep {
  .infinite-list-wrapper .list .list-item{
    list-style: none;
  }
  .el-divider--horizontal{
    margin: 4px 0;
  }
  .cell-template{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 100%;
    *{
      display: inline-block;
      padding: 0;
      margin: 0;
      border: none;
    }
  }
  .infinite-list-wrapper ul li:not(:last-child):not(.el-timeline-item){
    margin-bottom: 20px;
  }
  .el-table--border th .cell, .el-table--border td .cell{
    padding: 0 !important;
  }
}
</style>

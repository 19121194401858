import Vue from 'vue'
import Vuex from 'vuex'
import tool from "@/Utils/tool";
import request from "@/Utils/api";
Vue.use(Vuex)

const files = require.context('./modules', true, /\.js$/)

const modules = {};
//key是相对路径名
files.keys().forEach(key => {
  let module = files(key) || null;
  if(module)modules[key.substr(2 , key.length-5)] = {namespaced: true,...module.default};
})
tool.locationInit()
var defaultData = {
  userInfo:{
    headimg:require('@/assets/index/tx.png'),
    type:null,//用户类型 0:游客 1:学生 2:老师
    id: 0,
    powers: [],
    token: null,
    nickname:null,
    realname:null
  }
};
function getLocalUserInfo(){
  var userInfo = localStorage.getItem("user_token") && localStorage.getItem("userInfo") ? tool.decrypt(localStorage.getItem("userInfo"),'userInfo') : '';
    // console.log(userInfo,"``````````````````")
  return {...defaultData.userInfo,...userInfo ? JSON.parse(userInfo) : {}}
}
var userInfo = getLocalUserInfo();
export default new Vuex.Store({
  modules:{...modules},
  state: {
    msg_notread:0,
    userInfo:userInfo,
    defaultData:defaultData,
    exception:{
      status:0,
      path:null,
      goPath:null,
    },
    user_id: localStorage.getItem("user_id"),
    user_token:localStorage.getItem("user_token"),
    totle: 0,
    pageOptions:{
      width:0,
      height:0,
    },
    lodinShow: false,
    showBinding:false,
    // 上传文件地址
    action: (location.baseURL || '')+'/xapi/com.upload/upload',
    tmAction: (location.baseURL || '')+'/api/v1_0_0.question/get_import_data',
    cases_upload: (location.baseURL || '')+'/api/v1_0_0.question/cases_upload',
    socketUrl: {path:'/ws',host:process.env.NODE_ENV === 'production' ? '' : 'www.demo.kencoding.com.cn',port:0},
    topicType: {
      name: '新建题单',
      detail: {},
      api: '',
      path: ''
    },
    headerValue: {
      fields: '',
      value: {},
      flag:false
    },
    answerData: [],
    answerCorrect: [],
    courseHot: [],
    knowList: [],
    footerData:{},
    indexImg:[],
    courseImg:[],
    LoginRequests:[],
    requestNum:0,
    loginOutCallback: null,
    dataList:{
      course:{
        config:{
          url:"/api/v1_0_0.course/config",
        },
        options:{
          class:{
            title:'种类',
            list:[{label:'视频',value:1},{label:'文章',value:2}]
          }
        }
      },
      question:{
        config:{
          url:"/api/v1_0_0.question/options",
        }
      },
      question_list:{
        config:{
          url:"/api/v1_0_0.question_list/list_params",
        }
      },
      question_other:{
        config:{
          url:"/api/v1_0_0.question_other/options",
        }
      },
      forum:{
        config:{
          url:"/api/v1_0_0.forum/options",
        }
      },
      user_group:{
        config:{
          url:"/api/v1_0_0.user_group/list_params",
        }
      },
    },
    refreshData:{},
    systemCofing:[],
    currentCrumbList:[],
    menuLoading:true,
    menuRoles:[],
    menuList:[],
  },
  mutations: {
    // 修改值
    SET(state, obj) {
      if(obj.key.indexOf('.') > 0){
        let priexKey = obj.key.substr(0 , obj.key.indexOf('.'))
        let key = obj.key.substr(obj.key.indexOf('.') + 1)
        if(key){
          let data = state[priexKey];
          tool.setDataValue({[key]:obj.value} , data);
          state[priexKey] = {...data}
        }
      }else{
        state[obj.key] = obj.value;
      }
    },
    // 修改选择数据值
    SETDATA(state, obj) {
      let o = {};
      if(obj.key){
        o[obj.key] = obj.value;
      }else{
        o = {...obj};
      }
      tool.setDataValue(o , state.dataList);
    },
    // 修改选择数据值
    SETREFRESH(state, obj) {
      let o = {};
      if(obj.key){
        o[obj.key] = obj.value !== true;
      }else{
        o = {...obj};
      }
      tool.setDataValue(o , state.refreshData);
    },
  },
  actions: {
    clearLogin({state,commit,dispatch}){
      commit('SET' , {'key':'userInfo' ,'value': ((state.defaultData || {}).userInfo || {})})
      commit('SET' , {'key':'userType' ,'value':null})
      commit('SET' , {'key':'user_token' ,'value':null})
      // 登录令牌错误 跳转登录
      localStorage.removeItem("auth_token")
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_token")
      localStorage.removeItem("type")
      localStorage.removeItem("userInfo")
      dispatch('web/getMenuList');
    },
    getDataList({state,commit} , data){
      data = data instanceof Object ? data : (data ? {keys:data} : {} );
      return new Promise((resolve, reject) => {
        let keys = data.keys && data.keys instanceof Array ? data.keys : (data.keys || '').toString().split(',');
        keys = tool.obj_filter(keys);
        let name = data.name || ''
        if(name && (state.dataList[name] || {}).config){
          data = Object.assign({} , data , state.dataList[name].config)
        }
        if(name && !data.url){
          data.url = '/xapi/'+name+'/options';
        }
        if(!name && data.url){
          let u = data.url.replace('.' , '/').split('/');
          name = u[u.length - 2] || '';
        }
        if(keys.length === 0 && !name){
          return reject();
        }
        let isAll = data.isAll !== false;
        let $return  = (name , keys) => {
          let datas = null;
          if((keys || []).length > 0){
            datas = {};
            let dataCount = 0;
            for (const nameKey in keys) {
              let r = ((state.dataList[name] || {}).options || {})[keys];
              if(r){
                datas[keys[nameKey]] = r;
                dataCount ++ ;
              }
            }
            if(dataCount !== keys.length) {
              datas = null
            }
          }else{
            datas = (state.dataList[name] || {}).options || null
          }
          if(datas && isAll){
            let obj = datas ? JSON.parse(JSON.stringify(datas)) : null
            for (const datasKey in obj) {
              let list = obj[datasKey].list || [];
              if((list[0] || {}).value !== '全部'){
                obj[datasKey].list = [{value: '全部', label: "全部"}].concat(obj[datasKey].list || [])
              }
            }
            return obj
          }
          return datas
        }
        let datas = $return(name , keys);
        if(datas){
          return resolve(datas);
        }
        request({
          url:data.url,
          method:data.method || 'post',
          params:data.params || {},
          data: data.data || {},
          cache:60
        }).then(({data})=>{
          let obj = {};
          for (const objKey in data) {
            obj[objKey] = data[objKey]
          }
          if(keys.length > 0){
            for (const nameKey in keys) {
              if(obj[keys[nameKey]] === undefined){
                obj[keys[nameKey]] = {}
              }
            }
          }
          state.dataList[name] = state.dataList[name] || {};
          state.dataList[name].options = state.dataList[name].options || {};
          tool.setDataValue(obj , state.dataList[name].options);
          return resolve($return(name , keys) || {});
        }).catch(e=>{
          return reject(e);
        })
      })
    }
  },
})

<template>
  <resize-box @resize="resizeHander" @done="resizeHander($event , 'done')" v-loading="initLoading">
    <div :class="{pdfBoxCont:true,pdfBoxContFlex:boxStyle.pdfWidth>boxStyle.pdfHeight}" :style="bStyle" :id="idEl+'Con'">
      <pdf
          ref="pdf"
          :src="reloadUrl"
          :page="currentPage"
          :rotate="rotate"
          @progress="progress"
          @num-pages="numPages"
          @page-loaded="pageLoaded"
          @loaded="loaded"
          @error="error"
          @link-clicked="linkClicked"
          @password="password"
          v-loading="loading"
          v-if="reloadUrl && errorMsg === null"
      >
      </pdf>
      <div class="errorBox"  v-if="errorMsg !== null">
        <div>
          <slot class="errorBox" name="error"> PDF加载失败：{{errorMsg}} </slot>
        </div>
      </div>
    </div>
    <slot v-bind="{changePdfPage}">
      <div class="pageButton">
        <div>
          <el-button
              size="mini"
              @click.stop="changePdfPage(0)"
              round
              :disabled="currentPage <= 1"
          >
            上一页
          </el-button>
          <span> &nbsp; {{ currentPage }} / {{ pageCount }} &nbsp; </span>
          <el-button
              size="mini"
              @click.stop="changePdfPage(1)"
              round
              :disabled="currentPage >= pageCount"
          >
            下一页
          </el-button>
        </div>
      </div>
    </slot>
  </resize-box>
</template>

<script>
import pdf from "vue-pdf";
import $ from "jquery";
import ResizeBox from "@/components/com/el/resizeBox";
export default {
  name: "elPdf",
  components: {ResizeBox, pdf},
  model: {
    prop: 'page',
    event: 'change'
  },
  props: {
    src: {
      type:String,
      default: () => "",
    },
    page: {
      type:Number,
      default: () => 1,
    },
  },
  data(){
    return {
      loading:false,
      reloadUrl:"",
      reloadContent:null,
      pdfTask:null,//pdf的链接，可以是相对、绝对地址或者是一个pdf的加载任务
      idEl:"pdfBox"+(new Date()).getTime(),
      currentPage:1,// pdf文件页码
      pageCount: 0, // pdf文件总页数
      rotate:0,//pdf的旋转角度，‘90’的倍数才有效；
      loadedRatio: 0,
      loadIndex:0,
      bStyle:{},
      elStyle:{},
      errorMsg:null,
      initLoading:false,
      boxStyle: {
        width:0,
        height:0,
        pdfWidth:0,
        pdfHeight:0
      },
    };
  },
  watch: {
    src(v) {
      console.log(v,"=========vvv");
      if(this.boxStyle.width > 0 && this.boxStyle.height > 0){
        this.initLoading = true
        this.start(v);
      }
    },
    page(v){
      if(this.boxStyle.pdfWidth !== null && v !== this.currentPage){
        this.loadIndex = 0;
        this.currentPage = v;
      }
    },
    loading(v){
      if(v){
        this.errorMsg = null;
      }
    },
    boxStyle:{
      deep:true,
      immediate:true,
      handler(v , o){
        v = {...v || {}};
        o = {...o || {}};
        if(v.pdfWidth > 0){
          this.$nextTick(()=>{
            this.resizeHander({...v})
          })
        }
      }
    }
  },
  methods:{
    resizeHander({width,height} , emit){
      this.boxStyle.width = width
      this.boxStyle.height = height
      this.$emit('resize' , {width,height});
      var canvas = ((this.$refs.pdf || {}).$refs || {}).canvas
      if(emit){
        this.start(this.src);
      }else if(this.boxStyle.pdfWidth > 0){
        let {width,height , pdfWidth , pdfHeight} = this.boxStyle;
        var canvasWidth,canvasHeight
        if(pdfWidth > pdfHeight){
          canvasWidth = parseFloat((pdfWidth / (pdfHeight / height)).toFixed(2))
          if(canvasWidth > width){
            canvasHeight = parseFloat((height / (canvasWidth / width)).toFixed(2))
            canvasWidth = width
          }else{
            canvasHeight = height
          }
        }else{
          canvasWidth = width
          canvasHeight = parseFloat((pdfHeight / (pdfWidth / width)).toFixed(2))
          this.bStyle = {};
          if(height > canvasHeight){
            this.bStyle = {marginTop:((height - canvasHeight)/2)+'px'};
          }
        }
        // console.log("resizeHander" , canvasWidth , canvasHeight , {...this.bStyle} , {...this.boxStyle});
        $(canvas).css('height' , canvasHeight+'px')
        $(canvas).css('width' , canvasWidth+'px')
      }
    },
    start(src){
      
      // if(process.env.NODE_ENV !== 'production' && src){
      //   src = src.replace((new URL(src)).origin+'/' , location.currentURL+'/')
      // }
      if(src && src !== this.reloadUrl){
        this.boxStyle.pdfWidth = 0;
        this.boxStyle.pdfHeight = 0;
        this.loadedRatio = 0;
        this.loading = true;
        this.initLoading = true
        this.loadIndex = 0;
        this.reloadUrl = src;
        console.log(this.reloadUrl,"this.reloadUrlthis.reloadUrlthis.reloadUrl");
        this.currentPage = 1;
      }
    },
    //pdf的页面的加载进度，Rang[0,1]；
    progress(e){
      if(this.loadedRatio !== e){
        this.loadedRatio = e
        this.$emit('progress' , e)
      }
    },
    //监听pdf加载，获取pdf的页数；
    numPages(e){
      if(e){
        this.pageCount = e
        this.$emit('numPages' , e)
        this.initLoading = false;
      }
    },
    //pdf某个页面加载成功回调，e为页面索引值；
    pageLoaded(e){
      this.currentPage = e
      if(e){
        let page = parseInt(this.page > 0 && this.page <= this.pageCount ? this.page : 1)
        this.$emit('pageLoaded' , e , this.pageCount)
        this.$emit('change' , e);
        this.$nextTick(()=>{
          var canvas = this.$refs.pdf.$refs.canvas;
          this.$tool.MutationObserver($(canvas).get(0) , (e)=>{
            this.boxStyle.pdfWidth = $(canvas).width();
            this.boxStyle.pdfHeight = $(canvas).height();
          });
          this.loading = false;
          if(this.loadIndex === 0){
            this.loadIndex = 1;
            if(this.currentPage !== page){
              setTimeout(() => {
                this.loading = true;
                this.currentPage = page;
              }, 150)
            }
          }
        })
      }
    },
    // pdf加载时
    loaded(e) {
      this.$emit('loaded' , e)
    },
    //pdf加载失败回调；
    error(e){
      this.errorMsg = e;
      this.$emit('error' , e)
      console.log("pdf加载失败回调:" , e);
      this.$nextTick(()=>{
        this.loading = false;
      })
    },
    //单机内部链接时触发；
    linkClicked(e){
      this.$emit('link-clicked' , e)
    },
    // updatePassword:函数提示需要输入的密码；reason:提示（'NEED_PASSWORD' or 'INCORRECT_PASSWORD'）；
    password(updatePassword,reason){
      this.$emit('password' , updatePassword,reason)
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      if(val === 0 && this.currentPage > 1) {
        this.loading = true;
        this.currentPage--;
      }
      if(val === 1 && this.currentPage < this.pageCount) {
        this.loading = true;
        this.currentPage++;
      }
      if (this.loading && this.$refs.pdf && this.boxStyle.pdfHeight > this.boxStyle.pdfWidth) {
        $('#'+this.idEl+'Con').scrollTop(0);
      }
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep{
  .pdfBoxCont{
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    overflow-x:hidden;
  }
  .pdfBoxContFlex{
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    overflow-y:hidden;
    margin:auto;
  }
  .errorBox{
    width: 100%;
    height: 100%;
    display: table;

    > div{
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
  .boxCenter{
    display: table-cell;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
  }
  .pageButton{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 28px;
    line-height: 28px;
    text-align: center;
    > div {
      background-color: rgb(255 255 255 / 35%);
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<template>
  <div class="tab-box">
    <div class="tab-box-content" :style="bodyStyle" v-if="tabPosition === 'bottom'">
      <slot :name="currentTab.slot || 'default'" v-bind="currentTab" v-if="loading === false"></slot>
    </div>
    <div style="display: flex;">
      <div :style="{
        width:'calc(100% - '+(tabHeaderWidth||'15px')+')'
      }" v-if="tabPosition === 'right'">
        <div class="tab-box-content" :style="bodyStyle">
          <slot :name="currentTab.slot || 'default'" v-bind="currentTab" v-if="loading === false"></slot>
        </div>
      </div>
      <div :style="['top','bottom'].includes(tabPosition) ? {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex:1
          }:{
                minHeight:'100%'
          }">
        <div :class="'el-tabs el-tabs--'+tabPosition+' el-tabs--'+type" v-if="$scopedSlots['header-left']">
          <div :class="'el-tabs__header is-'+tabPosition">
            <div class="flex-center"
                 :style="['top','bottom'].includes(tabPosition) ? {
                  paddingLeft:'15px',
                  paddingRight:'15px',
                  height:tabHeaderHeight
                }:{

                }"><slot name="header-left"></slot></div>
          </div>
        </div>
        <el-tabs ref="tabs" v-model="activeName"
                 :type="type"
                 :closable="closable"
                 :addable="addable"
                 :tab-position="tabPosition"
                 :stretch="stretch"
                 :before-leave="beforeLeave"
                 @tab-click="handleEmit('tab-click' , ...arguments)"
                 @tab-remove="handleEmit('tab-remove' , ...arguments)"
                 @tab-add="handleEmit('tab-add' , ...arguments)"
                 @edit="handleEmit('edit' , ...arguments)"
                 style="flex: 1;"
                 :class="headerClass"
        >
          <el-tab-pane
              :label="tab.label"
              :disabled="tab.disabled === true"
              :name="tab.name"
              :closable="tab.closable === true"
              :lazy="tab.lazy === true"
              v-for="(tab , index) in tabList"
              :key="index">
            <template slot="label">
              <slot name="label" v-bind="tab">
                {{tab.label}}
              </slot>
            </template>
          </el-tab-pane>
        </el-tabs>
        <div :class="'el-tabs el-tabs--'+tabPosition+' el-tabs--'+type" v-if="$scopedSlots['header-right']">
          <div :class="'el-tabs__header is-'+tabPosition">
            <div class="flex-center"
                 :style="['top','bottom'].includes(tabPosition) ? {
                  paddingLeft:'15px',
                  paddingRight:'15px',
                  height:tabHeaderHeight
                }:{

                }"><slot name="header-right"></slot></div>
          </div>
        </div>
      </div>
      <div :style="{
        width:'calc(100% - '+(tabHeaderWidth||'15px')+')'
      }" v-if="tabPosition === 'left'">
        <div class="tab-box-content" :style="bodyStyle">
          <slot :name="currentTab.slot || 'default'" v-bind="currentTab" v-if="loading === false"></slot>
        </div>
      </div>
    </div>
    <div class="tab-box-content" :style="bodyStyle" v-if="tabPosition === 'top'">
      <slot :name="currentTab.slot || 'default'" v-bind="currentTab" v-if="loading === false"></slot>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props:{
    tabList:{
      type:Array,
      default(){return [];}
    },
    value:String,
    closable:{
      type:Boolean,
      default(){return false;}
    },
    addable:{
      type:Boolean,
      default(){return false;}
    },
    editable:{
      type:Boolean,
      default(){return false;}
    },
    tabPosition:{
      type:String,
      default(){return 'top';},//top/right/bottom/left
      validator(v){
        return ['top','right','bottom','left'].includes(v);
      },
    },
    type:{
      type:String,
      default(){return null;},//card/border-card
      validator(v){
        return ['card','border-card'].includes(v);
      },
    },
    stretch:{
      type:Boolean,
      default(){return false;}
    },
    beforeLeave:Function,//Function(activeName, oldActiveName)
    bodyStyle:{
      type:[String,Object],
      default(){return {};}
    },
    headerClass:{
      type:[String,Object],
      default(){return {};}
    },
  },data(){
    return {
      activeName : this.value,
      tabHeaderHeight:null,
      tabHeaderWidth:null,
      tabBodyHeight:null,
      loading:false,
    }
  },
  computed:{
    currentTabList(){
      return this.tabList.map((current)=>{
        if(current.slot && ['header-right','header-left','label','default'].includes(current.slot)){
          current.slot = 'default'
        }
        return current;
      });
    },
    currentTab(){
      var current = this.tabList.filter(tab=> tab.name+'' === this.activeName+'')[0] || null;
      if(current && current.slot && ['header-right','header-left','label','default'].includes(current.slot)){
        current.slot = 'default'
      }
      return current;
    },
  },
  watch:{
    activeName(v){
      this.loading = true;
      this.$emit('change',v+'');
      this.initStyle();
      this.$nextTick(()=>{
        this.loading = false;
      })
    },
    value(v){
      if(v+"" !== this.activeName+""){
        this.activeName = v;
        this.initStyle();
      }
    },
    tabPosition(){
      this.initStyle();
    },
  },
  mounted() {
    this.$nextTick(()=>{
        this.initStyle();
    })
  },
  methods:{
    initStyle(){
      var headerBody = this.$el.querySelector('.el-tabs__header') , bodyBody = this.$el.querySelector('.tab-box-content');
      this.tabHeaderHeight = headerBody.clientHeight ? headerBody.clientHeight+'px' : null;
      this.tabHeaderWidth = headerBody.offsetWidth ? headerBody.offsetWidth+'px': null;
      this.tabBodyHeight = bodyBody.clientHeight ? bodyBody.clientHeight+'px': null;
      // console.log("initStyle" , this.tabHeaderWidth , this.tabHeaderHeight , this.tabBodyHeight , this);
    },
    handleEmit(type , ...vars){
      this.$emit('change',this.activeName+'');
      this.$emit(type,...vars);
    },
  }
}
</script>

<style scoped lang="less">
.tab-box::v-deep {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
  border: 1px solid #DCDFE6;
  background-color: #fff;
  .flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-tabs--border-card > .el-tabs__content{
    display: none;
  }
  .el-tabs--right.el-tabs--border-card .el-tabs__header.is-right,
  .el-tabs--left.el-tabs--border-card .el-tabs__header.is-left{
    min-height: 100%;
  }
  .tab-box-left,.tab-box-right{
    padding:0;
    margin-bottom: -1px;
  }
  .el-tabs--border-card>.el-tabs__header {
    border-bottom: 1px solid #E4E7ED;
  }
  .tab-box-content{
    padding: 15px;
    width: calc(100% - 30px);
    overflow: auto;
  }
  .el-tab-badge{
    position: absolute;
  }
}
.el-tabs--border-card::v-deep{
  box-shadow:none;
  border: none;
  > .el-tabs__content {
    height: 0;
    padding: 0;
  }
}
</style>
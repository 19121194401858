<template>
  <el-card :header="header" :body-style="bodyStyle" :shadow="shadow" :class="{'hidden-card':isHidden}">
    <template slot="header">
      <slot name="header"></slot>
    </template>
    <template>
      <slot></slot>
    </template>
  </el-card>
</template>

<script>
export default {
  name: "diyCard",
  props:{
    header:{
      type:String,
    },
    bodyStyle:{
      type:Object,
    },
    shadow:{
      type:String,
      default(){return 'always';},
      validator(val){
        return ['always','hover','never'].includes(val);
      }
    },
    isCardJudge:{
      type:Boolean,
      default(){return false;},
    }
  },
  data(){
    return {
      isHidden:false,
    };
  },
  mounted() {
    if(this.isCardJudge && this.$children.length === 1){
      let children = ((this.$el.children[0] || {}).children || [])[0];
      if(children){
        this.isHidden = children.classList.contains("el-card");
      }
    }
  },
  updated() {
    if(this.isCardJudge && this.$children.length === 1){
      let children = ((this.$el.children[0] || {}).children || [])[0];
      if(children){
        this.isHidden = children.classList.contains("el-card");
      }
    }
  }
}
</script>

<style scoped lang="less">
::v-deep.hidden-card{
  box-shadow:none;
  border:none;
  > .el-card__header{
    padding: 18px 0;
    border: none;
  }
  > .el-card__body{
    padding: 0;
  }
}
</style>
<template>
  <div :id="idEl" v-loading="!isDone">
    <slot :box-style="boxStyle">

    </slot>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "resizeBox",
  props: {
    resizeEl: {
      type:[Object,String]
    },
    visible: {
      type:[Boolean]
    },
  },
  data(){
    return {
      loading:false,
      idEl:"resizeBox"+(new Date()).getTime(),
      isDone:false,
      boxStyle:{
        width:0,
        height:0,
      },
    };
  },
  watch: {
    boxStyle:{
      deep:true,
      immediate:true,
      handler(v){
        v = v || {};
        if(v.width > 0 && v.height > 0){
          this.$nextTick(()=>{
            $(this.$el).find('#'+this.idEl).css('width',v.width+'px')
            $(this.$el).find('#'+this.idEl).css('height',v.height+'px')
            this.loading = false;
            if(!this.isDone){
              this.isDone = true;
              this.$emit('done' , {...v});
            }else{
              this.$emit('resize' , {...v});
            }
          })
        }
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.init();
    })
  },
  methods:{
    init(){
      let $el = $(this.$el).parent();
      let changeFUnc = (e)=>{
        if($el.length > 0 && (this.boxStyle.width !== $el.width() || this.boxStyle.height !== $el.height())) {
          this.loading = true;
          this.boxStyle.width = $el.width()
          this.boxStyle.height = $el.height()
        }
      }
      changeFUnc();
      this.$tool.MutationObserver($el.get(0) , (e)=>{
        changeFUnc(e.type);
      });
      this.$tool.MutationObserver($(this.$el).get(0) , (e)=>{
        changeFUnc(e.type);
      });
    },
  }
}
</script>

<style scoped lang="less">
div::v-deep{
  position: relative;
}
</style>
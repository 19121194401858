<template>
  <div class="el-divider-box">
    <el-divider :content-position="contentPosition" :direction="direction">
      <slot name="title" v-bind:title="title">{{ title || '' }}</slot>
    </el-divider>
    <div :style="Object.assign({padding:'0 20px',marginBottom:'15px'},bodyStyle)">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "dividerBox",
  props:{
    direction:{
      type:String,
      default(){return 'horizontal'}//horizontal / vertical
    },
    borderColor:{
      type:String,
      default(){return '#DCDFE6'}
    },
    contentPosition:{
      type:String,
      default(){return 'center'}//left / right / center
    },
    title:{
      type:String,
      default(){return null}//left / right / center
    },
    position:{
      type:[Number,String],
      default(){return '20px'}
    },
    bodyStyle:{
      type:Object,
      default(){return {}}
    },
  },
  watch:{
    borderColor(){
      this.styleChange();
    },
    position(){
      this.styleChange();
    },
  },
  mounted() {
    this.styleChange();
  },
  methods:{
    styleChange(){
      let divider = this.$el.getElementsByClassName('el-divider')[0] || null
      let dividerText = divider ? (divider.getElementsByClassName('el-divider__text')[0] || null) : null
      this.$el.style.borderColor = this.borderColor;
      if(divider){
        divider.style.backgroundColor = this.borderColor;
      }
      if(dividerText){
        let position = this.position + "";
        if(!isNaN(position)){
          position = position +'px';
        }
        if(this.contentPosition === 'left'){
          dividerText.style.left = position;
          dividerText.style.right = null;
        }
        if(this.contentPosition === 'right'){
          dividerText.style.right = position;
          dividerText.style.left = null;
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.el-divider-box::v-deep{
  border-left:1px solid #DCDFE6;
  border-right:1px solid #DCDFE6;
  border-bottom:1px solid #DCDFE6;
  margin-bottom: 20px;
  .el-divider{
    margin-top: 30px;
  }
}
</style>
import {mapState} from "vuex";
import tool from "@/Utils/tool";
import $ from "jquery";
import {
    routeNavigateTo,
    routeNavigateBack,
    setRouterParams,
    routeNavigate,
    getRouteToUrl,
    getRouteTargetUrl
} from "@/router";
tool.locationInit();
import regularTime from "@/Utils/regularTime";
var routeIsChange = null;
var routeIsDone = null;
var routeChangeType = 2;
var routeCount = 0;
var routeElementViewDepth = 0;
var oldMatched = [];
var RouteNextTickFunc = null;

const comMixin = {
    data() {
        return {
            loadingData: {},
            othersData: {
                vshareConfig:{
                    shareList: ["mshare","sqq","weixin","mail",],
                    share: [{bdSize: 24}],
                    common: {bdSign: "off",},
                    selectShare: [
                        //此处放置划词分享设置
                        { bdSelectMiniList:["mshare","sqq","weixin","mail",],bdContainerClass: true },
                    ],
                },
            },
            currentRoute: {},
            routerViewDepth:-1,
        }
    },
    computed:{
        ...mapState(['systemCofing' , 'userInfo' , 'user_token']),
        userType(){
            return this.userInfo.type;
        },
        currentRouteName(){
            return this.currentRoute.name;
        }
    },
    created() {
        try{
            var  _this = this;
            if(routeChangeType === null && routeIsDone === null){
                routeIsDone = false;
                routeChangeType = 2;
            }
            routeChange(_this , null , ()=>{
                routeIsDone = true;
                this.$nextTick(()=>{
                    routeIsDone = null;
                    routeChangeType = null;
                    routeIsChange = null;
                })
            });
            this.$watch("$route" , function(a,b){
                if(routeChangeType === null && routeIsDone === null){
                    routeIsDone = false;
                    let type = null;
                    if(a.path === '/redirect'){
                        if((a.query.path && a.query.path !== b.path)||(a.query.name && a.query.name !== b.name)) type = 2;
                        if(type === null && a.query.params && tool.obj_equal(a.query.params , b.params) === false) type = 1;
                        if(type === null && a.query.query && tool.obj_equal(a.query.query , b.query) === false) type = 0;
                    }else{
                        if(a.path !== b.path) type = 2;
                        if(type === null && tool.obj_equal(a.params , b.params) === false) type = 1;
                        if(type === null && tool.obj_equal(a.query , b.query) === false) type = 0;
                    }
                   routeChangeType = type
				   routeElementViewDepth = 1;
				   oldMatched = [...b.matched];
                }
                if(routeIsDone !== true && routeChangeType >= 0){
                    _this.$nextTick(()=>{
                        routeChange(_this,()=>{
                            _this.routeListener(a,b , routeChangeType);
                        }, ()=>{
                            routeIsDone = true;
                            _this.$nextTick(()=>{
                                routeIsDone = null;
                                routeChangeType = null;
                                routeIsChange = null;
                            });
                            var changeIndex = -1 , isScroll = true , scrollIndex = routeElementViewDepth;
                            for (let i = 0; i < a.matched.length; i++) {
                                if((b.matched[i] || {}).path !== a.matched[i].path){
                                    changeIndex = i;
                                    break;
                                }
                            }
                            for (let i = 0; i < a.matched.length; i++) {
                                if(isScroll === true && i < (changeIndex===-1?i - 1 :changeIndex)){
                                    const _defaultComponets = ((a.matched[i]|| {}).instances || {}).default || {};
                                    if(_defaultComponets){
                                        try{
                                            const _noScroll = $(_defaultComponets.$el).attr('no-scroll');
                                            if(_noScroll !== undefined && _noScroll !== 'false'){
                                                isScroll = false;
                                                scrollIndex = i - 1;
                                            }
                                        }catch (e) {}
                                    }
                                }
                            }
                            const _defaultComponets = ((a.matched[scrollIndex]|| {}).instances || {}).default || null;
							if(isScroll && scrollIndex > 0 && _defaultComponets){
								if(routeCount > 0){
                                    try{
                                        _defaultComponets.$el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        })
                                    }catch (e) {}
								}
								routeCount ++ ;
							}
							if(changeIndex > -1){
                                const _defaultComponets = ((a.matched[changeIndex]|| {}).instances || {}).default || null;
                                if(_defaultComponets && typeof _defaultComponets['handleRouteCallback'] === 'function'){
                                    _defaultComponets.handleRouteCallback(true);
                                }
                            }
                        });
                    })
                }
            })
            var userInfo = _this.userInfo ;
            this.$watch("$store.state.userInfo" , function(v,o){
                if(o !== undefined){
                    let is_change ;
                    _this.userInfoListener(v = v || {} , o = o || {} , is_change = (v.id || 0)+'' !== (o.id || 0)+'');
                    if(routeIsDone !== true && is_change && _this.currentRoute.path){
                        _this.routeUpdate(_this.$route);
                        _this.routeChange(_this.currentRoute);
                    }
                }
                return v;
            } ,{immediate:true,deep:true})
        }catch (e) {
            // console.log("userInfo.id"  ,e);
        }
    },
    destroyed() {
        this.$clearInterval();
        this.$clearTimeout();
    },
    methods: {
        $isPowers(power){
            var powers = this.userInfo.powers || [];
            if(power && power instanceof Array){
                return power.map(d=>powers.includes(d)?1:0).indexOf(1) > -1;
            }
            return powers.includes(power)
        },
        $setInterval(cb , timeout){
            var _rt = (this.othersData.regularTime = this.othersData.regularTime || {...regularTime})
            return _rt.setInterval(cb , timeout);
        },
        $setTimeout(cb , timeout){
            var _rt = (this.othersData.regularTime = this.othersData.regularTime || {...regularTime})
            return _rt.setTimeout(cb , timeout);
        },
        $clearInterval(index = null){
            var _rt = (this.othersData.regularTime = this.othersData.regularTime || {...regularTime})
            return _rt.clearInterval(index);
        },
        $clearTimeout(index = null){
            var _rt = (this.othersData.regularTime = this.othersData.regularTime || {...regularTime})
            return _rt.clearTimeout(index);
        },
        userInfoListener(){},
        routeListener(){},
        routeChange(){},
        routeUpdate(){},
        routeQueryUpdate(where , cb){
            var is = false,obj = {query:this.$route.query,params:this.$route.params,name:this.$route.name,path:this.$route.path};
            for (const whereKey in where) {
                if(obj.query[whereKey] !== undefined){
                    if(obj.query[whereKey] !== where[whereKey]){
                        obj.query[whereKey] = where[whereKey];
                        is = true;
                    }
                }else if(obj.params[whereKey] !== undefined){
                    if(obj.params[whereKey] !== where[whereKey]){
                        obj.params[whereKey] = where[whereKey];
                        is = true;
                    }
                }
            }
            if(is){
                let _obj = setRouterParams(obj.path , obj.params);
                obj.path = _obj.path;
                routeNavigateTo({ path:obj.path, query:obj.query, replace:true}).catch(()=>{cb&&cb()});
            }else{
                cb && cb();
            }
        },
        addEventListener(type , callback , target = null , is_remove = false){
            if(type === 'scroll' && !target && this.$el){
                target = this.$jquery(this.$el).parent().get(0);
            }else{
                target = target || this.$el;
            }
            if(type && target){
                var $target = this.$jquery(target).get(0) , $listener;
                if($target){
                   if(callback === true){
                       $listener = $target.removeEventListener(type)
                   }else if(is_remove){
                        $listener = $target.removeEventListener(type, callback)
                    }else{
                        $listener = $target.addEventListener(type, callback)
                    }
                }
            }
        },
        handleEmit(type , ...vars){
            let commandKey = type === undefined || type === null ? '' : type+"";
            let method = 'handleEmit'+commandKey.slice(0,1).toUpperCase() +commandKey.slice(1)
            if(typeof this[method] === 'function'){
                this[method](...vars);
            }else{
                this.$emit(type, ...vars);
            }
        },
        $config(name , _default){return getConfig(name , _default , this.systemCofing);},
        $routerGo( number = -1 , to){
            routeNavigateBack(number , to)
        },
        obj_diff_key:tool.obj_diff_key,
        obj_intersect_key:tool.obj_intersect_key,
        obj_equal:tool.obj_equal,
        obj_filter:tool.obj_filter,
        obj_assign:tool.ObjectAssign,
        handleRouteCallback(callback){
            if(this.othersData.routeCallback === 1)return ;
            this.othersData.routeCallback = 1;
            if(typeof callback === 'function'){
                RouteNextTickFunc = callback;
            }else if(typeof callback === 'string'){
                var $callback = this.$route.query[callback || '$callback'] || null;
                if($callback && typeof $callback === 'string' && typeof this[$callback] === 'function'){
                    this.$nextTick(()=>{
                        this[$callback]();
                    })
                }
            } else if(callback === true && RouteNextTickFunc !== null){
                var func = RouteNextTickFunc;
                RouteNextTickFunc = null;
                this.$nextTick(()=>{
                    func.call(this);
                })
            }
        },
        handleCommand(command , ...vars){
            return this.$handleCommand(command , ...vars);
        },
        $handleCommand(command , ...vars){
            let commandKey = command === undefined || command === null ? '' : command+"";
            if(!commandKey || this.loadingData['command'+commandKey]) return ;
            this.loadingData['command'+commandKey] = true;
            let method = 'handleCommand'+commandKey.slice(0,1).toUpperCase() +commandKey.slice(1)
            let result = null;
            var _this = this;
            if(typeof this[method] === 'function'){
                result = this[method](...vars);
            }else {
                var methodFunc = null;
                if(commandKey === 'collect'){
                    methodFunc = (type,id , success , collect_type = null)=>{
                        let $t = typeof success;
                        return this.$http.post('/api/v1_0_0.user/collect' ,{id,type,collect_type:collect_type===true ? 'y' :(collect_type === false ? 'n' : '')}).then(({data,msg})=>{
                            if(success && $t === 'function'){
                                success({is_collect:data.is_collect,collect_count:data.collect_count})
                            } else if(success && $t === 'string'){
                                this.$message.success(msg);

                                var keys = success.replace(/(\[|\])/g,'.').split('.').filter(d=>{return d.trim() !== ''})
                                    , priex = keys[0]
                                    , key = success.substr(priex.length)
                                    , $data = this[priex] || {};
                                key = (key = key.substr(0,1) === '.' ? key.substr(1) : key) !== '' ? key+'.' : key;
                                let $obj = {};
                                $obj[key+'is_collect'] = data.is_collect;
                                $obj[key+'collect_count'] = data.collect_count;
                                this.$tool.setDataValue($obj , $data)
                                this[priex] = {...$data};
                            }
                        });
                    }
                }
                if(commandKey === 'feedback'){
                    methodFunc = (title,type , url , params)=>{
                        type = type || '课程';
                        if(!tool.isUrl(url)){
                            url = getRouteTargetUrl(getRouteToUrl(url instanceof Object ? url : (params || {}),url instanceof Object ? (typeof params === 'string' ? params : '') : url));
                            if(!tool.isUrl(url)) url = window.location.origin + window.location.pathname + url;
                        }
                        this.$confirm('<div style="padding: 20px 0;"><div style="display: inline-block;width: 100px;">反馈位置</div>'+$('<span>'+title+'</span>').text()+'</div><div><div style="display: inline-block;width: 100px;">反馈内容</div></div>', type+'反馈', {
                            dangerouslyUseHTMLString:true,
                            showInput: true,
                            inputPlaceholder: '请输入反馈内容',
                            inputType: 'textarea',
                            customClass:'feedback-box',
                            beforeClose:(action, instance)=>{
                                if(action === 'confirm'){
                                    let value = instance.inputValue;
                                    if(!value && value !== 0){
                                        return this.$message.error("请输入反馈内容");
                                    }
                                    this.$http.post("api/v1_0_0.feedback/submit",{
                                        content:value,
                                        type,
                                        title,
                                        url:url||window.location.href
                                    }).then((res) => {
                                        this.$message.success("反馈成功");
                                        instance.close()
                                    })
                                    return false;
                                }else{
                                    instance.close()
                                }
                            }
                        });
                    }
                }
                if(commandKey === 'confirm'){
                    methodFunc = (...vars)=>{this.$handleConfirm(...vars)}
                }
                if(commandKey === 'request'){
                    methodFunc = (url , data , success)=>{
                        return this.$http.post(url , {...(data || {})}).then(({msg})=>{
                            this.$handleMessage(msg || "操作成功！" , 'success' , success)
                        }).catch(()=>{})
                    }
                }
                if(commandKey === 'message'){
                    methodFunc = (...vars)=>{this.$handleMessage(...vars)}
                }
                if(commandKey === 'route'){
                    methodFunc = this.$handleRoute
                }
                if(commandKey === 'routeRefresh'){
                    methodFunc = (cb)=>{
                        let refreshMethod = this.refreshMethod || this.$attrs.refreshMethod
                        if(typeof refreshMethod === 'function'){
                            refreshMethod();
                        }
                        if(cb && typeof cb === 'function'){
                            cb();
                        }else if(cb !== false){
                            this.routeUpdate();
                        }
                    }
                }
                if(methodFunc){
                    command = '';
                    result = methodFunc(...vars);
                }
            }
            if(result && result instanceof Promise){
                result.finally(()=>{
                    this.loadingData['command'+commandKey] = false;
                });
            }else{
                this.loadingData['command'+commandKey] = false;
            }
        },
        $getParentRef(ref){
            var $ref = this.$refs[ref] || null;
            if($ref){
                return $ref;
            }
            var getParent = ($parent)=>{
                var $ref = $parent.$refs[ref] || null;
                if($ref){
                    return $ref;
                }
                return $parent.$parent ? getParent($parent.$parent) : null;
            }
            return this.$parent ? getParent(this.$parent) : null;
        },
        $handleRoute(query , path , target,replace=null){
            routeNavigate(query , path , target , replace);
        },
        $handleMessage(msg  , type, config){
            if(config && typeof config === 'string'){
                let [func,...vars] = config.split(':')
                if(typeof this[func] === 'function'){
                    config = ()=>{
                        this[func](...vars.map(d=>{return d?(d === 'false' ? false : d === 'true' ? true :d):undefined}));
                    }
                }
            }
            let { onClose, ...options} = Object.assign({
                message : typeof msg === 'string' ? msg : null,
                type : typeof type === 'string' && ['success','warning','info','error'].indexOf(type) > -1  ? type : 'info',
            },typeof msg === 'object' ? msg : {} ,
                typeof type === 'object' ? type : (type && type instanceof Function ? {onClose:type} : {}) ,
                config && config instanceof Function ? {onClose:config} : (typeof config === 'string' && ['success','warning','info','error'].indexOf(config) > -1 ? {type:config} : {...(config || {})})
            );
            options.duration = options.duration || options.duration === 0 ? options.duration : 2000;
            options.onClose = ()=>{
                if(onClose && onClose instanceof Function){
                    onClose();
                }
            };
            return this.$message(options);
        },
        $handleConfirm(msg , _url , _data , _success , config){
            let {url , data , success , successMessage , message , callback ,...options} = Object.assign({
                message : typeof msg === 'string' ? msg : null,
                url  : typeof _url === 'string' ? _url : null,
                data  :  typeof _url === 'string' ? (_data || {}) : {},
                success  : typeof _url === 'string' ? _success : null,
                callback  : typeof _url === 'function' ? _url : ( typeof _data === 'function' ? _data : (typeof _url === 'string' ? null : _success)),
                successMessage  : null,
                title:'提示',
            } , ...[...arguments].filter(d=>!(d instanceof Array) && d instanceof Object));
            options.callback = async (action, instance)=>{
               if(url && action === 'confirm'){
                    instance.visible = true;
                    await this.$http.post(url , {...(data || {})}).then(({msg,data})=>{
                        instance.close()
                        this.$handleMessage(successMessage || msg || "操作成功！" , 'success' , ()=>{success(data)})
                    }).catch(error=>{
                        instance.close()
                        typeof callback === 'function' && callback('fail' , instance , error);
                    })
                }else if(callback && callback instanceof Function){
                    callback(action, instance);
                }
            }
            return this.$confirm(message , options);
        },
    }
}
function getConfig(name , _default , systemCofing){
    _default = _default === undefined ? null : _default;
    var names = typeof name === 'string' ? name.trim().split('.') : (name instanceof Array ? name : []);
    var val = systemCofing || {};
    var is_end = false;
    var l = names.length;
    for (let i = 0; i < l; i++) {
        let name = names[i].trim();
        if(name === '*' || name === ''){
            is_end = true;
        }else {
            if(typeof val !== 'object'){
                val = _default;
                is_end = true;
            }else{
                for (const j in val) {
                    if(val[j].key.trim() === name){
                        if(l - 1 === i) {
                            val = (val[j] || {}).value
                            is_end = true;
                        }else{
                            val = (val[j] || {}).children || [];
                        }
                        break;
                    }
                }
            }
        }
        if(is_end){
            break;
        }
    }
    if(!is_end) return _default;
    return val === undefined ? _default : val;
}
var routerViewCache = {};
async function routerViewCacheCallback(_this, cb , endCb){
    if(routerViewCache[_this._uid]){
        return ;
    }
    routerViewCache[_this._uid] = 1;
    cb && await cb(_this);

    delete routerViewCache[_this._uid];
    if(Object.keys(routerViewCache).length === 0){
        endCb && endCb();
    }
}
async function routeChange(_this , cb , endCb){
    await routerViewCacheCallback(_this , async function(_this){
        let matcheds = (_this.$route || {}).matched || [];
        if(matcheds.length === 0){
            return ;
        }
        var $parentRouterViewDepth = (_this)=>{
            var $routerViewDepth = !(_this.routerViewDepth >= 0) ? -1 : _this.routerViewDepth;
            if($routerViewDepth === -1 && _this.$parent){
                return $parentRouterViewDepth(_this.$parent);
            }
            return $routerViewDepth;
        }
        var routerViewDepth = _this.routerViewDepth;
        if(routerViewDepth === -1) {
            for (let i = 0; i < matcheds.length; i++) {
				if(routeElementViewDepth === 0 && (oldMatched[i] || {}).path !== matcheds[i].path){
					routeElementViewDepth = i;
				}
                for (const instancesKey in matcheds[i].instances) {
                    if ((matcheds[i].instances[instancesKey] || {})._uid === _this._uid) {
                        routerViewDepth = i;
                    }
                    if (routerViewDepth > -1) {
                        break;
                    }
                }
                if (routerViewDepth > -1) {
                    break;
                }
            }
        }
        if(routerViewDepth === -1){
            routerViewDepth = $parentRouterViewDepth(_this);
        }
        routerViewDepth = routerViewDepth === -1 ? 0 : routerViewDepth
        _this.routerViewDepth = routerViewDepth
        var currentRoute = {...matcheds[routerViewDepth]};
        currentRoute.routerViewDepth = routerViewDepth;
        let pathname = ((matcheds[routerViewDepth+1] || {}).path || (currentRoute.path + '/')).replace(currentRoute.path+'/' , '');
        currentRoute.pathname = pathname.split('/')[0] || ''
        let isChange = routeChangeType >= 0 || !(_this.currentRoute.routerViewDepth >= 0) || (_this.currentRoute.routerViewDepth >= 0 && (
            _this.currentRoute.routerViewDepth !== currentRoute.routerViewDepth
            || _this.currentRoute.pathname !== currentRoute.pathname
            || _this.currentRoute.name !== currentRoute.name
            || _this.currentRoute.path !== currentRoute.path
        ));
        _this.currentRoute = {...currentRoute}
        if(isChange) {
            _this.routeUpdate(_this.$route , routeChangeType);
        }
        _this.routeChange(currentRoute , routeChangeType);

        cb && cb(currentRoute);
    } , endCb);
}
export default comMixin;
import request from "@/Utils/api";

export default {
    state:{
        menuLoading:true,
        menuList:[
            {
                "name": "首页",
                "icon": "",
                "path": "/"
            },
            {
                "name": "课程",
                "icon": "",
                "path": "/course",
                "children": [
                    {
                        "name": "入门级",
                        "path": "/course/moreCourse?from_id=1",
                        "imgs": [],
                        "icon": ""
                    },
                    {
                        "name": "提高级",
                        "path": "/course/moreCourse?from_id=10",
                        "imgs": [],
                        "icon": ""
                    },
                    {
                        "name": "NOI级",
                        "path": "/course/moreCourse?from_id=9",
                        "imgs": [],
                        "icon": ""
                    }
                ]
            },
        ]
    },
    mutatons:{
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },
    },
    actions:{
        getMenuList({state,commit}){
            state.menuLoading = true
            return new Promise((resolve, reject) => {
                request.get('/xapi/config/header' , {cache:60}).then(({data})=>{
                    state.menuList = data.navList || []
                    return resolve(data.navList || []);
                }).catch(e=>{
                    return reject(e);
                }).finally(()=>{
                    state.menuLoading = false
                })
            });
        },
    },
    getters:{}
}
import Vue from 'vue'
import comTable from "../el/comTable";
import FormCreate from "../FormCreate";
import dividerBox from "../el/dividerBox";
import diyFormItem from "../el/diyFormItem";
import elPdf from "../el/elPdf";
function sleep(time) {
    var timeStamp = new Date().getTime();
    var endTime = timeStamp + time;
    while (true) {
        if (new Date().getTime() > endTime) {
            return;
        }
    }
}
var nativeOnFunc = function(component , keys = null , _this ,_ref){
    keys = keys || ['class','style','attrs','props','domProps','on','nativeOn','directives','slot','key','ref','scopedSlots'];
    var $com = {};
    let ref = _ref || component.ref;
    for (let i = 0; i < keys.length; i++) {
        if(component[keys[i]] !== undefined){
            if(['on','nativeOn'].indexOf(keys[i]) > -1 && typeof component[keys[i]] === 'object' && !(component[keys[i]] instanceof Array)){
                if(!ref){
                    // $com.ref = ref = 'comRef-'+(Math.random()*10).toString()
                }
                let funObj = {};
                for (const funObjKey in component[keys[i]]) {
                    funObj[funObjKey] = function(funcString , key , _this , ref){
                        let func = null;
                        if(typeof funcString !== 'function'){
                            try{
                                func = eval("(false || "+funcString+")");
                            }catch (e) {func = null;}
                            if(typeof func !== 'function'){
                                func = null;
                            }
                        }else{
                            func = funcString;
                        }
                        return function(...vars){
                            if(func){
                                return func.call(_this , ...vars);
                            }
                        }
                    }(component[keys[i]][funObjKey] , keys[i] , _this , ref)
                }
                $com[keys[i]] = funObj;
            }else{
                $com[keys[i]] = component[keys[i]];
            }
        }
    }
    return $com;
}
var toEachMap = function(comp , h , _this , ref){
    let components = [];
    if(comp instanceof Array){
        components = [...comp];
    }else if(comp instanceof Object){
        components = [comp];
    }
    return components.map((component,index)=>{return toEach(component , h , _this, index , ref);}).filter(d=>d)
}
var toEach = function(component , h , _this , callback , ref){
    if(component === null || component === false) return ;
    if(_this.componentMethod && _this.componentMethod instanceof Function){
        var ret = _this.componentMethod(component , _this);
        if(ret && ret instanceof Object && ret.name){
            component = {...ret};
        }
    }
    if(!component.name) return ;
    for (const cKey in component) {
        if(component[cKey] instanceof Function && ['hidden','show'].indexOf(cKey) > -1){
            component[cKey] = component[cKey].call(_this.$parent , component , _this.$parent);
        }else if(!(component[cKey] instanceof Function) && ['callback'].indexOf(cKey) > -1){
            try{
                var cb = eval('('+component[cKey]+')');
                if(typeof cb !== 'function'){
                    component[cKey] = null;
                }else{
                    component[cKey] = function(component,$parent){
                        return cb.call(_this , component,$parent);
                    }
                }
            }catch (e){ component[cKey] = null;}
        }
    }
    if(component.hidden === true || component.show === false){
        return ;
    }
    component.callback && component.callback(component,_this.$parent);
    var $vnode = elseScopedSlots(component , h , _this , ref) , children = [];
    if($vnode === null && component.hidden !== true && (component || {}).name){
        if(component.condition && _this.conditionFun){
            let $val = _this.conditionVal ? _this.conditionVal() : undefined;
            if(_this.conditionFun(component.condition , $val) === false){
                return undefined;
            }
        }
        component.props = component.props || {};
        component.attrs = component.attrs || {};
        var $com = nativeOnFunc(component , null , _this ,ref);
        if(component[_this.children || 'components']){
            children = toEachMap(component[_this.children || 'components'] , h , _this) || [];
        }
        $com.scopedSlots = scopedSlots(component.slots , h , _this , component.props ,$com.ref || ref);
        if(typeof callback === 'function') callback($com);
        $vnode = h(component.name , $com , children);
        if($vnode && $vnode.data){
            $vnode.data.attrs = {...component.attrs,...component.props};
            $vnode.data.props = {...component.attrs,...component.props};
        }
    }
    if($vnode){
        for (const attrKey in (($vnode.data || {}).attrs || {})) {
            if(component.props[attrKey] !== undefined)$vnode.data.attrs[attrKey] = component.props[attrKey];
        }
        for (const attrKey in (($vnode.componentOptions || {}).propsData || {})) {
            if(component.props[attrKey] !== undefined)$vnode.componentOptions.propsData[attrKey] = component.props[attrKey];
        }
    }
    return $vnode
}
var elseScopedSlots = function(component , h , _this , ref){
    var $vnode = null , children = [];
    if(component.hidden === true){
        return $vnode;
    }
    if((component || {}).name && _this.elseComponents.indexOf(component.name) > -1 && _this.$scopedSlots['else-component']){
        if(component.condition && _this.conditionFun){
            let $val = _this.conditionVal ? _this.conditionVal() : undefined;
            if(_this.conditionFun(component.condition , $val) === false){
                return undefined;
            }
            if(component.props.prefixProp){
                // console.log("elseScopedSlots  $vnode" , {...component});
            }
        }
        component.scopedSlots = component.scopedSlots || {};
        component.props = component.props || {};
        if(component.components){
            children = toEachMap(component.components , h , _this,component.ref || ref) || [];
        }
        var $scopedSlots = scopedSlots(component.slots , h , _this , component.props,component.ref || ref);
        if(children.length > 0)$scopedSlots.default = ()=>{
            return children;
        };
        $vnode = (_this.$scopedSlots['else-component']({...component}) || [])[0] || null;
        if($vnode && ($scopedSlots && Object.keys($scopedSlots).length > 0) && $vnode.data){
            $vnode.data.scopedSlots = {
                ...$scopedSlots,
                _normalized:{...$scopedSlots}
            }
        }
        if($vnode && (component.style || component.class)){
            $vnode.data.style = component.style || {};
            $vnode.data.class = component.class || {};
        }
    }
    return $vnode
}
var scopedSlots = function($slots , h , _this , props , ref){
    let scopedSlots = {};
    let createHtml = (component , componentIndex)=>{
        var $ref = ref ? (_this.$refs[ref] || _this) : _this;
        return toEach(component , h , $ref);
    }
    for (const $slotsKey in ($slots || {})) {
        let slots = ($slots[$slotsKey] === true?$slotsKey : $slots[$slotsKey]);
        if(slots === false){
            continue;
        }
        let type = typeof slots;
        if(type === 'string'){
            if(_this.$scopedSlots[slots]){
                scopedSlots[$slotsKey] = (VNode)=>{
                    VNode.$props = props;
                    return _this.$scopedSlots[slots](VNode);
                };
            }else{
                scopedSlots[$slotsKey] = (VNode)=>{//VNode | Array<VNode>
                    return h('span', {domProps: {innerHTML: slots}} ) || VNode
                }
            }
        }else if(type === 'function'){
            scopedSlots[$slotsKey] = (VNode)=>{
                VNode.$props = props;
                return slots(VNode , createHtml ,h);
            };
        }else if(type === 'object' && slots instanceof Array){
            if(slots.length === 1 && slots[0] instanceof Function){
                scopedSlots[$slotsKey] = (VNode)=>{
                    VNode.$props = props;
                    return slots[0](VNode , createHtml ,h);
                };
            }else{
                scopedSlots[$slotsKey] = (VNode)=>{//VNode | Array<VNode>
                    var $ref = ref ? (_this.$refs[ref] || _this) : _this;
                    return toEachMap(slots , h , $ref , ref);
                }
            }
        }else if(type === 'object' && slots instanceof Object){
            scopedSlots[$slotsKey] = (VNode)=>{//VNode | Array<VNode>
                var $ref = ref ? (_this.$refs[ref] || _this) : _this;
                return toEach(slots , h , $ref) || VNode
            }
        }
    }
    return scopedSlots;
}
Vue.component('com-component', {
    render: function (createElement) {
        let props = Object.assign({} , this.$attrs , this.$props);
        var $vnode = toEach({...props}, createElement , this , ($com)=>{
            $com.props = props.props || {};
            $com.scopedSlots = scopedSlots({...this.$scopedSlots,...this.slots} , createElement , this , $com.props);
        });
        return $vnode;
    },
    model: {
        prop: 'value',
        event: 'changeValue'
    },
    data(){
        return {
            loading:false,
            acValue:this.value,
        };
    },
    components:{comTable,FormCreate,dividerBox,diyFormItem,elPdf},
    props: {
        value:{ default(){return null; }, },
        components:{
            type:Array,
            default(){return []; },
        },
        children:String,
        hidden:false,
        show:true,
        slots:{
            type:Object,
            default(){return {}; },
        },
        elseComponents:{
            type:Array,
            default(){return []; },
        },
        name:{
            type:String,
            required:true,
        },
        componentMethod:{
            type:Function,
            default(){return null; },
        },
        conditionFun:{
            type:Function,
            default(){return null; },
        },
        conditionVal:{
            type:Function,
            default(){return null; },
        },
    },
    watch:{
        value(v){
            this.acValue = v;
        },
    },
    methods:{
        initEvents(events){
            let $events = events || {};
            let _events = {};
            for (const $eventsKey in $events) {
                if($events[$eventsKey] instanceof Function){
                    _events[$eventsKey] = $events[$eventsKey];
                }else if((($events[$eventsKey] || [])[0]) instanceof Function){
                    _events[$eventsKey] = $events[$eventsKey][0];
                }
            }
            return _events;
        },
    }
})
/**
 * {
  // 和`v-bind:class`一样的 API
  'class': {
    foo: true,
    bar: false
  },
  // 和`v-bind:style`一样的 API
  style: {
    color: 'red',
    fontSize: '14px'
  },
  // 正常的 HTML 特性
  attrs: {
    id: 'foo'
  },
  // 组件 props
  props: {
    myProp: 'bar'
  },
  // DOM 属性
  domProps: {
    innerHTML: 'baz'
  },
  // 事件监听器基于 `on`
  // 所以不再支持如 `v-on:keyup.enter` 修饰器
  // 需要手动匹配 keyCode。
  on: {
    click: this.clickHandler
  },
  // 仅对于组件，用于监听原生事件，而不是组件内部使用
  // `vm.$emit` 触发的事件。
  nativeOn: {
    click: this.nativeClickHandler
  },
  // 自定义指令。注意，你无法对 `binding` 中的 `oldValue`
  // 赋值，因为 Vue 已经自动为你进行了同步。
  directives: [
    {
      name: 'my-custom-directive',
      value: '2',
      expression: '1 + 1',
      arg: 'foo',
      modifiers: {
        bar: true
      }
    }
  ],
  // Scoped slots in the form of
  // { name: props => VNode | Array<VNode> }
  scopedSlots: {
    default: props => createElement('span', props.text)
  },
  // 如果组件是其他组件的子组件，需为插槽指定名称
  slot: 'name-of-slot',
  // 其他特殊顶层属性
  key: 'myKey',
  ref: 'myRef'
}
 */

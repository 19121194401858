import Vue from 'vue'

import $packageConfig from '../package.json'
$packageConfig.version = '2.0.0';
Vue.prototype.$packageConfig = $packageConfig

import App from './App.vue'
import './Utils/api'
import socket from './Utils/socket'
import router from './router'
import store from './store'
import tool from './Utils/tool'
import ElementUI from 'element-ui'
import scroll from 'vue-seamless-scroll'
import './assets/css/public.scss'
import '@/components/CodeEditor/codefence.css';
import './assets/css/public.css'
window.UEDITOR_HOME_URL = '/public/UEditor/';
Vue.use(ElementUI)
// 富文本

import VueClipboard  from 'vue-clipboard2'
Vue.use( VueClipboard )

Vue.use(scroll)
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(VueVideoPlayer);

import storage from 'store'
Vue.prototype.$storage = storage
Vue.prototype.$tool = tool
Vue.prototype.$socket = socket
import $ from 'jquery'
Vue.prototype.$jquery = $

//面包屑
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.vue"
Vue.component('Breadcrumb', Breadcrumb)

//加载自定义组件
const comFiles = require.context('@/components/com/js/', true, /\.js$/)
comFiles.keys().forEach(key => {
  comFiles(key);
})
import vshare from 'vshare'
Vue.use(vshare)
import exception from "@/components/exception";
Vue.component('exception', exception)
import diyDialog from "@/components/com/diyDialog";
Vue.component('diy-dialog', diyDialog)
import diyCard from "@/components/com/el/diyCard";
Vue.component('diy-card', diyCard)
import FormCreate from "@/components/com/FormCreate";
Vue.component('form-create', FormCreate)
import comTable from "@/components/com/el/comTable";
Vue.component('com-table', comTable)
import diyFormItem from "@/components/com/el/diyFormItem";
Vue.component('diy-form-item', diyFormItem)
Vue.component('form-item', diyFormItem)
import elRouteTabs from "@/components/com/el/elRouteTabs";
Vue.component('el-route-tabs', elRouteTabs)

import comMixin from "@/Utils/comMixin";
Vue.mixin(comMixin)

//------------------------------------------------------------------
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

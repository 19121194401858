import http from "@/Utils/api";
import tool from "@/Utils/tool";
import store from "../index";
export default {
    state:{
        menuList:[]
    },
    mutatons:{
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },
    },
    actions:{
        getMenuList({state}){
            var currentVersion = (location.$packageConfig || {}).version
            state.menuLoading = true
            return new Promise((resolve, reject) => {
                http.request('/xapi/config/header').then(({data})=>{
                    state.menuLoading = false
                    state.menuList = data.navList || []
                    localStorage.setItem("menuList", tool.encrypt(JSON.stringify(state.menuList),'menuList'))
                    store.commit("SET" , {key:'menuList',value:state.menuList})
                    return resolve(data.navList || []);
                }).catch(()=>{
                    let data =  tool.decrypt(localStorage.getItem("menuList"),'menuList');
                    if(data && (data = JSON.parse(data))){
                        store.commit("SET" , {key:'menuList',value:data})
                    }else{
                        import('./web/menuList.'+currentVersion+'.json').then((data)=>{
                            state.menuList = data.navList || []
                            store.commit("SET" , {key:'menuList',value:state.menuList})
                            return resolve(data.navList || []);
                        }).catch(e=>{
                            return reject(e);
                        }).finally(()=>{
                            state.menuLoading = false
                        })
                    }
                })
            });
        },
    },
    getters:{}
}
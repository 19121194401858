
export default  {
  timeIndex:[],
  intervalIndex:[],
  setTimeout(cb , timeout){
    var index = null , _this = this;
    if(timeout && typeof cb === 'function'){
      index = setTimeout(function(){
        var ret = null;
        try{
          ret = cb();
        }catch (e) {}
        if(ret === false){
          _this.clearTimeout(index);
        }
      } , timeout);
      this.timeIndex.push(index);
    }
    return index;
  },
  setInterval(cb , timeout){
    var index = null , _this = this;
    if(timeout && typeof cb === 'function'){
      index = setInterval(function(){
        var ret = null;
        try{
          ret = cb();
        }catch (e) {
          console.log(" ----- setInterval" , e);
        }
        if(ret === false){
          _this.clearInterval(index);
        }
      } , timeout);
      this.intervalIndex.push(index);
    }
    return index;
  },
  clearInterval(index = null){
    if(index === undefined || index === null){
      for (let i = 0; i < this.intervalIndex; i++) {
        clearInterval(this.intervalIndex[i])
      }
      this.intervalIndex = [];
    } else if((index = this.intervalIndex.indexOf(index)) > -1){
      clearInterval(this.intervalIndex[index]);
      this.intervalIndex.splice(index , 1)
    }
  },
  clearTimeout(index = null){
    if(index === undefined || index === null){
      for (let i = 0; i < this.timeIndex; i++) {
        clearTimeout(this.timeIndex[i])
      }
      this.timeIndex = [];
    } else if((index = this.timeIndex.indexOf(index)) > -1){
      clearTimeout(this.timeIndex[index]);
      this.timeIndex.splice(index , 1)
    }
  },
}

<template>
  <el-drawer
      :visible.sync="isVisibled"
      v-bind="$attrs"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      v-if="dialogType === 'drawer' && (destroyOnClose === false || (destroyOnClose !== false && isVisibled === true))"
      ref="dialog"
      :before-close="beforeCloseFunction"
      >
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <slot>

    </slot>
  </el-drawer>
  <el-dialog
      :visible.sync="isVisibled"
      v-bind="$attrs"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      v-else-if="destroyOnClose === false || (destroyOnClose !== false && isVisibled === true)"
      ref="dialog"
      :destroy-on-close='true'
      :before-close="beforeCloseFunction"
	  @opened="$emit('opened', ...arguments)"
	  @open="$emit('open', ...arguments)"
	  @close="$emit('close', ...arguments)"
	  @closed="$emit('closed', ...arguments)"
  >
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <slot style="text-align: left;"> </slot>
    <span slot="footer" class="dialog-footer" v-if="$attrs.showFooter !== false">
      <slot name="footer">
        <el-button @click="confirm('cancel')">取 消</el-button>
        <el-button type="primary" @click="confirm('confirm')">确 定</el-button>
      </slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "diyDialog",
  props:{
    visible:{
      type:Boolean,
      default(){return false; },
    },
    dialogType:{
      type:String,
      default(){return ''; },
    },
    modalAppendToBody:{
      type:Boolean,
      default(){return true; },
    },
    appendToBody:{
      type:Boolean,
      default(){return true; },
    },
    beforeClose:{
      type:Function,
      default(){return null; },
    },
    bodyStyle:{
      type:[Object,String],
      default(){return null; },
    },
    destroyOnClose:{
      type:Boolean,
      default(){return true; },
    },
  },
  data(){
    return {
      isVisibled:this.visible
    };
  },
  watch:{
    isVisibled(v){
      this.$emit('update:visible',v);
      if(v){
        this.$nextTick(()=>{
          var body = this.$el.querySelector('.el-dialog__body')
          for (const bodyKey in this.bodyStyle) {
            body.style[bodyKey] = this.bodyStyle[bodyKey];
          }
        })
      }
    },
  },
  methods:{
    visibled(visible = true){
      let _visible = visible !== false;
      if(_visible === false && this.isVisibled === true){
        this.beforeCloseFunction(()=>{
          this.isVisibled = false;
        });
      }else{
        this.isVisibled = _visible;
      }
    },
    beforeCloseFunction(cb , type){
      if(this.beforeClose){
        var result = this.beforeClose(cb , type || 'close');
        if(result instanceof Promise){
          result.then(cb)
          return false;
        }else if(result === false){
          return false;
        }
      }
      cb();
    },
    confirm(type){
      this.beforeCloseFunction(()=>{
        this.$emit(type || 'close',type || 'close');
        this.isVisibled = false;
      } ,type || 'close');
    },
  }
}
</script>

<style scoped lang="less">
::v-deep{
  .el-dialog--center .el-dialog__body{
    //text-align: inherit;
  }
  .el-dialog__body :not(.cell) .el-form-item:last-of-type{
    //margin-bottom: 0;
  }
}
</style>
import tool from "@/Utils/tool";
import store from "@/store";
import regularTime from "@/Utils/regularTime";
tool.locationInit()
const WebSocket = window.WebSocket || window.MozWebSocket;
function initSocket(_h , o){
    if(_h && _h instanceof Object){
        o = {..._h,...(o || {})};
    }else{//udp://0.0.0.0:230046
        o = {socket:_h , ...(o || {})};
    }
    var index , index1;
    let {socket,host,port,protocol , path , ..._options} = {...o , ...(store.state.socketUrl && store.state.socketUrl instanceof Object ? store.state.socketUrl : {})};
    if(!(host || port || protocol)){
        socket = socket || store.state.socketUrl || null
        if(socket && typeof socket === 'string'){
            if((index = socket.indexOf('://')) > -1){
                protocol = socket.substr(0 , index) || '';
                host = socket.substr(index + 3 , (index1 = socket.lastIndexOf(':')) - index - 3) || '';
                port = socket.substr(index1 + 1) || '';
                path = path.substr(index1 + 1 + port.length);
            }
        }
    }
    protocol = ['wss','ws','websocket'].indexOf(protocol = protocol || 'ws') > -1 ? (window.location.protocol === 'https:' ? 'wss' : 'ws') : protocol
    host = host && host !== '0.0.0.0' ? host : (!window.location.hostname || window.location.hostname === 'localhost' ? '127.0.0.1' : window.location.hostname)
    port = parseInt(port === null || port === undefined ? 9485 : port);
    path = path || '/'
    socket = protocol+"://" +host+(port ? ":"+port : '')+path;
    return {..._options,socket,protocol,host,port , path};
}
var sockets = {};
function sleep(time){
    var timeStamp = new Date().getTime();
    var endTime = timeStamp + time;
    while(true){
        if (new Date().getTime() > endTime){
            return;
        }
    }
}
const socketInstance = {
    options:{},
    status:null,
    socket:null,
    connection:null,
    listens:{},
    ...regularTime,
    send(msg){
        return this.connect(null , null , (_this)=>{
            let t = typeof msg;
            if(_this.options.sendBefore && _this.options.sendBefore instanceof Function){
                msg = _this.options.sendBefore(msg);
            }else if(t === 'string' && msg.indexOf('/') > -1){
                let auth_token = localStorage.getItem("auth_token");
                if(auth_token){
                    msg = msg+(msg.indexOf('?') === -1 ? '?' : '&')+'auth_token='+auth_token
                }
            }else if(t === 'object'){
                msg = JSON.stringify(msg);
            }else{
                return false;
            }
            try{
                return _this.connection ? _this.connection.send(msg) : false;
            }catch (e) {
                return false;
            }
        });
    },
    isOpen(){
        return (this.connection || {}).readyState;
    },
    connect(_h , options , callback){
        // console.log(_h,"_h_h_h_h")
        // console.log(options,"optionsoptionsoptions")
        // console.log(callback,"callbackcallbackcallback")
        return new Promise((resolve, reject) => {
            if(!_h && options){
                _h = _h || this.socket
                options = options || this.options
            }
            let {socket,host,port,protocol , ..._options} = initSocket(_h , options);
            if(!socket) return reject(new Error('socket not set'));
            if(sockets[socket] === null){
                while(true){
                    if (sockets[socket] !== null){
                        return;
                    }
                }
            }
            sockets[socket] = sockets[socket] || null;
            if(sockets[socket] === null || ((sockets[socket] || {}).status || 'open') !== 'open'){
                var _this = sockets[socket] || {...socketInstance};
                var connection = new WebSocket(socket);
                _this.socket = socket;
                _this.connection =connection;
                _this.options = _options;
                var timeout = (_this.options.timeout || 10) * 1000;
                var checkIndex = 0;
                //连接成功时触发
                connection.onopen = function(event) {
                    _this.status = 'open'
                    _this.relisten();
                    checkIndex = _this.setInterval(function(){
                        if(_this.status === 'open'){
                            connection.send('{"ping":1}');
                        }
                    },timeout);
                    try { _this.options.onConnect && _this.options.onConnect(_this , event); }catch (e) {}
                };
                connection.onmessage = function(event) {
                    try { _this.options.onMessage && _this.options.onMessage(_this , event); }catch (e) { }
                    var data;
                    try{ data = JSON.parse(event.data) }catch (e){ data = null; }
                    if(data && data instanceof Object){
                        try{
                            if((data || {}).path && (data || {}).data !== undefined){
                                var path = data.path + '' , _data = data.data;
                                for (const dataKey in (_this.listens[path] || {})) {
                                    let {data , callback , isOne} = _this.listens[path][dataKey];
                                    try{ callback && callback.call(_this,{data:_data,request:data}); }catch (e) {}
                                    if(isOne){
                                        _this.delListen(path , data);
                                    }
                                }
                            }
                        }catch (e){}
                    }
                };
                connection.onerror = function(event){
                    // _this.status = 'error'
                    try { _this.options.onError && _this.options.onError(_this , ...arguments); }catch (e) { }
                };
                connection.onclose = function(event){
                    console.log(" --- connection close --- " , event);
                    if(_this.status && _this.status !== 'close'){
                        try { _this.options.onClose && _this.options.onClose(_this , ...arguments); }catch (e) {}
                    }
                    _this.status = 'close'
                    if(checkIndex){
                        _this.clearInterval(checkIndex);
                        checkIndex = 0;
                    }
                    _this.setTimeout(function(){_this.reconnect();}, timeout);
                };
                _this.connection = connection;
                sockets[socket] = _this;
            }
            if(callback && typeof callback === 'function'){
               var $ret = callback(sockets[socket]);
                resolve($ret);
                return $ret;
            }
            resolve(sockets[socket]);
            return sockets[socket];
        })
    },
    reconnect(){
        if(this.connection && this.status !== 'close'){
            this.connection.close();
        }
        return this.connect();
    },
    relisten(){
        for (const listenKey in this.listens) {
            for (const listenKeyKey in this.listens[listenKey]) {
                this.send(this.listens[listenKey][listenKeyKey].fullUrl);
            }
        }
        return this;
    },
    listen(url , data = {} , callback , isOne = false){
        return this.connect(null , null , (_this)=>{
            let {urlBase , fullUrl} = getFullUrl(url , data);
            _this.listens[urlBase] = _this.listens[urlBase] || {};
            let isInit = _this.listens[urlBase][fullUrl] === undefined;
            if(isInit){
                if(callback){
                    _this.listens[urlBase][fullUrl] = {fullUrl,data,callback , isOne:isOne === true};
                }
                _this.send(fullUrl);
            }
            return this;
        });
    },
    close(){
        this.connection && this.connection.close();
        this.connection = null;
        this.clearInterval()
        this.clearTimeout()
        return this;
    },
    delListen(url , data = {}){
        let {urlBase , fullUrl,split} = getFullUrl(url , data);
        if((this.listens[urlBase] || {})[fullUrl] !== undefined){
            delete this.listens[urlBase][fullUrl];
            this.send(fullUrl+split+'listen_cancel=1');
        }
        return this;
    },
}
function getFullUrl(url , data = {}){
    var urlBase = ((url = url || '').substr(0,1) !== '/' ? '/':'') + url , fullUrl  = urlBase , split = null;
    if(data instanceof Object && Object.keys(data).length > 0){
        fullUrl += (urlBase.indexOf('?') === -1 ? '?' : '&')+tool.build_query(data);
        split = '&'
    }else{
        split = urlBase.indexOf('?') === -1 ? '?' : '&';
    }
    return {urlBase,fullUrl,split}
}
window.onbeforeunload = function(){
    for (const socketsKey in sockets) {
        sockets[socketsKey].close();
    }
}
export default socketInstance;
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-box"},[(_vm.tabPosition === 'bottom')?_c('div',{staticClass:"tab-box-content",style:(_vm.bodyStyle)},[(_vm.loading === false)?_vm._t(_vm.currentTab.slot || 'default',null,null,_vm.currentTab):_vm._e()],2):_vm._e(),_c('div',{staticStyle:{"display":"flex"}},[(_vm.tabPosition === 'right')?_c('div',{style:({
      width:'calc(100% - '+(_vm.tabHeaderWidth||'15px')+')'
    })},[_c('div',{staticClass:"tab-box-content",style:(_vm.bodyStyle)},[(_vm.loading === false)?_vm._t(_vm.currentTab.slot || 'default',null,null,_vm.currentTab):_vm._e()],2)]):_vm._e(),_c('div',{style:(['top','bottom'].includes(_vm.tabPosition) ? {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex:1
        }:{
              minHeight:'100%'
        })},[(_vm.$scopedSlots['header-left'])?_c('div',{class:'el-tabs el-tabs--'+_vm.tabPosition+' el-tabs--'+_vm.type},[_c('div',{class:'el-tabs__header is-'+_vm.tabPosition},[_c('div',{staticClass:"flex-center",style:(['top','bottom'].includes(_vm.tabPosition) ? {
                paddingLeft:'15px',
                paddingRight:'15px',
                height:_vm.tabHeaderHeight
              }:{

              })},[_vm._t("header-left")],2)])]):_vm._e(),_c('el-tabs',{ref:"tabs",class:_vm.headerClass,staticStyle:{"flex":"1"},attrs:{"type":_vm.type,"closable":_vm.closable,"addable":_vm.addable,"tab-position":_vm.tabPosition,"stretch":_vm.stretch,"before-leave":_vm.beforeLeave},on:{"tab-click":function($event){return _vm.handleEmit('tab-click' , ...arguments)},"tab-remove":function($event){return _vm.handleEmit('tab-remove' , ...arguments)},"tab-add":function($event){return _vm.handleEmit('tab-add' , ...arguments)},"edit":function($event){return _vm.handleEmit('edit' , ...arguments)}},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.tabList),function(tab,index){return _c('el-tab-pane',{key:index,attrs:{"label":tab.label,"disabled":tab.disabled === true,"name":tab.name,"closable":tab.closable === true,"lazy":tab.lazy === true}},[_c('template',{slot:"label"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(tab.label)+" ")]},null,tab)],2)],2)}),1),(_vm.$scopedSlots['header-right'])?_c('div',{class:'el-tabs el-tabs--'+_vm.tabPosition+' el-tabs--'+_vm.type},[_c('div',{class:'el-tabs__header is-'+_vm.tabPosition},[_c('div',{staticClass:"flex-center",style:(['top','bottom'].includes(_vm.tabPosition) ? {
                paddingLeft:'15px',
                paddingRight:'15px',
                height:_vm.tabHeaderHeight
              }:{

              })},[_vm._t("header-right")],2)])]):_vm._e()],1),(_vm.tabPosition === 'left')?_c('div',{style:({
      width:'calc(100% - '+(_vm.tabHeaderWidth||'15px')+')'
    })},[_c('div',{staticClass:"tab-box-content",style:(_vm.bodyStyle)},[(_vm.loading === false)?_vm._t(_vm.currentTab.slot || 'default',null,null,_vm.currentTab):_vm._e()],2)]):_vm._e()]),(_vm.tabPosition === 'top')?_c('div',{staticClass:"tab-box-content",style:(_vm.bodyStyle)},[(_vm.loading === false)?_vm._t(_vm.currentTab.slot || 'default',null,null,_vm.currentTab):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
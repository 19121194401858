/**
 * axios配置
 */
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from "@/store"
import JsonBig from 'json-bigint'
import md5 from "js-md5";
import {Message , MessageBox } from 'element-ui';
import tool from "@/Utils/tool";

tool.locationInit();

// 定义一个缓存池用来缓存数据
let caches = {};
const EXPIRE_TIME = 60

axios.defaults.baseURL = location.baseURL;
axios.interceptors.request.use(
    function (config) {
        config = getConfig(config);
        config.cache = config.cache === undefined ? false : config.cache;
        var data = caches[config.cacheKey] || {};
        // 获取当前时间戳
        let expire_time = (new Date()).getTime();
        if ((config.cache === true && (data.data || data.loading === true) && data.time + config.cacheTime > expire_time)
            || (config.cache !== true && data.loading === true)
        ) {
            //利用axios的cancelToken来取消请求
            let source = axios.CancelToken.source()
            config.cancelToken = source.token;
            // source.cancel(config);
        }else if(caches[config.cacheKey] === undefined){
            caches[config.cacheKey] = {
                url: config.url,
                time: expire_time,
                loading: true,
                config: config,
                data: undefined
            }
        }else if(caches[config.cacheKey]){
            caches[config.cacheKey].loading = true;
            caches[config.cacheKey].time = expire_time;
            caches[config.cacheKey].config = config;
        }
        return config
    },
    error => {
        console.log("axios.interceptors.request error" , error);
        Promise.reject(error)
    }
)

// respone 拦截器
axios.interceptors.response.use(
    response => {
        let config = response.config || {};
        if(config.cacheKey){
            if(typeof response.data === "object" && ( response.data.code = parseInt(response.data.code || 0)) === 200){
                if(config.cacheKey && caches[config.cacheKey] !== undefined){
                    caches[config.cacheKey].data = response.data;
                }
                if(config.notNeed){
                    if(!config.isAuth){
                        store.state.LoginRequests.push(response.config.url);
                    }else{
                        store.state.loginCheckTime = (new Date()).getTime();
                    }

                }
            }
            caches[config.cacheKey].data = response;
            caches[config.cacheKey].loading = false;
        }
        return new Promise(function(resolve , reject){
            success_response(response , config.url , resolve , reject , config);
        });
    },
    (error) => {
        // 请求拦截器中的source.cancel会将内容发送到error中
        // 通过axios.isCancel(error)来判断是否返回有数据 有的话直接返回给用户
        if (axios.isCancel(error) && error.message.cacheKey && caches[error.message.cacheKey]) {
            var cacheKey = error.message.cacheKey , data = caches[cacheKey] , config = data.config;
            return new Promise(function(resolve , reject){
                if(data.loading === true){
                    var index = setInterval(function (){
                        if((data = caches[cacheKey] || {}).loading === false){
                            clearInterval(index);
                            success_response(data.data , data.url , resolve , reject , config);
                        }
                    } ,100);
                }else{
                    success_response(data.data , data.url , resolve , reject , config);
                }
            });
        }else{
            let config  = error.config || (error.response || {}).config || null;
            error.code = (error.response || {}).status || 500;
            if (error && error.response) {
                switch (error.response.status) {
                    case 400:
                        error.message = '请求错误'
                        break
                    case 401:
                        break
                    case 403:
                        error.message = '登录过期，请重新登录'
                        break
                    case 404:
                        error.message = '网络请求不存在'
                        break
                    case 408:
                        break
                    case 500:
                        error.message = '请求错误'
                        break
                    case 501:
                        error.message = '服务未实现'
                        break
                    case 502:
                        error.message = '网关错误'
                        break
                    case 503:
                        error.message = '服务不可用'
                        break
                    case 504:
                        error.message = '网关超时'
                        break
                    case 505:
                        error.message = 'HTTP版本不受支持'
                        break
                    default:
                }
            } else {
                error.message = '网络出现问题，请稍后再试'
            }
            
			if((config || {}).cacheKey){
                caches[config.cacheKey].data =  error.response || {data:null};
                caches[config.cacheKey].loading = false
            }
            return new Promise(function(resolve , reject){
                return success_response(error.response || error , (config || {}).url , resolve , reject , config || {});
            });

        }
    }
)
Vue.use(VueAxios, axios);

function getConfig(config){
    if(!config)return config;
    if(config.url.substr(0,1)==='/'&&(config.baseURL||'').substr(-1))config.url = config.url.substr(1);
    let href = config.url.indexOf('://') > 0 ? config.url : (config.baseURL + (config.baseURL.substr(-1) !== '/' && config.url.substr(0,1) !== '/' ? '/':'') + config.url);
    let isCurrent = href.indexOf(location.baseURL) === 0;
    config.method = config.method || 'post';
    config.data = tool.$eval(config.data) || {};
    config.params = tool.$eval(config.params) || {};
    config.message = config.message === undefined ? true : config.message;
    config.notNeed = config.notNeed === undefined || config.notNeed === true || config.notNeed === 'true' ? true : config.notNeed;
    if(config.cache === true || (!isNaN(config.cache) && config.cache > 5)){
        var time = (config.cache === true ? EXPIRE_TIME : parseInt(config.cache)) * 1000;
        config.cache = true;
        config.cacheTime = time;
    }else{
        config.cache = false;
    }
    config.headers = {...config.headers || {}};
    config.timeout = config.timeout > 0 ? (config.timeout * 1000) : (config.timeout === 0 || config.timeout === false? 0 : 60000) ;
    if(isCurrent){
        config.transformResponse = function (res) {let result ;try{result = JsonBig.parse(res)}catch (e) {result = res;}return result;};
        config.withCredentials = false;
        if (config.notNeed) {
            const token = localStorage.getItem("auth_token")
            if(token){
                config.headers['Authorization'] = token;
                config.isAuth = true;
            }else{
                // 添加 token 参数
                const user_id = localStorage.getItem("user_id")
                const user_token = localStorage.getItem("user_token")
                if (user_id && user_token) {
                    if(config.method !== 'get' && config.data.user_id === undefined){
                        if(config.data instanceof FormData){
                            config.data.append('user_id',user_id)
                            config.data.append('user_token',user_token)
                        }else{
                            config.data.user_id = user_id
                            config.data.user_token = user_token
                        }
                    }else{
                        if(config.params instanceof FormData){
                            config.params.append('user_id',user_id)
                            config.params.append('user_token',user_token)
                        }else{
                            config.params.user_id = user_id
                            config.params.user_token = user_token
                        }
                    }
                    config.isAuth = true;
                }else{
                    config.isAuth = false;
                }
            }
        }
        let reurl = location.origin + location.pathname + ((location.hash || '').substr(2) || location.search);
        config.headers['X-Requested-with'] = reurl + (reurl.indexOf('?') === -1 ? '?' : '&')+'v='+(Vue.prototype.$packageConfig || {}).version;
    }
    var isFormData = config.data instanceof FormData || config.params instanceof FormData;
    config.cache = isFormData ? false : config.cache ;
    config.cacheKey = config.method + '::' +href + '?' + md5(JSON.stringify(config.params) + config.data+(isFormData ? Math.random():''));
    return config;
}
export function startLoading(){

}
export function endLoading(){

}
function success_response(res , url ,resolve ,reject , config){
    res = res || {};
    res.code = parseInt((res || {}).code || 0);
    config = config || {};
    var data = res.data , _res = {...res,data:null};
    let $config =  {config , ..._res};
    if(typeof data !== 'object' || $config.config.responseType === 'blob'){
        if(data === undefined){
            return reject ? reject({...$config,...res}) : Promise.reject({...$config,...res});
        }
        return resolve ? resolve({...$config,data}) : Promise.resolve({...$config,data});
    }
    if(config.baseURL === location.baseURL){
        data = data || {};
        res.code = data.code || res.code;
        if (res.code === 200) {
            return resolve ? resolve({...$config,...data}) : Promise.resolve({...$config,...data});
        }else {
            let message = res.msg || res.message || data.msg || data.message
            if (res.code === 202) {
                store.dispatch('clearLogin');
                store.state.LoginRequests.push(url);
                store.commit("SET" , {key:'lodinShow',value:true})
            }else if (res.code === 201) {
                data.message = message;
                if (store.state.requestNum < 1 || store.state.lodinShow === false) {
                    store.state.requestNum = 1;
                    store.dispatch('clearLogin');
                    store.commit("SET" , {key:'lodinShow',value:true})
                }
            }else if((data.success === false || data.success === undefined) && config.message === true && message){
                data.message = message;
                Message.error(message)
            }
        }
        return reject ? reject({...$config,...data}) : Promise.reject({...$config,...data});
    }
    return reject ? reject({...$config,...res}) : Promise.reject({...$config,...res});
}
export default axios
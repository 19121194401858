import Vue from 'vue'
import tool from "@/Utils/tool";
import {getRouter,getRouteTargetUrl} from "@/router";
var setAElem = function(value , el , type){
    let t = typeof value;
    if(el && el.tagName !== 'A' && (t === 'string' || t === 'object')){
        var a = null;
        for (let i = 0; i < el.childNodes.length; i++) {
            if(el.childNodes[i].tagName === 'A'){
                a = el.childNodes[i];
                break;
            }
        }
        var initStyle = function(a , el){
            a.style.display = 'inline-block';
            a.style.position = 'absolute';
            a.style.cursor = ['-','default'].indexOf(el.style.cursor||'-') === -1 ? el.style.cursor : (el.attributes.disabled ? 'not-allowed' : 'pointer')
            if(el.style.position === 'absolute'){
                if(el.style.top || el.style.top === 0)a.style.top = el.style.top;
                if(el.style.bottom || el.style.bottom === 0)a.style.bottom = el.style.bottom;
                if(el.style.right || el.style.right === 0)a.style.right = el.style.right;
                if(el.style.left || el.style.left === 0)a.style.left = el.style.left;
                a.style.width = el.clientWidth+'px';
                a.style.height = el.clientHeight+'px';
            }else{
                a.style.top = 0;
                a.style.bottom = 0;
                a.style.right = 0;
                a.style.left = 0;
            }
            return a;
        }
        if(a === null){
            if(el.style.position === undefined || !el.style.position){
                el.style.position = 'relative';
            }
            a = document.createElement('a');
            initStyle(a,el);
            el.appendChild(a);
            a.addEventListener('mousedown', (event) => {
                if(event.button === 0){
                    //鼠标左键
                    // console.log("您点击了鼠标左键!" , event);
                    if (event.preventDefault) {
                        event.preventDefault();
                    } else {
                        event.returnValue = true;
                    }
                }else if(event.button === 2){
                    //鼠标右键
                    // console.log("您点击了鼠标右键!" , event);
                }else if(event.button === 1){
                    //鼠标中键
                    // console.log("您点击了鼠标中键!" , event);
                }else if(event.button === 3){
                    //鼠标侧键3
                    // console.log("您点击了鼠标侧键(后退)" , event);
                }else if(event.button === 4){
                    //鼠标侧键4
                    // console.log("您点击了鼠标侧键(前进)" , event);
                }
            })
        }else{
            initStyle(a,el);
        }
        if(t === 'string' && tool.isUrl(value)){
            a.href = value;
        }else{
            var _router = getRouter(value);
            if(_router){
                a.href = getRouteTargetUrl(_router);
            }else{
                a.href = 'javascript:void(0);';
            }
        }
    }
}
Vue.directive("href", {
    // 当vue要对当前指令所在的元素进行解析的时候执行
    bind(el,binding){
        setAElem(binding.value , el , 'bind');
    },
    // 当当前指令所在的元素被插入到页面中的时候，会执行的函数
    inserted(el,binding){
        setAElem(binding.value , el , 'inserted');
    },
    // 当当前指令绑定的数据，发生改变的时候，会执行update函数
    update(el,binding){
        setAElem(binding.value , el , 'update');
    },
    // 当数据改变完成，元素更新完毕之后，会执行componentUpdated
    componentUpdated(el,binding){
        setAElem(binding.value , el , 'componentUpdated');
    },
    // 当指令被卸载的时候
    unbind(el,binding){

    }
})
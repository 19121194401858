import Vue from 'vue'
// 引入 highlight.js 代码高亮工具
var hljs = require('highlight.js/lib/common');
// 使用样式，有多种样式可选
import "highlight.js/styles/default.css";
var preCodeHtml = (value , el)=>{
    value = value === undefined || value === null ? '' : value;
    let $html = value+"";
    if(value){
        var preHtml = (value)=>{
            let matched = /<pre(\s?)(.*?)>/g.exec(value);
            if(matched !== null){
                let index = value.indexOf('</pre>');
                if(index > -1){
                    let content = value.substr(matched.index+matched[0].length ,index - matched[0].length - matched.index).trim();
                    let yy = (/brush:(.*?);/g.exec(matched[1]) || [])[1] || 'defaulr'
                    let hhtml = '';
                    let html = content.replace("\r\n","\n").split("\n").map((d , index)=>{
                        hhtml += "<li style=\"color: rgb(153, 153, 153);\">"+(index + 1)+"</li>";
                        return d;
                    }).join("\n");
                    value = (matched.index > 0 ? value.substr(0,matched.index) : '')
                        + "<pre class=\"prettyprint\"><code style='language-"+yy+"'>"+html+"</code><ul class=\"pre-numbering\">"+hhtml+"</ul>"+"</pre>"
                        + preHtml(value.substr(index + 6));
                }
            }
            return value;
        }
        $html = preHtml($html);
    }
    if(el){
        el.innerHTML = $html;
        let blocks = el.querySelectorAll("pre code");
        blocks.forEach(block => {
            hljs.highlightBlock(block);
            //插入复制功能
            let copy = document.createElement('div');
            copy.classList.add('hljs-copy');
            copy.innerText = '复制';
            //添加点击事件
            copy.addEventListener('click', () => {
                //创建一个输入框
                let textarea = document.createElement('textarea')
                document.body.appendChild(textarea);
                textarea.setAttribute('readonly', 'readonly');
                textarea.value = block.innerText;
                textarea.select();
                if (document.execCommand('copy')) {
                    copy.innerText = '复制成功';
                }else{
                    copy.innerText = '复制失败';
                }
                document.body.removeChild(textarea);
            })
            block.addEventListener('mouseout', () => { copy.innerText = '复制'; copy.style.display = "none"; })
            block.addEventListener('mouseover', () => { copy.style.display = "block"; })
            copy.addEventListener('mouseover', () => { copy.style.display = "block"; })
            block.parentNode.appendChild(copy)
        });
    }
    return value;
}
Vue.directive("code-html", {
    // 当vue要对当前指令所在的元素进行解析的时候执行
    bind(el,binding){
        // preCodeHtml(binding.value , el)
    },
    // 当当前指令所在的元素被插入到页面中的时候，会执行的函数
    inserted(el,binding){
        if(el.attributes.dataPreCode !== binding.value){
            el.attributes.dataPreCode = binding.value;
            el.style.maxWidth = '100%'
            preCodeHtml(binding.value , el)
        }
    },
    // 当当前指令绑定的数据，发生改变的时候，会执行update函数
    update(el,binding){
        if(el.attributes.dataPreCode !== binding.value){
            el.attributes.dataPreCode = binding.value;
            el.style.maxWidth = '100%'
            preCodeHtml(binding.value , el)
        }
    },
    // 当数据改变完成，元素更新完毕之后，会执行componentUpdated
    componentUpdated(el,binding){
        if(el.attributes.dataPreCode !== binding.value){
            el.attributes.dataPreCode = binding.value;
            el.style.maxWidth = '100%'
            preCodeHtml(binding.value , el)
        }
    },
    // 当指令被卸载的时候
    unbind(el,binding){
        // el.append(preCodeHtml(binding.value))
    }
})
import { render, staticRenderFns } from "./dividerBox.vue?vue&type=template&id=1bfeaa78&scoped=true&"
import script from "./dividerBox.vue?vue&type=script&lang=js&"
export * from "./dividerBox.vue?vue&type=script&lang=js&"
import style0 from "./dividerBox.vue?vue&type=style&index=0&id=1bfeaa78&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfeaa78",
  null
  
)

export default component.exports
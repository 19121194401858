<template>
  <div class="breadcrumb" style="position: relative;">
    <el-breadcrumb class="breadcrumb-container" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item
          v-for="item in crumbList.filter(d=>d.title)"
          :key="item.name"
          :to="{name:item.name,params:{...$route.params},query:{...$route.query}}"
        >
          <span v-href="{name:item.name,params:{...$route.params},query:{...$route.query}}">{{ item.title }}</span>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
    <div style="position: absolute;right:0;top:0;bottom:0;display: flex;align-items: center;">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  props:{
    autoCurrent:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return {crumbList:[]};
  },
  computed: {
    ...mapState(["currentCrumbList"]),
  },
  watch:{
    "$parent.currentRoute"(){
      this.initCrumbs();
    },
    "$props"(){
      this.initCrumbs();
    },
  },
  mounted() {
    this.initCrumbs();
  },
  methods:{
    initCrumbs(){
      if(this.autoCurrent){
        let crumbs = [] , currentPath = (this.$parent.currentRoute || {}).path || this.$route.path;
        for (let i = 0; i < this.currentCrumbList.length; i++) {
          crumbs.push(this.currentCrumbList[i]);
          if(this.currentCrumbList[i].path === currentPath){
            break;
          }
        }
        this.crumbList = crumbs
      }else{
        this.crumbList = [...this.currentCrumbList]
      }
    }
  }
};
</script>
 
<style lang="less" scoped>
.breadcrumb {
  padding: 1rem 0;
  ::v-deep {
    .el-breadcrumb__inner a,
    .el-breadcrumb__inner.is-link {
      color: #3e3e3e;
    }
  }
}
</style>
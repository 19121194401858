export default {
    state:{},
    mutatons:{
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },
    },
    actions:{},
    getters:{}
}
<template>
  <el-date-picker
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    :align="inputProps.align || 'center'"
    :picker-options="pickerOptions"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    style="width: 100%"
    v-if="
      !initLoading &&
      itemType === 0 &&
      isShow &&
      (inputType === 'date' || inputType === 'daterange')
    "
  >
    <!--  DatePicker    year/month/date/dates/months/years week/datetime/datetimerange/ daterange/monthrange-->
    <template :slot="keyName" v-for="keyName in Object.keys($scopedSlots)">
      <slot :name="keyName" v-bind:coumn="inputProps" v-bind:prop="prop"></slot>
    </template>
  </el-date-picker>
  <el-upload
    v-bind="inputProps"
    :http-request="handleUpload"
    :on-remove="handleUploadRemove"
    :before-upload="handleUploadBefore"
    :on-change="handleUploadChange"
    :on-exceed="handleUploadExceed"
    :on-success="handleUploadSuccess"
    :on-progress="handleUploadProgress"
    :file-list="acValue"
    :auto-upload="false"
    ref="upload"
    :class="
      inputProps.limit > 1 && acValue.length >= inputProps.limit
        ? 'hidden-upload'
        : ''
    "
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'upload'
    "
  >
    <!--      image/png, image/jpeg,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document-->
    <slot
      v-bind:props="inputProps"
      v-bind:prop="prop"
      v-bind:uploadFiles="othersData.uploadFiles || []"
      v-bind:fileList="acValue"
      v-bind:handTrigger="handleUploadClick"
    >
      <div
        :class="'el-upload_item '"
        v-if="inputProps.listType === 'picture-card'"
      >
        <template
          v-if="!inputProps.showFileList && (acValue || []).length >= 1"
        >
          <el-image
            fit="contain"
            :src="acValue[0].url"
            style="width: 100%; height: 100%"
          />
        </template>
        <i class="el-icon-plus" v-else></i>
      </div>
    </slot>
    <template slot="trigger">
      <slot
        name="trigger"
        v-bind:props="inputProps"
        v-bind:uploadFiles="othersData.uploadFiles || []"
        v-bind:prop="prop"
        v-bind:fileList="acValue"
      >
        <template v-if="inputProps.listType !== 'picture-card'">
          <el-button size="small" type="primary">点击上传</el-button>
        </template>
      </slot>
    </template>
    <template slot="file" slot-scope="{ file }">
      <slot
        name="file"
        v-bind:props="inputProps"
        v-bind:prop="prop"
        v-bind:file="file"
        v-bind:fileList="acValue"
      >
        <div style="position: relative; visibility: visible">
          <template
            v-if="
              inputProps.listType === 'picture-card' &&
              getFileMime(null, file.url) === 'image'
            "
          >
            <el-progress
              style="top: -7px"
              color="#00957E"
              :percentage="parseInt(file.percent || 0)"
              v-if="file.status === 'uploading'"
            ></el-progress>
            <el-image
              fit="contain"
              :ref="'image' + file.uid"
              :src="file.url"
              :preview-src-list="acValue.map((d) => d.url)"
              style="width: 100%; height: 100%"
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="$refs['image' + file.uid].showViewer = true"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!inputProps.disabled"
                class="el-upload-list__item-delete"
                @click="
                  () => {
                    $tool.fileResponse(file.url);
                  }
                "
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="!inputProps.disabled"
                class="el-upload-list__item-delete"
                @click="handleUploadRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </template>
          <template v-else>
            <el-progress
              style="top: unset; bottom: -7px"
              color="#00957E"
              :format="
                () => {
                  return (file.percent || 0) + '%';
                }
              "
              :percentage="parseInt(file.percent || 0)"
              v-if="file.status === 'uploading'"
            ></el-progress>
            <a
              class="el-upload-list__item-name"
              target="_blank"
              :href="file.url"
            >
              <i :class="'el-icon-' + (file.ext || 'document')"></i>
              {{ file.name }}
            </a>
            <i
              class="el-icon-close"
              v-if="!inputProps.disabled"
              @click="handleUploadRemove(file)"
            ></i>
            <i class="el-icon-close-tip" v-if="!inputProps.disabled"
              >按 delete 键可删除</i
            >
          </template>
        </div>
      </slot>
    </template>
    <div class="form-item-notice" slot="tip">
      <slot
        name="tip"
        v-bind:props="inputProps"
        v-bind:prop="prop"
        v-bind:fileList="acValue"
      ></slot>
    </div>
  </el-upload>
  <el-switch
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'switch'
    "
  >
  </el-switch>
  <el-cascader
    ref="box"
    v-bind="inputProps"
    :options="parseData"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    style="width: 100%"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'cascader'
    "
  >
  </el-cascader>
  <el-slider
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'slider'
    "
  >
  </el-slider>
  <el-rate
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    v-else-if="!initLoading && itemType === 0 && isShow && inputType === 'rate'"
  >
  </el-rate>
  <div
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'color'
    "
  >
    <el-input
      v-model="acValue"
      readonly
      v-if="inputProps.type === 'color'"
      style="width: calc(100% - 80px)"
    ></el-input>
    <el-color-picker
      ref="box"
      v-bind="inputProps"
      v-model="acValue"
      @change="handleEmit('change', ...arguments)"
      :name="prop"
      style="top: 15px"
    >
    </el-color-picker>
  </div>
  <ele-icon-picker
    v-model="acValue"
    ref="box"
    v-bind="inputProps"
    :name="prop"
    v-else-if="!initLoading && itemType === 0 && isShow && inputType === 'icon'"
  />
  <el-transfer
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    :name="prop"
    style="width: 100%"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'transfer'
    "
  >
  </el-transfer>
  <el-radio-group
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'radio'
    "
    v-loading="loading"
  >
    <template v-for="(item, index) in parseData">
      <el-radio-button
        :label="item.value"
        :disabled="item.disabled === true"
        :border="item.border === true"
        :size="item.size || null"
        :name="prop"
        :key="index"
        v-if="options.listType === 'button'"
      >
        <slot v-bind="item" v-bind:index="index">{{ item.label }}</slot>
      </el-radio-button>
      <el-radio
        :label="item.value"
        :key="index"
        :disabled="item.disabled === true"
        :border="item.border === true"
        :size="item.size || null"
        :name="prop"
        v-else
      >
        <slot v-bind="item" v-bind:index="index">{{ item.label }}</slot>
      </el-radio>
    </template>
  </el-radio-group>
  <el-checkbox-group
    ref="box"
    v-bind="inputProps"
    :disabled="inputProps.disabled === true"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'checkbox'
    "
    v-loading="loading"
  >
    <template v-for="(item, index) in parseData">
      <el-checkbox-button
        :label="item.value"
        :key="index"

        :disabled="item.disabled === true"
        :border="item.border === true"
        :size="item.size || null"
        :name="prop"
        v-if="inputProps.listType === 'button'"
      >
        <slot v-bind="item" v-bind:index="index">{{ item.label }}</slot>
      </el-checkbox-button>
      <el-checkbox
      :key="index"

        :label="item.value"
        :disabled="item.disabled === true"
        :border="item.border === true"
        :size="item.size || null"
        :name="prop"
        v-else
      >
        <slot v-bind="item" v-bind:index="index">{{ item.label }}</slot>
      </el-checkbox>
    </template>
  </el-checkbox-group>
  <el-select
    ref="box"
    v-bind="{ multipleLimit: inputProps.limit || 0, ...inputProps }"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    @blur="isFocus = false"
    @focus="isFocus = true"
    style="width: 100%"
    v-else-if="
      !initLoading && itemType === 0 && isShow && inputType === 'select'
    "
    v-loading="loading"
  >
    <template v-for="(item, index) in parseData">
      <el-option-group
        :key="index"
        :label="item.group + ''"
        :disabled="item.disabled === true"
        v-if="item.group"
      >
        <template v-for="(item1, index1) in item.children || []">
          <el-option
            :key="index1"
            :label="item1.label + ''"
            :value="item1.value"
            :disabled="item1.disabled === true"
            ><slot
              name="select"
              v-bind="item1"
              v-bind:index="index + '-' + index1"
            ></slot
          ></el-option>
        </template>
      </el-option-group>
      <el-option
        :key="index"
        :label="item.label + ''"
        :value="item.value"
        :disabled="item.disabled === true"
        v-else
        ><slot name="select" v-bind="item" v-bind:index="index"></slot
      ></el-option>
    </template>
  </el-select>
  <div
    v-else-if="
      initLoading === false &&
      itemType === 0 &&
      isShow &&
      inputType === 'editor'
    "
  >
    <keep-alive v-if="inputProps.isShowText !== true">
      <vue-ueditor-wrap
        ref="ueditorWrap"
        :name="idEl"
        v-model="acValue"
        :id="'editor' + idEl"
        :editor-id="idEl"
        :config="inputProps.editorConfig || {}"
      ></vue-ueditor-wrap>
    </keep-alive>
    <div v-else v-code-html="acValue"></div>
  </div>
  <div
    :class="'el-tag-box el-tag-box--' + (size || 'small')"
    v-else-if="!initLoading && itemType === 0 && isShow && inputType === 'tag'"
  >
    <el-tag
      :key="tag"
      v-for="tag in acValue"
      :closable="disabled !== true"
      :disable-transitions="false"
      :size="size || 'small'"
      class="el-tag-item"
      @close="handleChange(acValue.splice(acValue.indexOf(tag), 1))"
      type="plain"
      >{{ tag }}</el-tag
    >
    <el-input
      v-if="tagInputVisible"
      :style="{ width: inputProps.tagWidth || false }"
      v-model="tagInputValue"
      :placeholder="inputProps.placeholder"
      ref="saveTagInput"
      :size="size || 'small'"
      class="el-tag-item el-tag-input"
      style="top: -1px"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button
      v-else
      :disabled="
        disabled ||
        (inputProps.limit > 0 && (acValue || []).length >= inputProps.limit)
      "
      plain
      type="primary"
      :size="size || 'small'"
      class="el-tag-item el-tag-botton"
      @click="tagShowInput"
      >{{ inputProps.addBotton || "+ New Tag" }}</el-button
    >
  </div>
  <div
    v-else-if="!initLoading && itemType === 0 && isShow && inputType === 'data'"
  >
    <el-input
      :value="
        chooseData
          .map(
            (d) =>
              d[this.$attrs.showKey || 'title'] ||
              d[this.$attrs.columnKey || 'id']
          )
          .join(',')
      "
      readonly
      :placeholder="inputProps.placeholder"
    >
      <template slot="append">
        <el-link
          style="padding: 0 10px"
          :disabled="!acValue"
          @click="
            acValue = null;
            selectionData = [];
            chooseData = [];
          "
          v-if="inputProps.clearable"
        >
          清空
        </el-link>
        <el-link
          style="padding: 0 10px"
          :disabled="
            !((inputProps.dialog.data || {}).url && columns.length > 0)
          "
          @click="$refs.dialog.visibled(true)"
          type="primary"
        >
          选择{{ (label || "数据").replace("选择", "") }}
        </el-link>
      </template>
    </el-input>
    <slot
      name="append"
      v-bind:data="chooseData"
      v-bind:columns="columns"
      v-bind:tableOptions="inputProps.dialog.table"
      v-bind:remove="handleTableChooseRemove"
    >
      <div
        v-if="acValue && chooseData.length === 0"
        style="text-align: center; color: rgb(195 196 199)"
      >
        数据没找到
      </div>
      <com-table
        v-bind="inputProps.dialog.table"
        :data="chooseData"
        :columns="columns"
        :page-options="false"
        v-if="chooseData.length > 0"
      >
        <template slot="after">
          <el-table-column width="80px" align="center">
            <template slot-scope="{ $index }">
              <el-button
                type="primary"
                size="mini"
                @click="handleTableChooseRemove($index)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </template>
      </com-table>
    </slot>
    <diy-dialog
      ref="dialog"
      :title="inputProps.dialog.title"
      :width="inputProps.dialog.width"
      center
      @confirm="handleTableConfirm"
      @cancel="handleTableConfirm"
    >
      <template v-if="inputProps.dialog.whereColumns">
        <el-form
          v-model="where"
          inline
          label-width="80px"
          @submit.native.prevent
          style="margin-bottom: 20px; width: 100%"
        >
        
          <diy-form-item
            v-model="where"
            :item-type="3"
            :childrens="inputProps.dialog.whereColumns"
          >
          </diy-form-item>
          <el-form-item class="operate">
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset(where)"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="(where = {}) && $refs.table.reset(where)"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </template>
      <com-table
        ref="table"
        v-bind="{
          defaultExpandAll: true,
          ...inputProps.dialog.table,
          limit: inputProps.limit,
        }"
        :data="{
          ...inputProps.dialog.data,
          params: { ...(inputProps.dialog.data.params || {}) },
        }"
        :columns="
          [
            { type: 'selection', width: '55', align: 'center', fixed: 'left' },
          ].concat(columns)
        "
        :where="{ ...(inputProps.dialog.data.where || {}), ...where }"
        :selection.sync="selectionData"
        :row-key="columnKey"
      ></com-table>
    </diy-dialog>
  </div>
  <div
    v-else-if="!initLoading && itemType === 0 && inputProps.isShowText === true"
  >
    {{ (acValue === null || acValue === undefined ? "" : acValue) + "" }}
  </div>
  <el-input-number
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    @blur="handleEmit('blur', ...arguments)"
    @focus="handleEmit('focus', ...arguments)"
    v-else-if="
      initLoading === false &&
      itemType === 0 &&
      isShow &&
      inputType === 'number'
    "
  >
    <template :slot="keyName" v-for="keyName in Object.keys($scopedSlots)">
      <slot :name="keyName" v-bind:coumn="inputProps" v-bind:prop="prop"></slot>
    </template>
  </el-input-number>
  <el-autocomplete
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    @blur="handleEmit('blur', ...arguments)"
    @input="handleEmit('input', ...arguments)"
    @clear="handleEmit('clear', ...arguments)"
    @focus="handleEmit('focus', ...arguments)"
    @select="handleEmit(((acValue = $event.value), 'change'), ...arguments)"
    v-else-if="
      !initLoading &&
      itemType === 0 &&
      isShow &&
      ['on', 'off'].indexOf(inputProps.autocomplete) > -1
    "
  >
    <template :slot="keyName" v-for="keyName in Object.keys($scopedSlots)">
      <slot :name="keyName" v-bind:coumn="inputProps" v-bind:prop="prop"></slot>
    </template>
  </el-autocomplete>
  <el-input
    ref="box"
    v-bind="inputProps"
    v-model="acValue"
    @change="handleEmit('change', ...arguments)"
    @blur="handleEmit('blur', ...arguments)"
    @input="handleEmit('input', ...arguments)"
    @clear="handleEmit('clear', ...arguments)"
    @focus="handleEmit('focus', ...arguments)"
    
    @keyup.enter.native="handleEmit('Inputkey', ...arguments)"
    v-else-if="!initLoading && itemType === 0 && isShow"
  >
    <template :slot="keyName" v-for="keyName in Object.keys($scopedSlots)">
      <slot :name="keyName" v-bind:coumn="inputProps" v-bind:prop="prop"></slot>
    </template>
  </el-input>
  <el-form-item
    v-else-if="!initLoading && itemType === 1 && isShow"
    v-bind="
      Object.assign({}, inputProps, options || {}, {
        rules: rulesAll,
        required: isRequired,
      })
    "
    :prop="prop"
    ref="formItem"
  >
    <slot>
      <div
        :class="{
          'el-form-item-content': true,
          'el-input-group':
            $scopedSlots.before ||
            $scopedSlots.prepend ||
            $scopedSlots.after ||
            $scopedSlots.append,
          ['el-input--' + (size || '')]: size ? true : false,
        }"
        :style="{
          width: allProps.width
            ? !isNaN(allProps.width)
              ? allProps.width + 'px'
              : allProps.width
            : '100%',
        }"
      >
        <div
          :class="
            'el-input el-input-group__prepend el-form-item-item' +
            (size ? ' el-input--' + size : '')
          "
          v-if="$scopedSlots.before"
        >
          <slot name="before"></slot>
        </div>
        <div
          :class="
            'el-input el-input-group__prepend el-form-item-item' +
            (size ? ' el-input--' + size : '')
          "
          v-else-if="$scopedSlots.prepend"
        >
          <slot name="prepend"></slot>
        </div>
        <div
          :class="{
            'el-form-item-item': true,
            'el-input-group__prepend-hidden':
              $scopedSlots.before || $scopedSlots.prepend,
            'el-input-group__append-hidden':
              $scopedSlots.after || $scopedSlots.append,
          }"
          style="flex: 1"
        >
          <diy-form-item
            v-bind="{ ...$attrs, ...$props, itemType: 0 }"
            :style="options.style || {}"
            :class="options.class || {}"
            v-model="acValue"
            @change="handleEmit('change', ...arguments)"
            @done="handleEmit('done', ...arguments)"
            @update="handleEmit('update', ...arguments)"
            @destroy="handleEmit('destroy', ...arguments)"
            :prefix-prop="prefixProp"
            ref="diyFormItemChildren"
            :condition-fun="conditionFun"
          >
            <template
              slot-scope="Obj"
              :slot="slotName === 'content' ? 'default' : slotName"
              v-for="slotName in Object.keys($scopedSlots).filter(
                (s) =>
                  ['after', 'before', 'tip', 'append', 'prepend'].indexOf(s) ===
                  -1
              )"
            >
              <slot :name="slotName" v-bind="Obj"></slot>
            </template>
            <template slot="tip" slot-scope="Obj" v-if="inputType === 'upload'">
              <slot name="tip" v-bind="Obj">
                <span v-if="allProps.notice" v-html="allProps.notice"></span>
              </slot>
            </template>
            <template slot-scope="Obj" slot="tip" v-else>
              <slot name="tip" v-bind="Obj"></slot>
            </template>
          </diy-form-item>
        </div>
        <div
          :class="
            'el-input el-input-group__append el-form-item-item' +
            (size ? ' el-input--' + size : '')
          "
          v-if="$scopedSlots.after"
        >
          <slot name="after"></slot>
        </div>
        <div
          :class="
            'el-input el-input-group__append el-form-item-item' +
            (size ? ' el-input--' + size : '')
          "
          v-else-if="$scopedSlots.append"
        >
          <slot name="append"></slot>
        </div>
      </div>
    </slot>
    <div class="form-item-notice">
      <slot name="tip" v-bind="allProps" v-if="inputType !== 'upload'">
        <div v-if="allProps.notice" v-html="allProps.notice"></div>
      </slot>
    </div>
  </el-form-item>
  <el-table
    ref="table"
    :data="tableData"
    :show-header="showHeader"
    :show-overflow-tooltip="false"
    :border="false"
    :class="showHeader ? 'cell-top-table' : 'cell-top-table cell-top-header'"
    :cell-class-name="othersData.isHeaderTop ? 'cell-top' : ''"
    default-expand-all
    v-loading="emitData.table"
    v-else-if="!initLoading && itemType === 2 && isShow && !loading"
  >
    <el-table-column
      v-for="(_column, columnIndex) in cColumns"
      v-bind="{ ..._column.column, slot: null }"
      :key="columnIndex"
    >
      <template slot-scope="{ row, $index, column }">
        <el-radio
          :value="tableData[$index][_column.prop]"
          :label="_column.valueLabel"
          style="width: 100%; height: 100%"
          @change="initTableData(tableData, $index, true, $event)"
          class="el-form-item"
          v-if="!_column.data && _column.type === 'radio'"
          >{{ tableData[$index][_column.columnKey] }}</el-radio
        >
        <el-checkbox
          :value="tableData[$index][_column.prop]"
          :label="_column.valueLabel"
          :false-label="_column.falseLabel"
          style="width: 100%; height: 100%"
          @change="
            initTableData(
              tableData,
              $index,
              !(tableData[$index][_column.prop] === _column.valueLabel),
              $event
            )
          "
          class="el-form-item"
          v-else-if="!_column.data && _column.type === 'checkbox'"
          >{{ tableData[$index][_column.columnKey] }}</el-checkbox
        >
        <slot
          :name="_column.slot"
          v-bind:row="tableData[$index]"
          v-bind:$index="$index"
          v-bind:props="{
            ..._column,
            type: null,
            slot: null,
            prefixProp: curProp + '[' + $index + '].',
          }"
          v-else-if="_column.slot && $scopedSlots[_column.slot]"
        ></slot>
        <template v-else-if="_column.slot === 'image'">
          <el-image
            :style="{ width: '100%', height: '55px' }"
            fit="contain"
            :src="
              getTemplateDataValue(
                { row, $index, column, $props: _column },
                null,
                ''
              )
            "
            :preview-src-list="[
              getTemplateDataValue(
                { row, $index, column, $props: _column },
                null,
                ''
              ),
            ]"
            v-if="
              getTemplateDataValue(
                { row, $index, column, $props: _column },
                null,
                ''
              )
            "
          >
            <div slot="error">-</div>
          </el-image>
        </template>
        <template v-else-if="_column.slot === 'avatar'">
          <el-avatar
            :src="
              getTemplateDataValue(
                { row, $index, column, $props: _column },
                null,
                ''
              )
            "
          >
            -
          </el-avatar>
        </template>
        <span
          :style="othersData.isHeaderTop ? 'line-height: 40px;' : ''"
          v-else-if="_column.type === 'text' && _column.template"
          v-html="
            getTemplateDataValue(
              { row, $index, column, $props: _column },
              _column.template
            )
          "
        ></span>
        <span
          :style="othersData.isHeaderTop ? 'line-height: 40px;' : ''"
          v-else-if="_column.type === 'text'"
          >{{ row[_column.prop] }}</span
        >
        <div
          style="text-align: left"
        :key="componentIndex"

          v-for="(component, componentIndex) in _column.components"
          v-else
        >
          <com-component
            v-bind="{ ...component, prefixProp: curProp + '[' + $index + '].' }"
            :key="componentIndex"
            :elseComponents="elseComponents"
            :condition-fun="handleCondition"
            :condition-val="
              () => {
                return row;
              }
            "
            :componentMethod="componentMethod"
            v-if="component.name"
          >
            <template
              slot-scope="Obj"
              :slot="slotName"
              v-for="slotName in Object.keys($scopedSlots).filter(
                (s) => ['else-component'].indexOf(s) === -1
              )"
            >
              <slot :name="slotName" v-bind="Obj"></slot>
            </template>
            <template slot="else-component" slot-scope="{ name, props }">
              <!--              {{JSON.stringify(_column.props)}}-->
              <!--              {{JSON.stringify(formatHumpLineTransfer({} , _column.props , props,{..._column.props.options,condition:null}))}}-->
              <diy-form-item
                v-bind="
                  formatHumpLineTransfer({}, _column.props, props, {
                    ..._column.props.options,
                    condition: null,
                  })
                "
                v-model="tableData[$index][props.prop]"
                @change="handleEmit('change', ...arguments)"
                @done="handleEmit('done', ...arguments)"
                @update="handleEmit('update', ...arguments)"
                @rule="handleEmit('rule', ...arguments)"
                @destroy="handleEmit('destroy', ...arguments)"
                :prefix-prop="curProp + '[' + $index + '].'"
                :condition-fun="handleCondition"
                ref="diyFormItemChildren"
                v-if="props.prop"
              >
                <template
                  slot-scope="Obj"
                  :slot="slotName"
                  v-for="slotName in Object.keys($scopedSlots).filter(
                    (s) => ['else-component'].indexOf(s) === -1
                  )"
                >
                  <slot :name="slotName" v-bind="Obj"></slot>
                </template>
              </diy-form-item>
              <diy-form-item
                v-bind="
                  formatHumpLineTransfer({}, _column.props, props, {
                    ..._column.props.options,
                    condition: null,
                  })
                "
                v-model="tableData[$index]"
                @change="handleEmit('change', ...arguments)"
                @done="handleEmit('done', ...arguments)"
                @update="handleEmit('update', ...arguments)"
                @rule="handleEmit('rule', ...arguments)"
                @destroy="handleEmit('destroy', ...arguments)"
                :prefix-prop="curProp + '[' + $index + '].'"
                :condition-fun="handleCondition"
                ref="diyFormItemChildren"
                v-else
              >
                <template
                  slot-scope="Obj"
                  :slot="slotName"
                  v-for="slotName in Object.keys($scopedSlots).filter(
                    (s) => ['else-component'].indexOf(s) === -1
                  )"
                >
                  <slot :name="slotName" v-bind="Obj"></slot>
                </template>
              </diy-form-item>
            </template>
          </com-component>
        </div>
      </template>
    </el-table-column>
    <slot
      name="action"
      v-bind:count="tableData.length"
      v-bind:handleAdd="handleTableAdd"
      v-bind:handleDel="handleTableDelete"
      v-bind:roles="tableRoles"
    >
      <el-table-column
        fixed="right"
        label="操作"
        width="120"
        v-if="allProps.showAction !== false"
      >
        <div
          :class="othersData.isHeaderTop ? 'el-form-item' : ''"
          style="text-align: center"
          slot-scope="{ row, $index }"
        >
          <el-button
            type="primary"
            style="padding: 5px 7px; font-size: 11px"
            :disabled="tableRoles.add"
            size="mini"
            @click="handleTableAdd({})"
            >添加</el-button
          >
          <el-button
            type="primary"
            style="padding: 5px 7px; font-size: 11px"
            :disabled="tableRoles.del"
            size="mini"
            @click="handleTableDelete($index)"
            >删除</el-button
          >
        </div>
      </el-table-column>
    </slot>
  </el-table>
  <span v-else-if="!initLoading && itemType === 3 && isShow">
    <template v-for="(component, componentIndex) in components">
      <com-component
        v-bind="component"
        :key="componentIndex"
        :elseComponents="elseComponents"
        :condition-fun="handleCondition"
        :componentMethod="componentMethod"
        v-if="component.name"
      >
        <template
          slot-scope="Obj"
          :slot="slotName"
          v-for="slotName in Object.keys($scopedSlots).filter(
            (s) => ['else-component'].indexOf(s) === -1
          )"
        >
          <slot :name="slotName" v-bind="Obj"></slot>
        </template>
        <template slot="else-component" slot-scope="{ name, props }">
          <diy-form-item
            v-bind="
              formatHumpLineTransfer({}, curProps, props, { condition: null })
            "
            :prefix-prop="curProp"
            v-model="formData[props.prop]"
            @change="handleEmit('change', ...arguments)"
            @done="handleEmit('done', ...arguments)"
            @update="handleEmit('update', ...arguments)"
            @rule="handleEmit('rule', ...arguments)"
            @destroy="handleEmit('destroy', ...arguments)"
            :condition-fun="handleCondition"
            ref="diyFormItemChildren"
            v-if="props.prop"
          >
            <template
              slot-scope="Obj"
              :slot="slotName"
              v-for="slotName in Object.keys($scopedSlots).filter(
                (s) => ['else-component'].indexOf(s) === -1
              )"
            >
              <slot :name="slotName" v-bind="Obj"></slot>
            </template>
          </diy-form-item>
          <diy-form-item
            v-bind="
              formatHumpLineTransfer({}, curProps, props, { condition: null })
            "
            :prefix-prop="curProp"
            v-model="formData"
            @change="handleEmit('change', ...arguments)"
            @done="handleEmit('done', ...arguments)"
            @update="handleEmit('update', ...arguments)"
            @rule="handleEmit('rule', ...arguments)"
            @destroy="handleEmit('destroy', ...arguments)"
            :condition-fun="handleCondition"
            ref="diyFormItemChildren"
          >
            <template
              slot-scope="Obj"
              :slot="slotName"
              v-for="slotName in Object.keys($scopedSlots).filter(
                (s) => ['else-component'].indexOf(s) === -1
              )"
            >
              <slot :name="slotName" v-bind="Obj"></slot>
            </template>
          </diy-form-item>
        </template>
      </com-component>
    </template>
  </span>
</template>
<script>
import tool from "@/Utils/tool";
import cityData from "@/Utils/citydata.js";
import VueUeditorWrap from "vue-ueditor-wrap";
import DiyDialog from "@/components/com/diyDialog";
import dayjs from "dayjs";
let OSS = require("ali-oss");
/**
 * https://fex.baidu.com/ueditor/#start-toolbar
 * 'anchor', //锚点
 'undo', //撤销
 'redo', //重做
 'bold', //加粗
 'indent', //首行缩进
 'snapscreen', //截图
 'italic', //斜体
 'underline', //下划线
 'strikethrough', //删除线
 'subscript', //下标
 'fontborder', //字符边框
 'superscript', //上标
 'formatmatch', //格式刷
 'source', //源代码
 'blockquote', //引用
 'pasteplain', //纯文本粘贴模式
 'selectall', //全选
 'print', //打印
 'preview', //预览
 'horizontal', //分隔线
 'removeformat', //清除格式
 'time', //时间
 'date', //日期
 'unlink', //取消链接
 'insertrow', //前插入行
 'insertcol', //前插入列
 'mergeright', //右合并单元格
 'mergedown', //下合并单元格
 'deleterow', //删除行
 'deletecol', //删除列
 'splittorows', //拆分成行
 'splittocols', //拆分成列
 'splittocells', //完全拆分单元格
 'deletecaption', //删除表格标题
 'inserttitle', //插入标题
 'mergecells', //合并多个单元格
 'deletetable', //删除表格
 'cleardoc', //清空文档
 'insertparagraphbeforetable', //"表格前插入行"
 'insertcode', //代码语言
 'fontfamily', //字体
 'fontsize', //字号
 'paragraph', //段落格式
 'simpleupload', //单图上传
 'insertimage', //多图上传
 'edittable', //表格属性
 'edittd', //单元格属性
 'link', //超链接
 'emotion', //表情
 'spechars', //特殊字符
 'searchreplace', //查询替换
 'map', //Baidu地图
 'gmap', //Google地图
 'insertvideo', //视频
 'help', //帮助
 'justifyleft', //居左对齐
 'justifyright', //居右对齐
 'justifycenter', //居中对齐
 'justifyjustify', //两端对齐
 'forecolor', //字体颜色
 'backcolor', //背景色
 'insertorderedlist', //有序列表
 'insertunorderedlist', //无序列表
 'fullscreen', //全屏
 'directionalityltr', //从左向右输入
 'directionalityrtl', //从右向左输入
 'rowspacingtop', //段前距
 'rowspacingbottom', //段后距
 'pagebreak', //分页
 'insertframe', //插入Iframe
 'imagenone', //默认
 'imageleft', //左浮动
 'imageright', //右浮动
 'attachment', //附件
 'imagecenter', //居中
 'wordimage', //图片转存
 'lineheight', //行间距
 'edittip ', //编辑提示
 'customstyle', //自定义标题
 'autotypeset', //自动排版
 'webapp', //百度应用
 'touppercase', //字母大写
 'tolowercase', //字母小写
 'background', //背景
 'template', //模板
 'scrawl', //涂鸦
 'music', //音乐
 'inserttable', //插入表格
 'drafts', // 从草稿箱加载
 'charts', // 图表
 */
const editorBaseConfig = {
  default: {
    initialFrameHeight: 150,
    toolbars: [
      [
        "source", //源代码
        "fullscreen", // 全屏
        "undo", //撤销
        "redo", //重做
        "|",
        "bold", //加粗
        "italic", //斜体
        "underline", //下划线
        "strikethrough", //删除线
        "subscript", //下标
        "superscript", //上标
        "|",
        "forecolor", //字体颜色
        "backcolor", //背景色
        "fontfamily", //字体
        "fontsize", //字号
        "|",
        "unlink", //取消链接
        "link", //超链接
        "blockquote", //引用
        "emotion", //表情
        "spechars", //特殊字符
        "insertcode", //代码语言
        "|",
        "justifyleft", //居左对齐
        "justifyright", //居右对齐
        "justifycenter", //居中对齐
        "|",
        // "simpleupload", //单图上传
        'insertimage', //多图上传

        "inserttable", //插入表格
      ],
    ],
  },
  base: {
    maximumWords: 2000,
    initialFrameHeight: 150,
    toolbars: [
      [
        "source", //源代码
        "undo", //撤销
        "redo", //重做
        "bold", //加粗
        "italic", //斜体
        "underline", //下划线
        "strikethrough", //删除线
        "subscript", //下标
        "superscript", //上标
        "unlink", //取消链接
        "link", //超链接
        "emotion", //表情
        "spechars", //特殊字符
        "justifyleft", //居左对齐
        "justifyright", //居右对齐
        "justifycenter", //居中对齐
        // "simpleupload", //单图上传
        'insertimage', //多图上传
      ],
    ],
  },
  title: {
    maximumWords: 2000,
    initialFrameHeight: 150,
    toolbars: [
      [
        "source", //源代码
        "undo", //撤销
        "redo", //重做
        "bold", //加粗
        "italic", //斜体
        "underline", //下划线
        "strikethrough", //删除线
        "subscript", //下标
        "superscript", //上标
        "emotion", //表情
        "spechars", //特殊字符
        "justifyleft", //居左对齐
        "justifyright", //居右对齐
        "justifycenter", //居中对齐
      ],
    ],
  },
};
export default {
  name: "diyFormItem",
  components: { DiyDialog, VueUeditorWrap },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    prop: {
      type: String,
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default() {
        return null;
      },
    },
    prefixProp: {
      type: String,
      default() {
        return "";
      },
    },
    labelWidth: {
      type: String,
      default() {
        return null;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rules: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    error: {
      type: String,
      default() {
        return null;
      },
    },
    showMessage: {
      type: Boolean,
      default() {
        return true;
      },
    },
    inlineMessage: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      type: String,
      default() {
        return null;
      }, //medium / small / mini
    },
    value: {
      default() {
        return null;
      },
    },
    defaultValue: {
      default() {
        return null;
      },
    },
    itemType: {
      type: Number,
      default() {
        return 1;
      }, //0 / 1 / 2 / 3
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    childrens: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
    conditionFun: {
      type: Function,
      default() {
        return null;
      },
    },
    condition: {
      type: [String, Function],
      default() {
        return null;
      },
    },
    initColumns: [String, Function],
  },
  data() {
    return {
      acValue: null,
      tableData: [],
      rulesAll: {},
      search: "",
      formData: {},
      elseComponents: [
        "el-form-item",
        "diy-form-item",
        "form-item",
        "batch-form-item",
      ],
      parseData: [],
      isRequired: this.required,
      loading: false,
      initLoading: true,
      valueType: null,
      inputType: null,
      placeholder: "",
      inputProps: {},
      components: [],
      typeLists: {
        checkbox: "array",
        daterange: "array",
        cascader: "array",
        upload: "array:image",
        tag: "array",
      },
      fields: [],
      oldFields: [],
      pickerOptions: {},
      changeValue: null,
      isShow: true,
      formItemLoading: true,
      idEl: "formItemBox" + Math.random() * 1000000000,
      selection: [],
      selectionData: [],
      chooseData: [],
      where: {},
      columnKey: this.$attrs.columnKey || "id",
      tagInputVisible: false,
      tagInputValue: "",
      isDone: false,
      emitData: { change: false, table: false },
      cColumns: [],
      columnData: null,
      othersData: {},
      initTableMethod: null,
      form: null,
      allProps: { ...this.$props, ...this.$attrs },
      editloading: false,
      isFocus: false,
    };
  },
  computed: {
    curProps() {
      if (this.itemType > 1) {
        let { labelWidth, prefixProp, label, prop, size, disabled } =
          this.$props;
        let { data, ...otherData } = this.$attrs;
        return {
          ...otherData,
          labelWidth,
          prefixProp,
          label,
          prop,
          size,
          disabled,
        };
      }
      return {};
    },
    showHeader() {
      return (
        (this.allProps.labelPosition || "top") === "top" &&
        this.allProps.showHeader !== false
      );
    },
    tableRoles() {
      let { min, max } = this.inputProps;
      min = min || 1;
      max = max === undefined || max === null ? 9999999 : max;
      return {
        del: this.tableData.length <= min,
        add: this.tableData.length >= max,
      };
    },
    curProp() {
      let l = this.prefixProp.length;
      return (
        (this.prop === "" && l > 0 && this.prefixProp.substr(l - 1, 1) === "."
          ? this.prefixProp.substr(0, l - 1)
          : this.prefixProp) + this.prop
      );
    },
  },
  watch: {
    initColumns(v, o) {
      if (this.initLoading === false && this.itemType === 2) {
        if (v !== o) {
          this.initValue();
        }
      }
    },
    $attrs: {
      deep: true,
      immediate: true,
      handler(v, o) {
        v = v || {};
        if (this.initLoading === false && this.itemType === 0) {
          if (v.type !== o.type) {
            this.inputType = null;
            this.initValue();
          } else {
            if (!this.obj_equal((v || {}).data, (o || {}).data)) {
              this.initParseData();
            }
            let { value, data, ...allprops } = this.allProps;
            let allprops2 = { ...this.$props, ...v };
            delete allprops2.value;
            delete allprops2.data;
            if (this.obj_equal(allprops, allprops2) === false) {
              this.initInputProps({}, true);
            }
          }
        } else if (this.initLoading === true || this.initTableMethod) {
          this.initLoading = true;
          var cColumns = this.initTableColumns();
          if (this.$tool.obj_equal(cColumns, this.cColumns) === false) {
            this.cColumns = cColumns;
            setTimeout(() => {
              this.initLoading = false;
            }, 150);
          } else {
            this.initLoading = false;
          }
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(v, o) {
        if (
          this.itemType === 3 &&
          this.obj_equal(v, this.changeValue) === false
        ) {
          if (this.initLoading === false) {
            this.formData = { ...(v || {}) };
            this.changeValue = { ...this.formData };
          }
        } else if (
          this.itemType === 2 &&
          this.obj_equal(v, this.changeValue) === false
        ) {
          if (this.initLoading === false) {
            this.tableData = [...this.initTableData(v || [])];
            this.changeValue = [...this.tableData];
          }
        } else {
          this.$nextTick(() => {
            let _v =
              this.value === undefined || this.value === null
                ? this.defaultValue
                : this.value;
            if (this.obj_equal(_v, this.changeValue) === false) {
              if (this.itemType === 0) {
                if (this.inputType === "data") {
                  this.initDataChooseData();
                } else {
                  this.changeValue = this.setValueType(this.valueType, _v);
                  this.acValue = this.setValueType(this.valueType, _v);
                }
              } else {
                this.changeValue = _v;
                this.acValue = _v;
              }
            }
          });
        }
        return v;
      },
    },
    formData: {
      deep: true,
      immediate: true,
      handler(v) {
        if (this.itemType === 3) {
          this.changeValue = { ...(v || {}) };
        }
      },
    },
    acValue(v, o) {
      if (this.inputType !== "input") {
        this.handleChange(v);
      }
    },
    isShow(v, o) {
      if (!v && v !== o && this.prop) {
        this.$emit("destroy", { prop: this.curProp });
      }
    },
    "$props.disabled"(v) {
      if (this.itemType === 0 || this.itemType === 1) {
        this.inputProps.disabled = v;
      }
    },
    "$props.required"() {
      this.$nextTick(() => {
        this.initRules();
      });
    },
  },
  created() {
    this.initLoading = true;
    this.initValue();
  },
  mounted() {
    if (this.initLoading === false) {
      this.allProps = { ...this.$props, ...this.$attrs };
      this.initValue();
    }
    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          // console.log('回车事件')
          // 调用你的搜索方法
          if(this.$refs.table){
            this.$refs.table.reset(this.where)
          }
        }
      });
    });
  },
  beforeUnmount() {
    // 移除键盘事件监听
    window.removeEventListener('keyup');
  },
  destroyed() {
    if (this.prop) {
      this.$emit("destroy", { prop: this.curProp, vNode: this });
    }
  },
  methods: {
    changeKeyEnter(){
      console.log("5555555555555");
      this.$emit('changeKeyEnter')
    },
    componentMethod(component, _this) {
      if (this.elseComponents.indexOf(component.name) > -1) {
        component.name = "diy-form-item";
        component.props = component.props || {};
        component.props.itemType =
          [0, 1, 2].indexOf(component.props.itemType) === -1
            ? ["batch-form-item"].indexOf(component.name) === -1
              ? 1
              : 2
            : component.props.itemType;
      }
    },
    formatHumpLineTransfer(...vars) {
      let obj = {};
      for (let i = 0; i < vars.length; i++) {
        if (typeof vars[i] === "object") {
          let _obj = tool.formatHumpLineTransfer(vars[i]);
          for (const varKey in _obj) {
            obj[varKey] = _obj[varKey];
          }
        }
      }
      return obj;
    },
    handleTableChooseRemove($index) {
      this.selectionData.splice($index, 1);
      this.handleTableConfirm("confirm");
    },
    handleTableConfirm(type) {
      if (type === "confirm") {
        this.chooseData = [...this.selectionData];
        if (this.inputProps.multiple === true || this.inputProps.limit > 1) {
          this.acValue = this.chooseData.map((d) => d[this.columnKey]);
        } else {
          this.acValue =
            this.chooseData.map((d) => d[this.columnKey])[0] || null;
        }
      }
      this.selection = this.chooseData.map((d) => d[this.columnKey]);
      this.selectionData = [...this.chooseData];
    },
    initDataChooseData() {
      if (!this.inputType === "data") return;
      if (this.value !== null) {
        let vals =
          this.value instanceof Array
            ? this.value
            : ["number", "string"].indexOf(typeof this.value) > -1
            ? (this.value + "").split(",")
            : [];
        if (
          vals.length > 0 &&
          (this.chooseData.length === 0 ||
            this.chooseData.filter(
              (d) =>
                vals.indexOf(d[this.columnKey]) > -1 ||
                vals.indexOf(d[this.columnKey] + "") > -1
            ).length !== this.chooseData.length)
        ) {
          let { data, oldKey } = this.inputProps.dialog || {};
          if (data.url && vals.length > 0) {
            this.handleChooseData("", {
              url: data.url,
              params: {
                ...data.params,
                [oldKey || "oldIds"]: this.value,
              },
              data: { ...(data.where || {}) },
            }).then((data) => {
              this.selectionData = data.filter(
                (d) =>
                  vals.indexOf(d[this.columnKey]) > -1 ||
                  vals.indexOf(d[this.columnKey] + "") > -1
              );
              if (this.selectionData.length > 0) {
                this.handleTableConfirm("confirm");
              } else {
                this.chooseData = [];
                this.selection = [];
              }
            });
          }
        }
      } else {
        this.selectionData = [];
        this.chooseData = [];
        this.selection = [];
        this.changeValue = null;
        this.acValue = null;
      }
    },
    handleTableAdd(row) {
      let $tableData = [...this.tableData];
      $tableData.push(
        this.initTableFormData(
          !row || row instanceof PointerEvent ? {} : row,
          $tableData.length
        )
      );
      this.initTableData($tableData);
    },
    handleTableDelete(index) {
      let $tableData = [...this.tableData];
      $tableData.splice(index, 1);
      this.initTableData($tableData);
    },
    initTableColumns(columns) {
      columns = columns || this.columns;
      if (this.initTableMethod && this.form) {
        try {
          var ret = this.initTableMethod(
            columns,
            (this.form || {}).model || {}
          );
          if (ret instanceof Array) {
            columns = [...ret];
          }
        } catch (e) {
          console.log("  --- initTableColumns error", this.initTableMethod, e);
        }
      }
      this.othersData.isHeaderTop = false;
      return columns
        .map((d) => {
          let {
            filteredValue,
            filterMethod,
            filterMultiple,
            filterPlacement,
            filters,
            labelClassName,
            className,
            headerAlign,
            align,
            showOverflowTooltip,
            formatter,
            resizable,
            sortOrders,
            sortBy,
            sortMethod,
            sortable,
            renderHeader,
            fixed,
            minWidth,
            width,
            prop,
            label,
            columnKey,
            index,
            type,
            hidden,
            slot,
            components,
            condition,
            callback,
            valueLabel,
            falseLabel,
            options,
            ...props
          } = this.$tool.formatHumpLineTransfer(d);
          let { prefixProp, showHeader, ...curProps } = this.curProps;
          props = Object.assign(
            {},
            curProps,
            { label, prop },
            props,
            (options = options || {})
          );
          props.type =
            ["index", "expand", "radio", "checkbox", "text"].indexOf(type) > 0
              ? null
              : type;
          type =
            ["index", "expand", "radio", "checkbox", "text"].indexOf(type) > 0
              ? type
              : undefined;
          columnKey = type ? columnKey || this.columnKey : columnKey;
          let column = {
            filteredValue,
            filterMethod,
            filterMultiple,
            filterPlacement,
            filters,
            labelClassName,
            className,
            headerAlign,
            align,
            showOverflowTooltip,
            formatter,
            resizable,
            sortOrders,
            sortBy,
            sortMethod,
            sortable,
            renderHeader,
            fixed,
            minWidth,
            width,
            prop,
            label,
            columnKey,
            index,
            type,
          };
          for (const columnKey1 in column) {
            if (column[columnKey1] === undefined) {
              delete column[columnKey1];
            }
          }
          var template = null;
          if (type && d.template) {
            try {
              template = eval("(" + d.template + ")");
            } catch (e) {}
          }
          let itemType = -1;
          components = components || [];
          if (components.length === 0) {
            itemType = !isNaN(props.itemType) ? parseInt(props.itemType) : -1;
            if ([0, 1, 2, 3].indexOf(itemType) === -1) {
              itemType =
                (this.allProps.labelPosition || "top") === "top" ? 0 : 1;
            }
            props.itemType = itemType;
            props.options = options;
            if (
              props.itemType === 1 &&
              (this.allProps.labelPosition || "top") === "top" &&
              this.allProps.showHeader === false
            ) {
              props.labelWidth = "0px";
            }
            components = [{ name: "diy-form-item", props: props }];
            if (components.length > 0 && itemType !== 0) {
              this.othersData.isHeaderTop = true;
            }
          }
          if (
            (type === "checkbox" || type === "radio") &&
            !(valueLabel === null || valueLabel === undefined)
          ) {
            if (
              valueLabel === true ||
              ["string", "number", "boolean"].indexOf(typeof valueLabel) === -1
            ) {
              valueLabel = 1;
            } else if (valueLabel === false) {
              valueLabel = 0;
            }
          } else {
            valueLabel = true;
          }
          if (
            type === "checkbox" &&
            !(falseLabel === null || falseLabel === undefined)
          ) {
            if (
              falseLabel === false ||
              ["string", "number", "boolean"].indexOf(typeof falseLabel) === -1
            ) {
              falseLabel = 0;
            } else if (falseLabel === true) {
              falseLabel = 1;
            }
          } else {
            falseLabel = null;
          }
          column.align = column.align || (itemType !== 0 ? "center" : "left");
          return {
            props,
            type,
            column,
            prop,
            columnKey,
            slot,
            hidden,
            valueLabel,
            falseLabel,
            template,
            components: [...components],
          };
        })
        .filter((d) => d.hidden !== true);
    },
    initTableFormData(d, index, radioIndex, checked) {
      let formData = this.columnData;
      if (formData === null) {
        formData = {};
        for (let i = 0; i < this.columns.length; i++) {
          let column = this.columns[i];
          let valueLabel = column.valueLabel;
          let falseLabel = column.falseLabel;
          if (column.prop) {
            if (column.callback && column.callback instanceof Function) {
              formData[column.prop] = column.callback;
            } else if (
              !column.data &&
              (column.type === "radio" || column.type === "checkbox")
            ) {
              this.columnKey = column.prop || column.columnKey;
              if (!(valueLabel === null || valueLabel === undefined)) {
                if (
                  valueLabel === true ||
                  ["string", "number", "boolean"].indexOf(typeof valueLabel) ===
                    -1
                ) {
                  valueLabel = 1;
                } else if (valueLabel === false) {
                  valueLabel = 0;
                }
              } else {
                valueLabel = true;
              }
              if (
                column.type === "checkbox" &&
                !(falseLabel === null || falseLabel === undefined)
              ) {
                if (
                  falseLabel === false ||
                  ["string", "number", "boolean"].indexOf(typeof falseLabel) ===
                    -1
                ) {
                  falseLabel = 0;
                } else if (falseLabel === true) {
                  falseLabel = 1;
                }
              } else {
                falseLabel = null;
              }
              formData[column.prop] = function (
                val,
                index,
                data,
                radioIndex,
                checked = true
              ) {
                if (radioIndex === undefined || radioIndex === null)
                  return val === valueLabel ||
                    (val === true && falseLabel !== 1)
                    ? valueLabel
                    : falseLabel;
                if (index === radioIndex) {
                  return checked === true ? valueLabel : falseLabel;
                }
                return index !== radioIndex &&
                  column.type === "checkbox" &&
                  val === valueLabel
                  ? valueLabel
                  : falseLabel;
              };
            } else {
              let defaultValue =
                column.defaultValue === undefined ? null : column.defaultValue;
              formData[column.prop] =
                defaultValue instanceof Function
                  ? function (val, index, data) {
                      if (val !== undefined && val !== null && val !== "") {
                        return val;
                      }
                      return defaultValue(val, index, data);
                    }
                  : defaultValue;
            }
          }
        }
        this.columnData = formData;
      }
      index = isNaN(index) === true ? 0 : index;
      if (Object.prototype.toString.call(d) !== "[object Object]") {
        if (typeof d === "string") {
          d = JSON.parse(d) || {};
        } else {
          d = {};
        }
      }
      for (const dKey in formData) {
        try {
          if (formData[dKey] instanceof Function) {
            d[dKey] = formData[dKey](
              d[dKey] === undefined || d[dKey] === null ? null : d[dKey],
              index,
              d,
              radioIndex,
              checked
            );
          } else if (
            d[dKey] === undefined ||
            d[dKey] === null ||
            d[dKey] === ""
          ) {
            d[dKey] = formData[dKey];
          }
        } catch (e) {}
      }
      return d;
    },
    initTableData(value, $index, checked) {
      if (this.emitData.table === true) return value;
      value = [...(value || this.tableData || [])];
      let ischange = false;
      this.emitData.table = true;
      let $vLength = value.length || 1;
      if (checked === true || checked === false) {
        if (isNaN($index) === false && value[$index]) {
          ischange = true;
        }
      } else {
        $index = null;
        for (let i = 0; i < $vLength; i++) {
          if ($index === null && (value[i] || {})[this.columnKey]) {
            $index = i;
            break;
          }
        }
      }
      let { min, max } = this.inputProps;
      if (max > 0 && $vLength >= max) {
        value.splice(max - 1, $vLength - max);
        $vLength = max;
        ischange = true;
      }
      let $length = min && $vLength <= min ? min : $vLength || 1;
      for (let i = 0; i < $length; i++) {
        if (i < $vLength) {
          value[i] = this.initTableFormData(value[i] || {}, i, $index, checked);
        } else {
          ischange = true;
          value.push(
            this.initTableFormData(value[i] || {}, i, $index, checked)
          );
        }
      }
      ischange = ischange || value.length !== this.tableData.length;
      if (ischange) {
        this.tableData = value;
        this.handleChange(value);
        if (checked === true || checked === false) {
          this.$emit("update", {
            value: checked,
            oldValue: !checked,
            actValue: value,
            prop:
              this.prefixProp +
              this.prop +
              "[" +
              $index +
              "]." +
              this.columnKey,
            type: "",
            props: this.inputProps,
            vNode: this,
          });
        }
      }

      this.$nextTick(() => {
        this.emitData.table = false;
      });
      return value;
    },
    getIsDone() {
      var isDone = this.isDone === true;
      var chirden = this.getChildren();
      if (isDone === false) {
        if (this.itemType === 0) {
          if (
            this.prop &&
            this.loading === false &&
            this.initLoading === false &&
            this.formItemLoading === true
          ) {
            isDone = true;
          }
        } else if ([2, 3, 1].indexOf(this.itemType) > -1 && chirden) {
          isDone =
            chirden.length === chirden.filter((d) => d.isDone === true).length;
        } else if (this.itemType === 1) {
          if (
            this.loading === false &&
            this.initLoading === false &&
            this.formItemLoading === true
          ) {
            isDone = true;
          }
        }
      }
      return isDone;
    },
    handleEmitDone(...vars) {
      var isDone = this.getIsDone();
      var initDone = () => {
        if (isDone && this.formItemLoading === true) {
          this.formItemLoading = false;
          this.$nextTick(() => {
            var prefixProp =
              this.prefixProp || this.$parent.$attrs.prefixProp || "";
            let l = prefixProp.length;
            var curProp =
              (this.prop === "" && l > 0 && prefixProp.substr(l - 1, 1) === "."
                ? prefixProp.substr(0, l - 1)
                : prefixProp) + this.prop;
            this.$emit("done", {
              prop: curProp,
              prefixProp: prefixProp,
              type: this.inputType,
              value: this.acValue,
              props: this.inputProps,
              vNode: this,
            });
          });
        }
      };
      this.isDone = isDone === true;
      if (this.itemType === 0) {
        initDone();
      } else if (this.itemType === 1) {
        initDone();
      } else if (vars.length > 0) {
        let { type, prefixProp, props, value, vNode } = vars[0] || {};
        if (
          value !== undefined &&
          this.itemType > 1 &&
          (!value || (value instanceof Array && value.length === 0))
        ) {
          var prefix =
              prefixProp.lastIndexOf(".") === prefixProp.length - 1
                ? prefixProp.substr(0, prefixProp.length - 1)
                : prefixProp,
            form = this.$tool.getDataValue(
              (this.form || {}).model || {},
              prefix,
              {}
            );
          if (type === "daterange") {
            var startField = (props || {}).startField || "start_time",
              endField = (props || {}).endField || "end_time",
              startTime = (value || [])[0] || "",
              endTime = (value || [])[1] || "";
            if (
              form[startField] &&
              form[endField] &&
              (startTime !== form[startField] || endTime !== form[endField])
            ) {
              vNode.acValue = [form[startField], form[endField]];
            }
          }
        }
        this.$emit(
          "done",
          ...vars.map(({ prop, type, props, vNode }) => {
            return { prop, type, props, vNode };
          })
        );
      }
    },
    handleEmit(type, ...vars) {
      let method =
        "handleEmit" + type.slice(0, 1).toUpperCase() + type.slice(1);
      if (type && typeof this[method] === "function") {
        this[method](...vars);
      } else {
        if (type && ["rule", "change"].indexOf(type) === -1) {
          this.$emit(type, ...vars);
        }
        if (type === "rule" && this.itemType > 1) {
          this.$emit(type, ...vars);
        }
        if (type === "change") {
          this.handleChange(
            this.acValue,
            this.itemType === 0,
            this.inputType === "input"
          );
        }
        if (type === "Inputkey" ) {
          console.log("enter");
          // this.$emit(type, ...vars);
          this.handleChange(
            this.acValue,
            this.itemType === 0,
            this.inputType === "input"
          );
        }
      }
    },
    handleEmitUpdate({
      value,
      oldValue,
      actValue,
      prop,
      type,
      props,
      vNode,
      isupdate,
    }) {
      var isDone = this.getIsDone();
      let $isUpdate =
        this.othersData["update" + prop] === undefined
          ? false
          : this.othersData["update" + prop];
      if (isDone === false || $isUpdate) return;
      this.othersData["update" + prop] = true;
      var $index = prop.lastIndexOf("."),
        $l = (this.prefixProp + this.prop).length,
        prefix = $index > -1 ? prop.substr($l, $index - $l) : "";
      this.$emit("update", {
        value,
        oldValue,
        actValue,
        prop,
        type,
        props,
        vNode,
        prefix,
        isupdate,
      });
      this.$nextTick(() => {
        if (["daterange", "date"].indexOf(this.inputType) > -1) {
          this.initPickerOptions();
        }
        this.othersData["update" + prop] = false;
      });
    },
    handleChange(val, isUpdate = true, isForce = false) {
      var isDone = this.getIsDone(),
        elForm = this.form;
      if (
        isDone === false ||
        this.emitData.change === true ||
        (this.prop === "" && this.itemType !== 3)
      )
        return;
      this.emitData.change = true;
      if (this.itemType === 2) {
        this.$emit("change", this.tableData);
      } else if (this.itemType === 3) {
        this.$emit("change", this.formData);
      } else {
        if (!this.curProp) return;
        val = this.itemType === 3 ? { ...this.formData } : this.acValue;
        if (this.itemType === 0 && this.inputType === "switch") {
          if (val === "" && this.inputProps.inactiveValue === "0") {
            val = this.inputProps.inactiveValue;
          } else if (val === "" && this.inputProps.activeValue === "0") {
            val = this.inputProps.activeValue;
          }
        }
        let value, oldvalue;
        if (this.itemType === 0 && this.inputType === "upload") {
          val = [...this.acValue];
          var changeValue = [...this.acValue];
          oldvalue = this.value;
          var fileKey = this.inputProps.disabledUpload === true ? "raw" : "url";
          if (this.inputProps.limit === 1) {
            value = (changeValue[0] || {})[fileKey] || null;
          } else {
            value = changeValue.map((d) => d[fileKey]);
          }
        } else {
          value = val;
          oldvalue = this.value;
        }
        let is_equal = true;
        if (
          isForce === true ||
          (is_equal = this.$tool.obj_equal(value, oldvalue)) === false
        ) {
          try {
            if (elForm && this.inputType === "daterange") {
              var startField = this.allProps.startField || "start_time",
                endField = this.allProps.endField || "end_time";
              var prefixProp =
                this.prefixProp || this.$parent.$attrs.prefixProp || "";
              let l = prefixProp.length;
              var _curProp =
                this.prop === "" && l > 0 && prefixProp.substr(l - 1, 1) === "."
                  ? prefixProp.substr(0, l - 1)
                  : prefixProp;
              this.$tool.setDataValue(
                {
                  [_curProp + startField]: (value || [])[0] || "",
                  [_curProp + endField]: (value || [])[1] || "",
                },
                elForm.model
              );
            }
            elForm && elForm.$emit("change", elForm.model);
          } catch (e) {}
          this.$emit("change", value);
          if (this.$tool.obj_equal(value, this.value) === false) {
            this.$emit("update", {
              value,
              oldValue: oldvalue,
              actValue: val,
              prop: this.curProp,
              type: this.inputType,
              props: this.inputProps,
              vNode: this,
            });
          }
          if (
            this.inputType === "data" &&
            (((this.inputProps || {}).dialog || {}).data || {}).params
          ) {
            this.inputProps.dialog.data.params[
              this.inputProps.dialog.oldKey || "oldIds"
            ] = value;
          }
          this.changeValue = value;
        }
      }
      this.$nextTick(() => {
        this.emitData.change = false;
      });
    },
    handleCondition(condition, val) {
      var conditionFun = this.conditionFun,
        isShow = true,
        time = new Date().getTime();
      if (typeof conditionFun === "function" && condition) {
        let $val =
          val === undefined
            ? this.itemType === 3
              ? this.formData
              : this.itemType === 2
              ? this.tableData
              : this.acValue
            : val;
        isShow = conditionFun.call(null, condition, $val, this);
      }
      return isShow;
    },
    initInputProps(inputProps, isForce) {
      inputProps = inputProps || {};
      let {
        batchData,
        editorConfig,
        width,
        height,
        pickerOptions,
        validateEvent,
        remoteMethod,
        multiple,
        max,
        min,
        limit,
        ...otherData
      } = this.$attrs;
      for (const $inputPropsKey in inputProps) {
        if (
          otherData[$inputPropsKey] !== undefined &&
          otherData[$inputPropsKey] !== null &&
          !(otherData[$inputPropsKey] instanceof Object)
        ) {
          inputProps[$inputPropsKey] = otherData[$inputPropsKey];
        }
      }
      var defaultOptions = {
        multiple: null,
        limit: null,
        min: null,
        max: null,
        disabled: this.disabled,
        validateEvent: false,
        placeholder: "请输入" + (this.label || ""),
        label: this.label,
        size: this.size,
        clearable: true,
        action: "",
        filterable: true,
        labelPosition: this.labelPosition,
        labelWidth: this.labelWidth,
      };
      if (this.itemType === 1) {
        defaultOptions.label = this.label;
        defaultOptions.defaultValue = this.defaultValue;
      }
      let $inputProps = this.$tool.ObjectAssign(
        {},
        ($v) => {
          return !($v === undefined || $v === null);
        },
        defaultOptions,
        otherData,
        this.itemType === 0 ? this.options : {},
        this.inputProps || {},
        inputProps,
        { max, min, limit }
      );
      multiple =
        typeof multiple === "boolean"
          ? multiple
          : typeof $inputProps.multiple === "boolean"
          ? $inputProps.multiple
          : null;
      min =
        !isNaN($inputProps.min) || $inputProps.min === null
          ? $inputProps.min
          : !isNaN(min)
          ? min
          : null;
      max =
        !isNaN($inputProps.max) || $inputProps.max === null
          ? $inputProps.max
          : !isNaN(max)
          ? max
          : null;
      limit =
        !isNaN($inputProps.limit) || $inputProps.limit === null
          ? $inputProps.limit
          : !isNaN(limit)
          ? limit
          : null;
      if ($inputProps.multiple === -1 && multiple === null)
        multiple = limit > 1 || max > 1;
      var maxLimit =
          multiple === false
            ? 1
            : limit === null
            ? max
            : max !== null
            ? Math.max(limit, max)
            : limit,
        minLimit =
          min === null
            ? min
            : maxLimit !== null
            ? Math.min(maxLimit, min)
            : min;
      $inputProps.max = max !== null ? maxLimit : null;
      $inputProps.min = min !== null ? minLimit : null;
      $inputProps.limit =
        maxLimit === null && (multiple === true || multiple === null)
          ? null
          : maxLimit;
      $inputProps.multiple =
        (multiple === true || multiple === null) &&
        (maxLimit > 1 || maxLimit === null);
      $inputProps.size =
        ["medium", "small", "mini"].indexOf($inputProps.size) > -1
          ? $inputProps.size
          : "medium";
      $inputProps.step = !isNaN($inputProps.step)
        ? parseInt($inputProps.step) == $inputProps.step
          ? parseInt($inputProps.step)
          : parseFloat($inputProps.step)
        : null;
      $inputProps.precision =
        !isNaN($inputProps.precision) && $inputProps.precision > 0
          ? parseInt($inputProps.precision) == $inputProps.precision
            ? parseInt($inputProps.precision)
            : parseFloat($inputProps.precision)
          : null;
      if ($inputProps.autocomplete !== undefined) {
        $inputProps.autocomplete =
          $inputProps.autocomplete === true || $inputProps.autocomplete === "on"
            ? "on"
            : "off";
      }
      // console.log("$inputProps"  ,this.prop , min + '' , max + '' , limit + '');
      if (this.inputType === "switch") {
        $inputProps.activeValue =
          ($inputProps.activeValue =
            $inputProps.activeValue === undefined ||
            $inputProps.activeValue === null
              ? 1
              : $inputProps.activeValue) instanceof Boolean ||
          $inputProps.activeValue === null
            ? $inputProps.activeValue
            : $inputProps.activeValue + "";
        $inputProps.inactiveValue =
          ($inputProps.inactiveValue =
            $inputProps.inactiveValue === undefined ||
            $inputProps.inactiveValue === null
              ? 2
              : $inputProps.inactiveValue) instanceof Boolean ||
          $inputProps.inactiveValue === null
            ? $inputProps.inactiveValue
            : $inputProps.inactiveValue + "";
      } else if (this.inputType === "upload") {
        if ($inputProps.accept)
          $inputProps.accept = this.getFileMime($inputProps.accept);
        $inputProps.listType =
          $inputProps.listType ||
          (($inputProps.accept || "").indexOf("image") === 0
            ? "picture-card"
            : "text");
        if (!$inputProps.showFileList && $inputProps.showFileList !== false) {
          $inputProps.showFileList =
            $inputProps.listType === "text" || $inputProps.multiple === true;
        } else {
          $inputProps.showFileList =
            $inputProps.showFileList === true ||
            $inputProps.showFileList === "true";
        }
      }
      let $_inputProps = {};
      for (const propKey in $inputProps) {
        if (
          $inputProps[propKey] !== null &&
          $inputProps[propKey] !== undefined
        ) {
          $_inputProps[propKey] = $inputProps[propKey];
        }
      }
      if (isForce) {
        this.inputProps = { ...$_inputProps };
      }
      return $_inputProps;
    },
    initType() {
      let type = this.allProps.type || this.allProps.options.type || "input";
      let inputProps = {};
      if (
        [
          "el-date-picker",
          "date-picker",
          "year",
          "month",
          "date",
          "dates",
          "months",
          "years",
          "week",
          "datetime",
          "datetimerange",
          "daterange",
          "monthrange",
          "day",
        ].indexOf(type) > -1
      ) {
        inputProps.type =
          ["el-date-picker", "date-picker", "day"].indexOf(type) > -1
            ? "date"
            : type;
        type =
          ["datetimerange", "daterange", "monthrange"].indexOf(type) > -1
            ? "daterange"
            : "date";
        inputProps.placeholder = "请选择" + (this.label || "");
        inputProps.startPlaceholder = inputProps.startPlaceholder || "开始时间";
        inputProps.endPlaceholder = inputProps.endPlaceholder || "结束时间";
        inputProps.rangeSeparator = inputProps.rangeSeparator || "至";
        inputProps.valueFormat =
          {
            date: "yyyy-MM-dd",
            year: "yyyy",
            month: "MM",
            day: "dd",
            monthrange: "yyyy-MM",
            daterange: "yyyy-MM-dd",
          }[inputProps.dataType || inputProps.type] || "yyyy-MM-dd HH:mm:ss";
        inputProps.format = inputProps.valueFormat + "";
        this.initPickerOptions();
        this.inputType = type;
        this.initInputProps(inputProps, true);
        this.typeLists.date =
          (this.inputProps.dataType || "datetime") +
          ":" +
          (this.inputProps.format || null);
        this.typeLists.daterange =
          (this.inputProps.dataType || "datetime") +
          ":" +
          (this.inputProps.format || null);
        return;
      } else if (["el-upload", "upload"].indexOf(type) > -1) {
        type = "upload";
        inputProps.multiple = -1;
        inputProps.placeholder = "请上传" + (this.label || "");
      } else if (["el-switch", "switch"].indexOf(type) > -1) {
        type = "switch";
        inputProps.placeholder = "请选择" + (this.label || "");
      } else if (["el-cascader", "cascader", "area"].indexOf(type) > -1) {
        type = "cascader";
        inputProps.placeholder = "请选择" + (this.label || "");
      } else if (["el-slider", "slider"].indexOf(type) > -1) {
        type = "slider";
        inputProps.placeholder = "请选择" + (this.label || "");
      } else if (["el-rate", "rate"].indexOf(type) > -1) {
        type = "rate";
        inputProps.placeholder = "请设置" + (this.label || "");
      } else if (["el-tag", "tag"].indexOf(type) > -1) {
        type = "tag";
        inputProps.multiple = true;
        inputProps.placeholder = "请设置" + (this.label || "");
      } else if (
        ["el-color-picker", "color-picker", "color"].indexOf(type) > -1
      ) {
        type = "color";
        inputProps.placeholder = "请设置" + (this.label || "");
      } else if (["el-transfer", "transfer"].indexOf(type) > -1) {
        type = "transfer";
        inputProps.placeholder = "请设置" + (this.label || "");
        if (!(inputProps.format instanceof Object)) {
          inputProps.format = {
            noChecked: "${total}",
            hasChecked: "${checked}/${total}",
          };
        }
      } else if (
        ["radio-group", "radio", "el-radio-group"].indexOf(type) > -1
      ) {
        type = "radio";
        inputProps.multiple = false;
        inputProps.placeholder =
          "请选择" + (this.label || "数据").replace("选择", "");
      } else if (
        ["checkbox-group", "checkbox", "el-checkbox-group"].indexOf(type) > -1
      ) {
        type = "checkbox";
        inputProps.multiple = true;
        inputProps.placeholder =
          "请选择" + (this.label || "数据").replace("选择", "");
      } else if (["icon", "el-icon"].indexOf(type) > -1) {
        type = "icon";
        inputProps.placeholder =
          "请选择" + (this.label || "图标").replace("选择", "");
      } else if (["el-select", "select"].indexOf(type) > -1) {
        type = "select";
        inputProps.placeholder =
          "请选择" + (this.label || "数据").replace("选择", "");
        inputProps.multiple = -1;
      } else if (["el-data", "data"].indexOf(type) > -1) {
        type = "data";
        inputProps.multiple = -1;
        inputProps.placeholder =
          "请选择" + (this.label || "数据").replace("选择", "");
        let { url, params, where, ...dialog } = {
          ...(this.$attrs.dialog || {}),
        };
        params = { ...this.$route.params, ...(params || {}) };
        params[dialog.oldKey || "oldIds"] = this.acValue;
        inputProps.dialog = {
          ...dialog,
          title:
            dialog.title || "选择" + (this.label || "数据").replace("选择", ""),
          width: dialog.width || "450px",
          oldKey: dialog.oldKey || "oldIds",
          data: {
            url: url,
            params: params,
            where: { ...(where || {}) },
          },
          whereColumns: this.$attrs.whereColumns || null,
          table: dialog.table || {},
        };
        this.$nextTick(() => {
          this.initDataChooseData();
        });
      } else if (["el-editor", "editor"].indexOf(type) > -1) {
        type = "editor";
        if (this.itemType === 0) {
          var $editorConfig = {},
            editorA,
            editorC =
              this.allProps.editorConfig ||
              this.allProps["editor-config"] ||
              null;
          var getZIndex = () => {
            let arr = this.$parent.$el
              ? [
                  this.$parent.$el,
                  ...this.$jquery(this.$parent.$el).parents(),
                ].map((e, i) => {
                  return +e.style.zIndex || 0;
                })
              : [];
            return arr.length ? Math.max(...arr) : 0;
          };
          if (editorC) {
            if (typeof editorC === "string") {
              editorA = editorC;
              editorC = null;
            } else if (editorC.module !== undefined) {
              editorA = editorC.module;
              delete editorC.module;
            }
            $editorConfig =
              editorA !== "all"
                ? { ...(editorBaseConfig[editorA] || {}), ...(editorC || {}) }
                : {};
            for (const $editorConfigKey in $editorConfig) {
              if (this.$attrs[$editorConfigKey] !== undefined) {
                $editorConfig[$editorConfigKey] = this.$attrs[$editorConfigKey];
              }
            }
          }
          inputProps.editorConfig = {
            autoHeightEnabled: false,
            initialFrameHeight: 200,
            initialFrameWidth: "100%",
            serverUrl:
              (this.$location.baseURL || "") +
              (this.$location.baseURL.substr(-1) !== "/" ? "/" : "") +
              "xapi/com.upload/ueditor?_ueditor=" +
              (this.$location.authorization || ""),
            UEDITOR_HOME_URL:
              (this.$location.static_base_url || "/") + "static/ueditor/",
            enableAutoSave: true,
            wordCount: true,
            ...$editorConfig,
            // ,zIndex:'auto'
          };
          this.$nextTick(() => {
            let zIndex =
              this.allProps.zIndex &&
              !isNaN(this.allProps.zIndex) &&
              this.allProps.zIndex > 0
                ? this.allProps.zIndex
                : (this.inputProps.editorConfig || {}).zIndex &&
                  !isNaN(this.inputProps.editorConfig.zIndex) &&
                  this.inputProps.editorConfig.zIndex > 0
                ? this.inputProps.editorConfig.zIndex
                : 0;
            zIndex =
              zIndex && zIndex > 499 ? zIndex - 499 : Math.max(0, getZIndex());
            if (zIndex > 0) {
              this.inputProps.editorConfig.zIndex = zIndex;
              console.log(
                "editorConfig",
                this.curProp,
                this.initLoading,
                this.$parent.$el,
                zIndex
              );
            }
          });
          inputProps.editorConfig.headers =
            inputProps.editorConfig.headers || {};

          let reurl =
            location.origin +
            location.pathname +
            ((location.hash || "").substr(2) || location.search);
          inputProps.editorConfig.headers["X-Requested-with"] =
            reurl +
            (reurl.indexOf("?") === -1 ? "?" : "&") +
            "v=" +
            (this.$packageConfig || {}).version;
        }
      } else if (["el-input-number", "number"].indexOf(type) > -1) {
        type = "number";
        // inputProps.controlsPosition = "right";
        inputProps.min = 0;
        if (this.value === null) {
          this.$nextTick(() => {
            if (this.value === null && this.acValue === null) {
              this.handleChange((this.acValue = 0), true, true);
            }
          });
        }
      } else {
        type = "input";
      }
      this.inputType = type;
      this.initInputProps(inputProps, true);
    },
    initValue() {
      this.initLoading = true;
      var childrens = (
          this.childrens instanceof Array
            ? [...this.childrens]
            : this.childrens
            ? [this.childrens]
            : []
        ).concat(this.$attrs.components || []),
        _this = this,
        value = (function () {
          return _this.value === null ? _this.defaultValue : _this.value;
        })();
      this.formItemLoading = true;
      if (
        childrens.length > 0 &&
        Object.keys(this.components || []) !== childrens.length
      ) {
        var init_component = function (func, component) {
          if (component instanceof Array) {
            return component
              .filter((d) => d)
              .map((component) => {
                if (typeof component.components === "object") {
                  component.components = func(func, component.components);
                }
                return { ...component, name: component.name || "form-item" };
              });
          }
          if (component instanceof Object) {
            if (typeof component.components === "object") {
              component.components = func(func, component.components);
            }
            return [{ ...component, name: component.name || "form-item" }];
          }
          return component;
        };
        this.components = init_component(init_component, childrens);
      }
      var isInit = this.inputType === null;
      if (isInit && (this.itemType === 0 || this.itemType === 1)) {
        this.initType();
      }
      if (this.valueType === null) {
        if (this.inputType === "select" && this.inputProps.multiple) {
          this.valueType = "array";
        } else {
          this.valueType = this.typeLists[this.inputType] || "string";
        }
      }
      if (this.itemType === 0) {
        if (isInit) {
          if (
            this.inputProps.autofocus === true &&
            this.inputType === "input"
          ) {
            try {
              this.$refs.box.focus();
            } catch (e) {}
          }
        }
        if (
          ["date"].indexOf(this.inputType) > -1 &&
          this.inputProps.type !== "year" &&
          isInit &&
          !isNaN(value) &&
          value > 0
        ) {
          let $value = new Date();
          $value.setTime(parseInt(value) * 1000);
          value = $value;
        }
        if (
          ["select", "cascader", "checkbox", "radio", "transfer"].indexOf(
            this.inputType
          ) > -1 &&
          isInit
        ) {
          this.initParseData();
        }
        this.acValue = this.setValueType(this.valueType, value);
        this.initRules();
      } else if (this.itemType === 1) {
        this.initInputProps({}, true);
        this.initRules();
      } else if (this.itemType === 2) {
        this.tableData = [];
        this.columnData = null;
        this.cColumns = [];
        var method =
          this.initColumns instanceof Function ? this.initColumns : null;
        if (!method && this.initColumns) {
          try {
            method = eval("(" + this.initColumns + ")");
          } catch (e) {
            console.log("  --- initColumns error", e);
          }
          if (!(method instanceof Function)) method = null;
        }
        this.initTableMethod = method;
        this.initInputProps({ multiple: true }, true);
        this.formData = this.initTableFormData();
        this.cColumns = this.initTableColumns();
        this.tableData = this.initTableData(value);
        if (method) {
          setTimeout(() => {
            this.cColumns = this.initTableColumns();
          }, 150);
        }
      } else if (this.itemType === 3) {
        value = value instanceof Object ? value : {};
        this.formData = { ...value };
      }
      this.$nextTick(() => {
        this.initLoading = false;
        this.$nextTick(() => {
          if (this.form === null) {
            if (
              [...((this.$parent.$el || {}).classList || [])].indexOf(
                "el-form-create"
              ) > -1
            ) {
              this.form = this.$parent;
            } else {
              if (typeof this.$parent.form !== undefined) {
                this.form = this.$parent.form;
              }
            }
          }
          if (this.form) {
            var children = this.getChildren();
            for (let i = 0; i < children.length; i++) {
              if (children[i]) {
                if (children[i].form === null) {
                  children[i].form = this.form;
                }
              }
            }
          }
        });
        this.handleEmitDone();
      });
    },
    getChildren() {
      return this.$refs.diyFormItemChildren instanceof Array
        ? this.$refs.diyFormItemChildren
        : this.$refs.diyFormItemChildren
        ? [this.$refs.diyFormItemChildren]
        : [];
    },
    setValueType(valueType, acValue) {
      valueType = (this.valueType || "string") + "";
      let type2 = valueType.split(":")[1] || "";
      let type = valueType.split(":")[0] || "";
      acValue = acValue === undefined ? null : acValue;
      // if(acValue === null)return acValue;
      let stringType = typeof acValue,
        isBoolean = stringType === "boolean",
        isString = stringType === "string";
      if (type === "string" && !isString && acValue !== null) {
        try {
          if (
            stringType === "object" &&
            ((acValue instanceof Object && Object.keys(acValue).length > 0) ||
              (acValue instanceof Array && acValue.length > 0))
          ) {
            acValue = JSON.stringify(acValue);
          } else if (stringType !== "object" && stringType !== "function") {
            acValue = acValue.toString();
          } else {
            acValue = null;
          }
        } catch (e) {
          acValue = null;
        }
      } else if (type === "array") {
        var arr = [];
        if (stringType === "object" && acValue) {
          arr = acValue instanceof Array ? [...acValue] : [acValue];
        } else if (isBoolean) {
          arr = [acValue];
        } else if (
          stringType !== "object" &&
          (acValue || acValue === 0 || acValue === "0")
        ) {
          arr = (acValue + "")
            .toString()
            .split(",")
            .filter((d) => d || d === 0 || d === "0");
        }
        var arr2 = [];
        if (type2 === "image") {
          for (let i = 0; i < arr.length; i++) {
            let v1 = typeof arr[i] === "object" ? arr[i] : { url: arr[i] };
            if ((v1 || {}).url && typeof v1.url === "string") {
              v1.url = v1.url.replace("\\", "/");
              let aIndex = v1.url.lastIndexOf("/");
              v1.name =
                v1.name || (aIndex > -1 ? v1.url.substr(aIndex + 1) : v1.url);
              v1.status = "success";
              arr2.push(v1);
            }
          }
          arr = [...arr2];
        }
        acValue = [...arr];
      } else if (
        type === "number" &&
        !(stringType === "object" && acValue instanceof Array) &&
        acValue !== null
      ) {
        if (stringType === "object") {
          acValue = JSON.stringify(acValue);
        } else {
          acValue = acValue.toString();
        }
      }
      return acValue;
    },
    initRules() {
      let is_required = this.required === true;
      let rules =
        this.rules instanceof Array
          ? [...this.rules]
          : Object.keys(this.rules) > 0
          ? [this.rules]
          : [];
      let in_required = false;
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].validator && !(rules[i].validator instanceof Function)) {
          try {
            rules[i].validator = eval(rules[i].validator);
          } catch (e) {
            rules.splice(i, 1);
          }
        }
      }
      for (let i = 0; i < rules.length; i++) {
        if (in_required === false && rules[i].required !== undefined) {
          in_required = true;
          is_required = rules[i].required === true;
          if (is_required) {
            rules[i].message =
              rules[i].message || (this.label || this.prop) + "不能为空";
            break;
          }
        }
      }

      if (!in_required) {
        rules = [
          {
            required: is_required,
            message: (this.label || this.prop) + "不能为空",
          },
          ...rules,
        ];
      }
      if (this.itemType === 1) {
        this.$emit("rule", { rules: rules, prop: this.prop });
      }
      this.rulesAll = [...rules];
      this.isRequired = is_required;
    },
    initPickerOptions() {
      let pickerOptions = this.allProps.pickerOptions || null;
      let maxTime = this.allProps.maxTime || null;
      let minTime = this.allProps.minTime || null;
      let shortcuts = [];
      let $pickerOptions = {};
      if (maxTime || minTime) {
        $pickerOptions.disabledDate = (time) => {
          var $maxTime =
              maxTime && maxTime instanceof Function ? maxTime(time) : maxTime,
            $minTime =
              minTime && minTime instanceof Function ? minTime(time) : minTime,
            _time = time.getTime(),
            disabled = false;
          $minTime =
            $minTime && typeof $minTime === "string" && isNaN($minTime)
              ? new Date($minTime.replace(new RegExp(/-/gm), "/")).getTime()
              : $minTime;
          $maxTime =
            $maxTime && typeof $maxTime === "string" && isNaN($maxTime)
              ? new Date($maxTime.replace(new RegExp(/-/gm), "/")).getTime()
              : $maxTime;
          if ($minTime && _time < $minTime) {
            disabled = true;
          }
          if ($maxTime && _time > $maxTime) {
            disabled = true;
          }
          return disabled;
        };
      }
      if (pickerOptions) {
        var cDate = new Date();
        var week = !cDate.getDay() ? 7 : cDate.getDay();
        var date =
          cDate.getDate() >= 10 ? cDate.getDate() : "0" + cDate.getDate();
        var month =
          cDate.getMonth() >= 9
            ? cDate.getMonth() + 1
            : "0" + (cDate.getMonth() + 1);
        var year = cDate.getFullYear();
        for (const optionsKey in pickerOptions) {
          let _options = pickerOptions[optionsKey];
          if (_options === "week") {
            shortcuts.push({
              text: "本周",
              onClick(picker) {
                let start = new Date(
                  "" + year + "-" + month + "-" + date + " 00:00:00"
                );
                let end = new Date(
                  "" + year + "-" + month + "-" + date + " 23:59:59"
                );
                start.setTime(
                  week === 1
                    ? start.getTime()
                    : start.getTime() - (week - 1) * 24 * 3600 * 1000
                );
                end.setTime(
                  week === 7
                    ? end.getTime()
                    : end.getTime() + (7 - week) * 24 * 3600 * 1000
                );
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "nextweek") {
            shortcuts.push({
              text: "下周",
              onClick(picker) {
                let start = new Date(
                  "" + year + "-" + month + "-" + date + " 00:00:00"
                );
                let end = new Date(
                  "" + year + "-" + month + "-" + date + " 23:59:59"
                );
                start.setTime(
                  (week === 1
                    ? start.getTime()
                    : start.getTime() - (week - 1) * 24 * 3600 * 1000) +
                    7 * 24 * 3600 * 1000
                );
                end.setTime(
                  (week === 7
                    ? end.getTime()
                    : end.getTime() + (7 - week) * 24 * 3600 * 1000) +
                    7 * 24 * 3600 * 1000
                );
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "prevweek") {
            shortcuts.push({
              text: "上周",
              onClick(picker) {
                let start = new Date(
                  "" + year + "-" + month + "-" + date + " 00:00:00"
                );
                let end = new Date(
                  "" + year + "-" + month + "-" + date + " 23:59:59"
                );
                start.setTime(
                  (week === 1
                    ? start.getTime()
                    : start.getTime() - (week - 1) * 24 * 3600 * 1000) -
                    7 * 24 * 3600 * 1000
                );
                end.setTime(
                  (week === 7
                    ? end.getTime()
                    : end.getTime() + (7 - week) * 24 * 3600 * 1000) -
                    7 * 24 * 3600 * 1000
                );
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "month") {
            shortcuts.push({
              text: "本月",
              onClick(picker) {
                let start = new Date("" + year + "-" + month + "-01 00:00:00");
                let end = new Date(
                  "" +
                    year +
                    "-" +
                    (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
                    "-01 00:00:00"
                );
                end.setTime(end.getTime() - 1000);
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "prevmonth") {
            shortcuts.push({
              text: "上个月",
              onClick(picker) {
                let start = new Date(
                  "" +
                    year +
                    "-" +
                    (month - 1 >= 10 ? month - 1 : "0" + (month - 1)) +
                    "-01 00:00:00"
                );
                let end = new Date("" + year + "-" + month + "-01 00:00:00");
                end.setTime(end.getTime() - 1000);
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "nextmonth") {
            shortcuts.push({
              text: "下个月",
              onClick(picker) {
                let start = new Date(
                  "" +
                    year +
                    "-" +
                    (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
                    "-01 00:00:00"
                );
                let end = new Date(
                  "" +
                    year +
                    "-" +
                    (month + 2 >= 10 ? month + 2 : "0" + (month + 2)) +
                    "-01 00:00:00"
                );
                end.setTime(end.getTime() - 1000);
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "year") {
            shortcuts.push({
              text: "今年",
              onClick(picker) {
                let start = new Date("" + year + "-01-01 00:00:00");
                let end = new Date("" + year + "-12-31 23:59:59");
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "nextyear") {
            shortcuts.push({
              text: "明年",
              onClick(picker) {
                let start = new Date("" + (year + 1) + "-01-01 00:00:00");
                let end = new Date("" + (year + 1) + "-12-31 23:59:59");
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "prevyear") {
            shortcuts.push({
              text: "去年",
              onClick(picker) {
                let start = new Date("" + (year - 1) + "-01-01 00:00:00");
                let end = new Date("" + (year - 1) + "-12-31 23:59:59");
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options === "latelyyear") {
            shortcuts.push({
              text: "最近一年",
              onClick(picker) {
                let start = new Date(
                  "" + (year - 1) + "-" + month + "-" + date + " 00:00:00"
                );
                let end = new Date();
                start.setTime(
                  start.getTime() + (end.getTime() - start.getTime())
                );
                picker.$emit("pick", [start, end]);
              },
            });
          } else if (_options && _options instanceof Object) {
            shortcuts.push(_options);
          }
        }
      }
      if (shortcuts.length > 0) $pickerOptions.shortcuts = [...shortcuts];
      this.pickerOptions = { ...$pickerOptions };
    },
    initParseData(query = "") {
      this.loading = true;
      this.handleChooseData(query)
        .then((data) => {
          this.parseData = [...data];
          if (
            this.isFocus === false &&
            this.acValue + "" === "0" &&
            this.parseData.filter((d) => d.value === this.acValue + "")
              .length === 0
          ) {
            this.acValue = null;
          }
        })
        .catch()
        .finally(() => {
          this.loading = false;
          this.handleEmitDone();
        });
    },
    handleChooseData(query = "", dataCallback = null) {
      query = query || "";
      return new Promise((resolve, reject) => {
        let data = dataCallback || this.$attrs.data || null;
        let dataMethod = this.$attrs.dataMethod || null;
        let mapData = (data) => {
          data = data.map((d) => {
            let _d;
            if (
              typeof d !== "object" &&
              (d || {}).value === undefined &&
              (d || {}).label === undefined
            ) {
              _d = { label: d, value: d };
            } else {
              _d = { ...d };
            }
            _d.label = _d.label === undefined ? _d.name : _d.label;
            _d.value = _d.value === undefined ? null : _d.value;
            _d.value =
              _d.value instanceof Boolean || _d.value === null
                ? _d.value
                : _d.value + "";
            if (_d.trueValue !== undefined) {
              _d.trueValue =
                _d.trueValue instanceof Boolean || _d.trueValue === null
                  ? _d.trueValue
                  : _d.trueValue + "";
              _d.falseValue =
                _d.falseValue === undefined ? null : _d.falseValue;
              _d.falseValue =
                _d.falseValue instanceof Boolean || _d.falseValue === null
                  ? _d.falseValue
                  : _d.falseValue + "";
            }
            if (_d.disabled !== undefined) {
              _d.disabled = !!_d.disabled;
            }
            return _d;
          });
          if (dataMethod && dataMethod instanceof Function) {
            dataMethod(data);
          }
          //console.log("---" , this.prop,this.acValue , data);
          if (query)
            data = data.filter((d) => (d.label || "").indexOf(query) > -1);
          return data;
        };
        if (this.inputType === "cascader" && this.inputProps.type === "area") {
          resolve([...cityData]);
        } else if (data instanceof Function) {
          var result = data(query);
          if (result && result instanceof Promise) {
            result
              .then((data) => {
                resolve(mapData(data));
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(mapData(result));
          }
        } else if (!data) {
          resolve([]);
        } else if (data instanceof Array) {
          let list = mapData(data);
          if (query)
            list = list.filter((d) => (d.label || "").indexOf(query) > -1);
          resolve(list);
        } else if (data instanceof Object) {
          if (data.url) {
            let searchData = { ...(data.data || {}) };
            let cache = 180;
            let keywordField = data.keywordField || "keyword";
            if (query) {
              searchData[keywordField] = query;
              cache = false;
            }
            if (
              this.inputType === "select" &&
              this.inputProps.remote !== false
            ) {
              this.inputProps.remote = true;
              if (this.inputProps.remoteMethod === undefined) {
                this.inputProps.remoteMethod = this.initParseData;
                if (query === "" && this.acValue) {
                  searchData[keywordField] =
                    this.acValue instanceof Array
                      ? this.acValue.join(",")
                      : this.acValue;
                  cache = false;
                }
              }
            }
            this.othersData.httpAllData = this.othersData.httpAllData || {};
            var isQuery = !(this.othersData.keywordField || "");
            var allData = this.othersData.httpAllData[data.url] || [];
            var values = allData.map((d) => d.value);
            this.$http
              .request({
                url: data.url,
                method: data.method || "post",
                params: { pageSize: 100, ...(data.params || {}) },
                data: { ...(data.data || {}), ...searchData },
                cache: cache,
              })
              .then((res) => {
                this.othersData.keywordField = query;
                let $data = ((res.data || {}).data || res.data || []).map(
                  (d) => {
                    d.value = d[(data.labelOptions || {}).value || "value"];
                    d.label = d[(data.labelOptions || {}).label || "label"];
                    return d;
                  }
                );
                let $_data = $data.filter((d) => !values.includes(d.value));
                allData = $_data.concat(allData);
                if (
                  (res.data || {}).total &&
                  (res.data || {}).total instanceof Number
                ) {
                  this.inputProps.remote = res.data.total !== allData.length;
                }
                this.othersData.httpAllData[data.url] = allData;
                if (query) {
                  resolve(mapData($data));
                } else {
                  resolve(mapData(allData));
                }
                if (!isQuery && this.inputProps.multiple) {
                  var val = [...this.acValue];
                  this.acValue = [];
                  this.$nextTick(() => {
                    this.acValue = [...val];
                  });
                }
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            data = { ...data };
            let key = data.keys;
            this.$store
              .dispatch("getDataList", data)
              .then((data) => {
                resolve(mapData((data[key] || {}).list || []));
              })
              .catch((err) => {
                reject(err);
              });
          }
        }
      });
    },
    tagShowInput() {
      this.tagInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputkey(){
      console.log("键盘");
    },
    handleInputConfirm() {
      console.log("键盘222");

      let inputValue = this.tagInputValue;
      if (inputValue) {
        if (this.acValue.indexOf(inputValue) === -1) {
          this.acValue.push(inputValue);
        } else {
          this.$handleMessage(inputValue + "已存在");
        }
      }
      this.tagInputVisible = false;
      this.tagInputValue = "";
      this.handleChange(this.acValue);
    },
    handleUploadSuccess(response, file, fileList) {
      file.percentage = 100;
      if (response) {
        file.url = (response.data || {}).url || response.url;
      }
      typeof this.$attrs.onSuccess === "function" &&
        this.$attrs.onSuccess(response, file, fileList);
    },
    handleUploadExceed(FileList, fileList) {
      // console.log("handleUploadExceed" , FileList);
      var uploadFiles = [];
      for (let i = 0; i < FileList.length; i++) {
        let file = FileList[i];
        if (
          !file.status ||
          file.status === "uploading" ||
          file.status === "ready"
        ) {
          uploadFiles.push(FileList[i]);
        }
      }
      this.othersData.uploadFiles = uploadFiles;
      if (this.inputProps.limit === 1 && this.acValue.length >= 1) {
        this.$refs.upload.clearFiles();
        this.$refs.upload.handleStart(FileList[0]);
      }
    },
    handleUploadChange(file, fileList) {
      let autoUpload =
        this.inputProps.autoUpload === undefined
          ? true
          : this.inputProps.autoUpload;
      if (file) {
        if (this.inputProps.limit === 1) {
          this.acValue = [file];
        } else if (fileList.length > this.acValue.length) {
          this.acValue.push(file);
        }
      }
      if (autoUpload) {
        this.$refs.upload && this.$refs.upload.submit();
      }
    },
    handleUploadProgress(progress) {
      // console.log(progress, "progressprogress");
      try {
        this.$attrs.onProgress(progress);
      } catch (error) {}
      // let index = this.acValue.map(d=>d.uid).indexOf(file.uid);
      // if(index > -1){
      //   progress.percent = (progress.percent = (progress.loaded/progress.total).toFixed(2)) > 100 ? 100 : (progress.percent < 0 ? 0 : progress.percent);
      //   this.acValue[index].percent = progress.percent;
      //   if(progress.percent === 100)this.acValue[index].status = 'success';
      //   typeof this.$attrs.onProgress === 'function' && this.$attrs.onProgress(progress , file , this.acValue);
      // }
    },
    handleUploadBefore(file) {
      var isVal = false;
      if (this.inputProps.limit === 1) {
        isVal = true;
      } else if (
        !(this.inputProps.limit > 1) ||
        (this.inputProps.limit > 1 &&
          this.acValue.length < this.inputProps.limit)
      ) {
        isVal = true;
      }
      if (isVal && typeof this.$attrs.beforeUpload === "function") {
        isVal = this.$attrs.beforeUpload(file);
      }
      return isVal !== false;
    },
    handleUploadClick() {
      this.$jquery(this.$refs.upload.$el).find("[type=file]").trigger("click");
    },
    getFileMime(mime = null, file = null) {
      var accepts = {
        image: "image/jpg,image/png,image/gif,image/bmp,image/jpeg,image/webp,image/jfif",
        video:
          "video/avi,video/mp4,video/wma,video/rmvb,video/rm,video/flash,video/3gp,video/flv",
      };
      if (!mime && !file) {
        return null;
      } else if (!file) {
        return accepts[mime] || mime || "";
      }
      var index = file.lastIndexOf(".");
      var ext = index !== -1 ? file.substr(index + 1) : null;
      if (ext) {
        for (const accept in accepts) {
          let mines = accepts[accept].split(",");
          for (let i = 0; i < mines.length; i++) {
            if (mines[i].indexOf("/" + ext) > -1) {
              mime = accept;
            }
          }
          if (mime) {
            break;
          }
        }
      }
      return mime || "";
    },
    async handleUpload({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      // console.log(1111111111111111111);
      let oss = new OSS({
        region: "oss-cn-hangzhou", //地域
        accessKeyId: "LTAI5tCdqiuXoAgCYcfnLVfL", //你自己的key和id
        accessKeySecret: "RKh2ZH0Na8g9FxhIiZbLwnlyJVkKSV",
        bucket: "kencodingpicture", //oss上你的存储空间名称
        secure: true,
        timeout: 120000,
        // region: "oss-cn-beijing", //地域
        // accessKeyId: "LTAI5tB5C2WA6nDePnz3LDAY", //你自己的key和id
        // accessKeySecret: "HQgjdKAl05o8W9yIApzLxSZeTzN4Uy",
        // bucket: "kencoding", //oss上你的存储空间名称
        // secure: true,
        // timeout: 120000,
      });
      // console.log(oss, "ossssssssssss");
      // console.log(oss.multipartUpload, "oss.multipartUpload");
      // return
      let filePath = `/kenkeding/${dayjs().format("YYYY-MM-DD-HH:mm:ss")}/${
        file.name
      }`;
      // console.log(file, "filefilefile");
      console.log(filePath, "============");
    return await  oss.multipartUpload(filePath, file, {
          progress(p) {
            // console.log(p, "pppppppppp");
            onProgress(p);
          },
        })
        .then((result) => {
          console.log(result, "result-------");
          // console.log(file, "filefilefile");
          // console.log(file.name, "filefilefile");
          // console.log(file.type, "filefilefile");
          // console.log(file.size, "filefilefile");

          if (!result) return;
          let url = result.res.requestUrls[0];
          let lastIndex = url.lastIndexOf("?");
          if (lastIndex !== -1) url = url.substr(0, lastIndex);
          // console.log(url, "上传之前");
          let response = {
            success: true,
            code: 200,
            msg: "ok",
            data: {
              id: 483,
              url: url,
              file_url: url,
              name:file.name,
              file: {
                name: file.name,
                type: file.type,
                tmp_name: "/tmp/phpCWHBZ1",
                error: 0,
                size: file.size,
              },
              original: "1d39784f9ca65b61047c690b1167fcca.mp4",
              type: file.type,
              title: "1445_156662.mp4",
              size: file.size,
            },
          };
         return response;
          // console.log(result.res.requestUrls[0], "result");
          // // return result.res.requestUrls[0]

          // // 返回的url需要处理
          // let { res } = result;
          // // if (res.status === 200) {
          // let url = res.requestUrls[0];
          // let lastIndex = url.lastIndexOf("?");
          // if (lastIndex !== -1) url = url.substr(0, lastIndex);
          // this.listInfo.video = url;
          // console.log(this.listInfo.video, "shipship");
        });

      //  data = data || {};
      //   if(this.inputProps.disabledUpload === true){
      //     onSuccess(null);
      //     return ;
      //   }
      //   if(this.loadingData['file_'+file.uid]){
      //     return ;
      //   }
      //   var _this = this;
      //   _this.loadingData['file_'+file.uid] = true
      //   _this.editloading = true
      //   _this.$parent.editloading = _this.editloading === true;
      //   var isError = false,position = 0 , md5_file
      //       , maxSize = 5 * 1024 * 1024
      //       , minSize = file.size > maxSize ? parseInt(file.size/100) : maxSize;
      //   minSize = minSize > maxSize ? maxSize : minSize;
      //   var ProgressUpload = async (blob,size)=>{
      //     let index = _this.acValue.map(d=>d.uid).indexOf(file.uid);
      //     if(index === -1)return onError(new Error('文件不存在或已被删除'));
      //     var form = new FormData();
      //     form.append('position' , position)
      //     form.append('size' , file.size)
      //     form.append(filename || 'file' , blob, file.name)
      //     form.append('key' , md5_file);

      //     return await this.$http.request({
      //       url : action || _this.$store.state.action,
      //       headers : headers || {},
      //       params:data,
      //       data : form,
      //       method:'post',
      //       withCredentials:withCredentials,
      //       onUploadProgress:(progress)=>{
      //         let loaded = progress.loaded === progress.total ? minSize : parseInt(progress.loaded/progress.total * size);
      //         onProgress({loaded:100 + position + loaded,total:file.size+100});
      //         if(progress.loaded === progress.total)position += minSize;
      //       }
      //     }).then(async function ({data}){
      //       if(data.position && !isNaN(data.position) && position !== data.position){
      //         position = data.position;
      //         onProgress({loaded:position+100,total:file.size+100});
      //       }
      //       if(position >= file.size || data.size === file.size){
      //         onProgress({loaded:file.size+100,total:file.size+100});
      //         onSuccess(data);
      //         delete _this.loadingData['file_'+file.uid];
      //       }else{
      //         await ProgressUpload(file.slice(position, Math.min(position + minSize , file.size)) , size);
      //       }
      //     }).catch(error=>{
      //       isError = true
      //       delete _this.loadingData['file_'+file.uid];
      //       onError(error);
      //     }).finally(()=>{
      //       var $i = 0 , $i1 = 0;
      //       for (const indexKey in _this.loadingData) {
      //         if(indexKey.indexOf('file_') === 0){
      //           $i++;
      //           if(_this.loadingData[indexKey] === false){
      //             $i1 ++;
      //           }
      //         }
      //       }
      //       _this.editloading = $i > $i1;
      //       _this.$parent.editloading = _this.editloading === true;
      //     })
      //   }
      //   let $idx = _this.acValue.map(d=>d.uid).indexOf(file.uid);
      //   if($idx > -1) {
      //     _this.acValue[$idx].url = URL.createObjectURL(file);
      //   }
      //   onProgress({loaded:0,total:file.size+100});
      //   var _count = 0;
      //   var index = setInterval(()=>{
      //     _count += 5;
      //     onProgress({loaded:_count,total:file.size+100});
      //   },500);
      //   _this.$tool.md5_file(file , async function(reader){
      //     clearInterval(index);
      //     onProgress({loaded:100,total:file.size+100});
      //     md5_file = reader.md5_file;
      //     position = 0;
      //     if(file.size > maxSize){
      //       await ProgressUpload(file.slice(position, minSize) , minSize);
      //     }else{
      //       await ProgressUpload(file , file.size);
      //     }
      //   });
    },
    handleUploadRemove(file) {
      this.acValue = this.acValue.filter((d) => d.uid !== file.uid);
      this.handleChange(this.acValue);
    },
    getTemplateDataValue(Obj, callback, _default = "-") {
      var val;
      try {
        if (callback && typeof callback === "function") {
          val = callback(Obj);
        } else {
          val = this.$tool.getDataValue(
            Obj.row,
            callback && typeof callback === "string"
              ? callback
              : Obj.$props.prop,
            null
          );
        }
      } catch (e) {
        val = null;
      }
      if (val || val === 0 || val === false) {
        return val;
      }
      return _default;
    },
  },
};
</script>

<style lang='less' scoped>
.el-form--inline .el-form-item::v-deep {
  display: inline-flex;
}
.el-form--inline .el-form-item::v-deep .el-form-item__content {
  flex: 1;
}
::v-deep {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner,
  .el-select {
    width: 100%;
  }
  .el-table .cell .el-range-editor.el-input__inner {
    max-width: calc(100% - 30px);
    padding: 3px 10px;
    border: 1px solid #dcdfe6;
  }
  .hidden-label .el-radio__label,
  .hidden-label .el-checkbox__label {
    display: none;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: inherit;
  }
  .edui-editor {
    line-height: 1;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }
  .el-upload--picture-card {
    width: auto;
    height: auto;
    min-width: 108px;
    min-height: 108px;
    line-height: 118px;
    border: none;
  }
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload--picture-card .el-upload_item {
    width: 108px;
    height: 108px;
    line-height: 118px;
  }
  .el-upload-list--picture-card
    .el-upload-list:not(.el-upload_item)
    .el-upload-list__item,
  .el-upload_item {
    border: 1px dashed var(--color-primary);
    border-radius: 6px;
  }
  .el-upload-list--text .el-upload-list__item-status-label {
    top: 5px;
  }
  .el-upload_item.el-upload-list .el-upload-list__item {
    margin: 0;
  }
  .el-table .el-table__cell {
    padding: 2px 0;
    margin: 0;
  }
  .el-table td.el-table__cell.cell-top {
    vertical-align: top;
  }
  .el-radio-button {
    .el-radio-button__inner {
      border: none;
      border-radius: 20px;
      padding: 7px 18px;
    }
  }
  .el-checkbox-button {
    .el-checkbox-button__inner {
      border: none;
      border-radius: 20px;
      padding: 7px 18px;
    }
  }
  .el-tag-item:first-child,
  .el-tag-item + .el-tag-item {
    margin-right: 10px;
  }
  .el-tag-box .el-tag-input {
    min-width: 90px;
  }
  .el-form-item .el-table .cell .el-form-item {
    margin-bottom: 22px;
  }
  .el-upload--text {
    display: inline-block;
  }
  .el-form-item ~ .el-form-item .el-form-item:last-child {
    margin-bottom: 0;
  }
  .el-upload-list__item-status-label i {
    display: block;
  }
  .el-input-number {
    width: 100% !important;
  }
  .hidden-upload .el-upload--picture-card {
    display: none;
  }
  .form-item-notice {
    font-size: 12px;
    color: var(--color-notice);
    line-height: 1.5;
    white-space: pre-wrap;
    * {
      font-size: 12px;
    }
  }
  .el-input-group__prepend .el-link,
  .el-input-group__append .el-link {
    vertical-align: unset;
  }
}
.el-table::v-deep {
  tr:last-child > .el-form-item:last-child,
  tr:last-child .table-column-form-item:last-child {
    margin-bottom: 0;
  }
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    display: none;
  }
  tr .el-table__cell:first-child .cell {
    padding-left: 0;
  }
  tr .el-table__cell:last-child .cell {
    padding-right: 0;
  }
}
.el-select-dropdown .el-select-dropdown__item {
  max-width: 80vw;
}
</style>

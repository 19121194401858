
<script>
import {mapState} from "vuex";
import {routeNavigateBack} from "@/router";

export default {
  name: "exception",
  computed:{
    ...mapState(['exception']),
  },
  render(createElement, context) {
    this.initException();
    if(this.curentEx){
      let children = [
        this.$slots.extra ? this.$slots.extra : createElement('div' , {slot:'extra'},[
          createElement('el-button',{
            props:{
              type:'primary',
            },
            domProps: {
              innerHTML: '返回上一页'
            },
            on:{
              click:()=>{this.$routerGo(-1)}
            }
          })
        ])
      ];
      if(this.curentEx.image){
        children.push(createElement('el-image',{
          props:{
            src:this.curentEx.image,
          },
          slot:'icon'
        }));
      }
      return createElement('el-result',{
        props:{
          icon:this.curentEx.icon,
          title:this.curentEx.title,
          subTitle:this.curentEx.desc,
        }
      },children);
    }else{
      return this.$slots.default ? this.$slots.default : null ;
    }
  },
  data(){
    return {
      curentEx:null,
      exData:{
        500:{
          image:"",
          icon:"error",
          title:"请求错误",
          desc:"抱歉，该页面请求失败了！",
        },
        404:{
          image:"",
          icon:"error",
          title:"页面走丢了",
          desc:"抱歉，该页面不存在",
        },
        403:{
          image:"",
          icon:"error",
          title:"无权访问",
          desc:"抱歉，您没有无权访问该页面",
        },
      },
    };
  },
  props:{
    exceptionData:{
      type:Object
    }
  },
  watch:{
    exception:{
      immediate:true,
      deep:true,
      handler(){
        this.initException();
      }
    },
    exceptionData:{
      immediate:true,
      deep:true,
      handler(){
        this.initException();
      }
    }
  },
  created() {
    this.initException();
  },
  methods:{
    $routerGo( number = -1){
      let {goPath} = this.exception
      if(goPath){
        this.$router.push(goPath instanceof Object ? {...goPath} : {path:goPath})
      }else{
        routeNavigateBack(-1)
      }
    },
    initException(){
      let {status , path , _uid , ...o} = {...this.exception,...this.exceptionData}
      if(status && status !== 200){
        this.curentEx = {...this.exData[status || 500] || this.exData[500],...o};
      }else{
        this.curentEx = null;
      }
    }
  }
}
</script>

<style scoped>

</style>